import { Button, Modal, TextField } from "@/components";
import { IModal } from "@/components/Modal/modal";
import { useAppDispatch } from "@/hooks";
import { CreateVitalSingsPayload, VitalSign, VitalSingList, VitalSigns as vitalSingsRecord } from "@/models/surgery/patientAttention/patientAttention";
import { useEffect, useState } from "react";
import { getVitalSingList } from "../patientAttentionSurgery.action";



export default function VitalSignsModal({
    appId,
    handleGenericFunction,
    defaultDataRecord,
    dontClose = true,
    ...rest
}: IModal & {
    appId: number;
    defaultDataRecord?: vitalSingsRecord;
    setVitalSingsData?: (data: CreateVitalSingsPayload) => void;
    handleGenericFunction: (data: VitalSign[]) => Promise<void>;
}) {
    const dispatch = useAppDispatch();
    const [vitalSingsData, setVitalSingsData] = useState<VitalSign[]>([]);
    const [vitalListFields, setVitalListFields] = useState<VitalSingList[]>([]);
 


    const mapLastValues = (service1: vitalSingsRecord, service2: VitalSingList[]) => {
        return service2.map(({ visStageId, visName }) => {
            const lastValue = service1[visName.toLowerCase()]?.at(-1)?.value || "";
            return {
                visStageId,
                visValue: lastValue,
            };
        });
    };



    useEffect(() => {
        async function fetchData() {
            if (rest.isOpen) {
                const data = await dispatch(getVitalSingList({ enabled: 1, stgPrefix: "surgery" }));
                if (data) {
                    setVitalListFields(data);
                    if(defaultDataRecord){
                        setVitalSingsData(mapLastValues(defaultDataRecord, data)); 
                    }else{ 
                        const dataFormatted = data.map((data) => ({ visStageId: data.visStageId, visValue: "" }));
                        setVitalSingsData(dataFormatted);
                    }

                }
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest.isOpen]);

    const onCloseModal = () => {
        const formatted = vitalSingsData.map((sing) => ({ ...sing, visValue: "" }));
        setVitalSingsData(formatted);
        rest.onClose();
    };

    const handleChange = (value: string, id: number) => {
        const formatted = vitalSingsData.map((sing) => (sing.visStageId === id ? { ...sing, visValue: value } : sing));
        setVitalSingsData(formatted);
    };

    return (
        <Modal
            {...rest}
            width={400}
            dontClose={dontClose}
            positionModal="center"
            onClose={onCloseModal}
        >
            <div className="p-4">
                <h1 className="text-secondary fw-bold text-center">Máquina de anestesia</h1>
                {vitalListFields.length > 0 &&
                    vitalListFields.map((field) => {
                        return (
                            <div
                                className="px-3 d-flex  "
                                key={field.visStageId}
                            >
                                <div className="col-9 text-muted align-self-end">{field.visDescription}</div>
                                <div className="col-3">
                                    <TextField
                                        value={vitalSingsData.find((x) => x.visStageId === field.visStageId)?.visValue}
                                        type="number"
                                        variant="plain"
                                        onChange={({ target }) => {
                                            handleChange(target.value, field.visStageId);
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}

                <div className="pt-4">
                    <div className="d-flex justify-content-center mt-3">
                        <Button
                            variant="primary"
                            size="large"
                            className="py-2"
                            key={String(vitalSingsData)}
                            disabled={vitalSingsData.some((v) => v.visValue === null || v.visValue === undefined || v.visValue === "")}
                            onClick={() => {
                                onCloseModal();
                                handleGenericFunction(vitalSingsData);
                            }}
                        >
                            Guardar
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}




