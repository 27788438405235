import { jwtDecode, JwtPayload } from "jwt-decode";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { clearAuth, createAuth, finishLoading, startLoading } from "@/config/slices";
import { setCategorySlice } from "@/config/slices/category";
import { statusCodes } from "@/constants";
import routes from "@/constants/routes";
import { getAccountsByUser, saveAccount } from "@/features/Workspace/Home/home.actions";
import { getCategory } from "@/features/Workspace/Sidebar/sidebar.actions";
import { useAppDispatch } from "@/hooks";
import { AuthUserData } from "@/models";
import { verifyToken } from "@/services";
import { hasData, parseSearchParams } from "@/utils";

export interface IDataBiowel {
    idModule: number;
    idAccount: number;
    idProfile: number;
    token: string;
}

const { REACT_APP_URL_REDIRECT_BIOWEL } = process.env;

export default function ValidateAuth() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const parsed = useMemo(() => {
        return parseSearchParams<IDataBiowel>(location.search);
    }, [location.search]);

    const onClearAuth = useCallback(() => {
        dispatch(clearAuth());
        window.location.href = REACT_APP_URL_REDIRECT_BIOWEL + "/login";
    }, [dispatch]);

    useEffect(() => {
        (async function () {
            try {
                dispatch(startLoading());
                if (hasData(parsed.idAccount) && hasData(parsed.idModule) && hasData(parsed.idProfile) && hasData(parsed.token)) {
                    localStorage.clear();

                    const decodedToken = jwtDecode<JwtPayload & { user_data: AuthUserData }>(parsed.token);
                    localStorage.setItem("x_token", parsed.token as string);

                    const response = await verifyToken();

                    if (response.status === statusCodes.OK) {
                        localStorage.setItem("user_data", JSON.stringify(decodedToken.user_data));

                        const accounts = (await dispatch(getAccountsByUser(`${decodedToken.user_data.id}`))) || [];

                        const currentAccount = accounts.find((acc) => acc.id === Number(parsed.idAccount));
                        if (currentAccount) {
                            const currentProfile = currentAccount.profile.find((prof) => prof.id === Number(parsed.idProfile));

                            const data = await dispatch(getCategory(parsed.idProfile));
                            if (data) {
                                dispatch(setCategorySlice(data ?? {}));
                                localStorage.setItem("category", JSON.stringify(data));
                            }

                            if (currentProfile) dispatch(saveAccount({ ...currentAccount, profile: currentProfile }));
                        }

                        dispatch(
                            createAuth({
                                access_token: parsed.token,
                                is_auth: true,
                                message: "Autenticación correcta",
                                status: 200,
                                user_data: decodedToken.user_data,
                            })
                        );
                        navigate(routes.workspace);
                    } else {
                        onClearAuth();
                    }
                } else {
                    onClearAuth();
                }
            } finally {
                dispatch(finishLoading());
            }
        })();
    }, [dispatch, navigate, parsed, onClearAuth]);

    return <div className="w-100 vh-100"></div>;
}
