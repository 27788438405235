import { axiosInstance } from "@/config/axios";
import { DoctorListResponse, IResponse, MeasurementMethodResponse } from "@/models";
import { AttentionOriginFields, AttentionOriginResponse, AttentionTimeResponse } from "@/models/attentionOrigin";
import { GeneralFieldsParams, GeneralFieldsResponse } from "@/models/generalFields";
import { ArticlesResponse } from "@/models/pre-consultation/generals";
import { DesignLensResponse } from "@/models/select/designLens";
import { IdentifiedLetterResponse } from "@/models/select/indentifiedLetter";
import { MaterialsLensResponse } from "@/models/select/materialsLens";
import { OptotypeListResponse } from "@/models/select/optotype";
import { SitesParams, SitesResponse } from "@/models/select/sites";
import { SoftLensResponse } from "@/models/select/softLens";
import { SpecialLensResponse } from "@/models/select/specialLens";
import { VisionResponse } from "@/models/select/vision";
import { FinishAppointmentResults } from "@/models/shared/finishAppointment";
import { DetailedClassificationsResponse, GeneralClassificationResponse } from "@/models/shared/unexpectedSituation";
import { AnesthesiaResponse, CommonSelectResponse, SquintsResponse, UserResponse } from "@/models/sheets/orthoptic";
import { setHeaders } from "@/utils";

export async function getArticlesService(appointmendId: number): Promise<ArticlesResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params: { appId: appointmendId } };

    const { data: serviceData } = await axiosInstance.get<ArticlesResponse>(
        "/medical/generals/articleLots/", axiosParams
    );
    return serviceData;
}

export async function getSitesService(params: SitesParams): Promise<SitesResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<SitesResponse>(
        "/medical/generals/site/", axiosParams
    );
    return serviceData;
}

export async function getDoctorsService(accountId: number): Promise<DoctorListResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params: { idAccount: accountId } };

    const { data: serviceData } = await axiosInstance.get<DoctorListResponse>(
        "/medical/doctor/", axiosParams
    );
    return serviceData;
}

export async function getAttentionOriginsService(params: Record<string, unknown>): Promise<AttentionOriginResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<AttentionOriginResponse>(
        "/medical/generals/attentionOrigin/", axiosParams
    );
    return serviceData;
}

export async function getAttentionTimesService(params?: Record<string, unknown>): Promise<AttentionTimeResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<AttentionTimeResponse>(
        "/medical/generals/times/", axiosParams
    );
    return serviceData;
}

export async function saveAttentionOriginService(payload: AttentionOriginFields): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/consultingGeneralFields/", payload, axiosParams
    );
    return serviceData;
}

export async function getGeneralFieldsService(params: GeneralFieldsParams): Promise<GeneralFieldsResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<GeneralFieldsResponse>(
        "/medical/clinicHistory/consultingGeneralFields/", axiosParams
    );
    return serviceData;
}

export async function getOptotypes(): Promise<OptotypeListResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<OptotypeListResponse>(
        "/medical/generals/optoTypes/", axiosParams
    );
    return serviceData;
}

export async function getFarVisionList(): Promise<VisionResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<VisionResponse>(
        "/medical/generals/farVision/", axiosParams
    );
    return serviceData;
}

export async function getNearVisionList(): Promise<VisionResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<VisionResponse>(
        "/medical/generals/nearVision/", axiosParams
    );
    return serviceData;
}

export async function getIdentifiedLetters(): Promise<IdentifiedLetterResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<IdentifiedLetterResponse>(
        "/medical/generals/identifiedLetters/", axiosParams
    );
    return serviceData;
}

export async function getLensAdd(): Promise<VisionResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<VisionResponse>(
        "/medical/generals/lensAdd/", axiosParams
    );
    return serviceData;
}

export async function getLensType(): Promise<VisionResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<VisionResponse>(
        "/medical/generals/lensType/", axiosParams
    );
    return serviceData;
}

export async function getPIOMeasurementsMethodsService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<MeasurementMethodResponse>(
        "/medical/generals/pioMeasurementMethod/", axiosParams
    );
    return serviceData;
}
export async function getSoftLensService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<SoftLensResponse>(
        "/medical/contactLensFitting/softTestLens/", axiosParams
    );
    return serviceData;
}
export async function getMaterialLensService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<MaterialsLensResponse>(
        "/medical/contactLensFitting/materialsLens/", axiosParams
    );
    return serviceData;
}
export async function getSpecialLensService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<SpecialLensResponse>(
        "/medical/contactLensFitting/specialLens/", axiosParams
    );
    return serviceData;
}
export async function getDesignLensService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<DesignLensResponse>(
        "/medical/contactLensFitting/designLens/", axiosParams
    );
    return serviceData;
}

export async function getKappaAngleService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<CommonSelectResponse>(
        "/medical/generals/KappaAngle/", axiosParams
    );
    return serviceData;
}

export async function getEyesLateralityService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<CommonSelectResponse>(
        "/medical/generals/eyesLaterality/", axiosParams
    );
    return serviceData;
}

export async function getHandsLateralityService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<CommonSelectResponse>(
        "/medical/generals/handsLaterality/", axiosParams
    );
    return serviceData;
}

export async function getFixationService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<CommonSelectResponse>(
        "/medical/generals/fixation/", axiosParams
    );
    return serviceData;
}

export async function getFlexibilityService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<CommonSelectResponse>(
        "/medical/generals/flexibility/", axiosParams
    );
    return serviceData;
}

export async function getCycleMinService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<CommonSelectResponse>(
        "/medical/generals/cycleMin/", axiosParams
    );
    return serviceData;
}

export async function getAnesthesiaList() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<AnesthesiaResponse>(
        "/medical/generals/anesthesia/", axiosParams
    );
    return serviceData;
}

export async function getVersionsList() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<CommonSelectResponse>(
        "/medical/generals/versions/", axiosParams
    );
    return serviceData;
}

export async function getSquintsService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<SquintsResponse>(
        "/medical/generals/squints/", axiosParams
    );
    return serviceData;
}

export async function getSquintsDegreeService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<CommonSelectResponse>(
        "/medical/generals/squintsDegree/", axiosParams
    );
    return serviceData;
}

export async function getBreadthAccommodationLevelService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<CommonSelectResponse>(
        "/medical/generals/breadthAccommodationLevel/", axiosParams
    );
    return serviceData;
}

export async function getSensoryCorrespondenceService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<CommonSelectResponse>(
        "/medical/clinicHistory/orthopticSensoryCorrespondence/", axiosParams
    );
    return serviceData;
}

export async function getGeneralClassificationsService(params: Record<string, unknown>): Promise<GeneralClassificationResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<GeneralClassificationResponse>(
        "/medical/clinicHistory/clasificationLevels/", axiosParams
    );
    return serviceData;
}
export async function getDetailedClassificationsService(params: Record<string, unknown>): Promise<DetailedClassificationsResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<DetailedClassificationsResponse>(
        "/medical/clinicHistory/clasificationDetails/", axiosParams
    );
    return serviceData;
}
export async function getFinishAppointmentService(mcfId: number): Promise<FinishAppointmentResults> {
    const headers = setHeaders();
    const axiosParams = { headers, params: { mcfId } };

    const { data: serviceData } = await axiosInstance.get<FinishAppointmentResults>(
        "/medical/clinicHistory/canAttentionEnd/", axiosParams
    );
    return serviceData;
}

export async function getSitesListService(params: SitesParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<CommonSelectResponse>(
        "/medical/sites/", axiosParams
    );
    return serviceData;
}
export async function getSurgeriesRoomService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<CommonSelectResponse>(
        "/medical/surgeriesRoom/", axiosParams
    );
    return serviceData;
}
export async function getSurgeonsService(params?: { profilePrefix?: string; }) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<AnesthesiaResponse>(
        "/medical/surgeons/", axiosParams
    );
    return serviceData;
}

export async function getUsersService(params?: { eaccount: number; anesthesia_assistant: number }): Promise<UserResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<UserResponse>("/users/params/", axiosParams);
    return serviceData;
}

export async function synchronizeConsumptionSheetService(payload: { appId: number; eaccount: number; userId: number; }) {
    const headers = setHeaders();
    const axiosParams = { headers };

    await axiosInstance.put("/medical/clinicHistory/synchronize-consumption-sheet-surgery", payload, axiosParams);
}