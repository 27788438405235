import { Avatar, Badge, Button, Dropdown, DropdownItem, DropdownMenu, Modal, SectionCard, Select } from "@/components";
import DiagnosticAidsSheet from "@/features/Workspace/DiagnosticAids/DiagnosticAidsSheet";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { Data, History, PatientHeader, Structure, UnexpectedSituationsBody } from "@/models/historyDetails/diagnosticAdxDetails";
import { SurgeryStage } from "@/models/surgery/surgeryStages";
import { formatteDocument, IResultConvertFileBase64 } from "@/utils";
import { IconArrowsMaximize, IconChevronUp } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { getDiagnosticADXDetail } from "../../history.actions";
import PdfVisualizer from "../PdfVisualizer";
import BackgroundDetail from "./SurgeryDetails/BackgroundDetail/BackgroundDetail";
import OrdersDetail from "./SurgeryDetails/OrdersDetail/OrdersDetail";
import { getSurgeryStages } from "./SurgeryDetails/surgeryDetails.actions";
interface ConfigModalData {
    configModal: ConfigModalBody;
    setConfigModal: (ConfigModalBody: ConfigModalBody) => void;
    showReport?: boolean;
}

interface ConfigModalBody {
    isOpenModal: boolean;
    appId?: number | null;
    tab?: string;
}

interface SelectOptions {
    value: string | number;
    label: string;
    url: string;
    base64?: string | Promise<IResultConvertFileBase64>;
}

const DiagnosticAdxDetails = ({ configModal, setConfigModal, showReport }: ConfigModalData) => {
    const dispatch = useAppDispatch();
    const eaccount = useAppSelector((state) => state.workspace.id);
    const [structure, setStructure] = useState<Structure>();
    const [dataForm, setDataForm] = useState<Data>();
    const [unexpectedSituationData, setUnexpectedSituationData] = useState<UnexpectedSituationsBody[]>([]);
    const [trigger, setTrigger] = useState(0);
    const [selectedSection, setSelectedSection] = useState("");
    const [history, setHistory] = useState<History>();
    const [showModalViewer, setShowModalViewer] = useState<boolean>(false);
    const [patientHeader, setPatientHeader] = useState<PatientHeader>();
    const [fileSelected, setFileSelected] = useState<{
        url: string;
        value: string | number;
    }>({
        url: "",
        value: "",
    });
    const [filesOptions, setFilesOptions] = useState<SelectOptions[]>([]);
    const [selectedOption, setSelectedOption] = useState<SurgeryStage>({
        name: "Examen",
        prefix: "diagnosticAids",
        tab: "exam",
    });
    const [stageOptions, setStageOptions] = useState<SurgeryStage[]>([]);

    const handleCloseModal = () => {
        setConfigModal({ ...configModal, isOpenModal: false, tab: "exam" });
        setSelectedOption({ name: "Examen", prefix: "diagnosticAids", tab: "exam" });
        setFileSelected({ url: "", value: "" });
    };

    useEffect(() => {
        void (async function () {
            if (Number(configModal.appId)) {
                const response = await dispatch(getSurgeryStages({ appId: configModal.appId || NaN, toDownload: 0 }));
                setStageOptions(response);
            }
        })();
    }, [configModal.appId, dispatch]);

    useEffect(() => {
        async function fetchData() {
            if (configModal.appId && configModal.tab !== "") {
                const data = await dispatch(
                    getDiagnosticADXDetail({
                        prefix: "diagnosticAids",
                        appId: configModal.appId,
                    })
                );

                setTrigger(trigger + 1);
                if (data?.structure) {
                    setStructure(data.structure);
                }
                if (data?.data) {
                    setDataForm(data.data);
                    if (configModal.tab === "images") {
                        setSelectedOption({ name: "Imágenes", prefix: "diagnosticAids", tab: "images" });
                        if (data?.data?.images?.ao && data?.data?.images?.ao?.length > 0) {
                            uploadFilesFunction("ao", true, data?.data);
                            setFileSelected({
                                value: data?.data?.images?.ao[0].id ?? data?.data?.images?.ao[0].value ?? "",
                                url:
                                    data?.data?.images?.ao[0].url ??
                                    `data:application/pdf;base64,${data?.data?.images?.ao[0].base64}` ??
                                    "",
                            });
                        } else if (data?.data?.images?.od && data?.data?.images?.od?.length > 0) {
                            uploadFilesFunction("od", true, data?.data);
                            setFileSelected({
                                value: data?.data?.images?.od[0].id ?? data?.data?.images?.od[0].value ?? "",
                                url:
                                    data?.data?.images?.od[0].url ??
                                    `data:application/pdf;base64,${data?.data?.images?.od[0].base64}` ??
                                    "",
                            });
                        } else if (data?.data?.images?.oi && data?.data?.images?.oi?.length > 0) {
                            uploadFilesFunction("oi", true, data?.data);
                            setFileSelected({
                                value: data?.data?.images?.oi[0].id ?? data?.data?.images?.oi[0].value ?? "",
                                url:
                                    data?.data?.images?.oi[0].url ??
                                    `data:application/pdf;base64,${data?.data?.images?.oi[0].base64}` ??
                                    "",
                            });
                        } else {
                            setFileSelected({ url: "", value: "" });
                        }
                    } else if (configModal.tab === "report") {
                        setSelectedOption({ name: "Informes", prefix: "diagnosticAids", tab: "report" });
                        if (data?.data?.report?.files && data?.data?.report?.files?.length > 0) {
                            uploadFilesFunction("report", true, data?.data);
                            setFileSelected({
                                value: data?.data?.report?.files[0].id ?? data?.data?.report?.files[0].value ?? "",
                                url:
                                    data?.data?.report?.files[0].url ??
                                    `data:application/pdf;base64,${data?.data?.report?.files[0].base64}` ??
                                    "",
                            });
                        } else {
                            setFileSelected({ url: "", value: "" });
                        }
                        uploadFilesFunction("report", true, data?.data);
                    }
                }
                if (data?.history) {
                    setHistory(data.history);
                }
                if (data?.patientHeader) {
                    setPatientHeader(data.patientHeader);
                }
                if (data?.unexpectedSituations) {
                    setUnexpectedSituationData(data.unexpectedSituations);
                }
            } else {
                if (configModal.tab === "images") {
                    setSelectedOption({ name: "Imágenes", prefix: "diagnosticAids", tab: "images" });
                    if (dataForm?.images?.ao && dataForm?.images?.ao?.length > 0) {
                        uploadFilesFunction("ao", true, dataForm);
                        setFileSelected({
                            value: dataForm?.images?.ao[0].id ?? dataForm?.images?.ao[0].value ?? "",
                            url: dataForm?.images?.ao[0].url ?? `data:application/pdf;base64,${dataForm?.images?.ao[0].base64}` ?? "",
                        });
                    } else if (dataForm?.images?.od && dataForm?.images?.od?.length > 0) {
                        uploadFilesFunction("od", true, dataForm);
                        setFileSelected({
                            value: dataForm?.images?.od[0].id ?? dataForm?.images?.od[0].value ?? "",
                            url: dataForm?.images?.od[0].url ?? `data:application/pdf;base64,${dataForm?.images?.od[0].base64}` ?? "",
                        });
                    } else if (dataForm?.images?.oi && dataForm?.images?.oi?.length > 0) {
                        uploadFilesFunction("oi", true, dataForm);
                        setFileSelected({
                            value: dataForm?.images?.oi[0].id ?? dataForm?.images?.oi[0].value ?? "",
                            url: dataForm?.images?.oi[0].url ?? `data:application/pdf;base64,${dataForm?.images?.oi[0].base64}` ?? "",
                        });
                    } else {
                        setFileSelected({ url: "", value: "" });
                    }
                } else if (configModal.tab === "report") {
                    setSelectedOption({ name: "Informes", prefix: "diagnosticAids", tab: "report" });
                    if (dataForm?.report?.files && dataForm?.report?.files?.length > 0) {
                        uploadFilesFunction("report", true, dataForm);
                        setFileSelected({
                            value: dataForm?.report?.files[0].id ?? dataForm?.report?.files[0].value ?? "",
                            url: dataForm?.report?.files[0].url ?? `data:application/pdf;base64,${dataForm?.report?.files[0].base64}` ?? "",
                        });
                    } else {
                        setFileSelected({ url: "", value: "" });
                    }
                    uploadFilesFunction("report", true, dataForm);
                }
            }
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, eaccount, configModal.appId, configModal.tab]);

    const uploadFilesFunction = (section: "report" | "ao" | "od" | "oi", setting: boolean, data?: Data) => {
        setSelectedSection(section);
        const dataFull = data ?? dataForm;
        if (section === "report") {
            if (setting && dataFull?.report?.files && dataFull?.report?.files?.length > 0) {
                setFileSelected({
                    value: dataFull?.report?.files[0].id ?? dataFull?.report?.files[0].value ?? "",
                    url: dataFull?.report?.files[0].url ?? `data:application/pdf;base64,${dataFull?.report?.files[0].base64}` ?? "",
                });
            }
            const options =
                dataFull?.report?.files && dataFull?.report?.files?.length > 0
                    ? dataFull?.report?.files.map((file) => ({
                        label: file.filename ?? "",
                        value: file.id ?? file.value ?? "",
                        url: file.url ?? "",
                        base64: file.base64 ?? "",
                    }))
                    : [];
            setFilesOptions(options);
        } else if (section === "ao") {
            if (setting && dataFull?.images?.ao && dataFull?.images?.ao?.length > 0) {
                setFileSelected({
                    value: dataFull?.images?.ao[0].id ?? dataFull?.images?.ao[0].value ?? "",
                    url: dataFull?.images?.ao[0].url ?? `data:application/pdf;base64,${dataFull?.images?.ao[0].base64}` ?? "",
                });
            }
            const options =
                dataFull?.images?.ao && dataFull?.images?.ao?.length > 0
                    ? dataFull?.images?.ao.map((file) => ({
                        label: file.filename ?? "",
                        value: file.id ?? file.value ?? "",
                        url: file.url ?? "",
                        base64: file.base64 ?? "",
                    }))
                    : [];
            setFilesOptions(options);
        } else if (section === "od") {
            if (setting && dataFull?.images?.od && dataFull?.images?.od?.length > 0) {
                setFileSelected({
                    value: dataFull?.images?.od[0].id ?? dataFull?.images?.od[0].value ?? "",
                    url: dataFull?.images?.od[0].url ?? `data:application/pdf;base64,${dataFull?.images?.od[0].base64}` ?? "",
                });
            }
            const options =
                dataFull?.images?.od && dataFull?.images?.od?.length > 0
                    ? dataFull?.images?.od.map((file) => ({
                        label: file.filename ?? "",
                        value: file.id ?? file.value ?? "",
                        url: file.url ?? "",
                        base64: file.base64 ?? "",
                    }))
                    : [];
            setFilesOptions(options);
        } else if (section === "oi") {
            if (setting && dataFull?.images?.oi && dataFull?.images?.oi?.length > 0) {
                setFileSelected({
                    value: dataFull?.images?.oi[0].id ?? dataFull?.images?.oi[0].value ?? "",
                    url: dataFull?.images?.oi[0].url ?? `data:application/pdf;base64,${dataFull?.images?.oi[0].base64}` ?? "",
                });
            }
            const options =
                dataFull?.images?.oi && dataFull?.images?.oi?.length > 0
                    ? dataFull?.images?.oi.map((file) => ({
                        label: file.filename ?? "",
                        value: file.id ?? file.value ?? "",
                        url: file.url ?? "",
                        base64: file.base64 ?? "",
                    }))
                    : [];
            setFilesOptions(options);
        }
        setTrigger(trigger + 1);
    };

    const header = () => {
        return (
            <div className="px-5 py-4">
                <div className="d-flex w-100 align-items-center">
                    <div className="d-flex justify-content-center align-self-center px-2 align-items-center">
                        <Avatar
                            size="small"
                            src={
                                patientHeader?.urlPhoto ?? "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"
                            }
                        />
                    </div>
                    <h4 className="text-secondary fw-bold px-2 my-0">Ayuda diagnóstica</h4>
                    <div className="mx px-2 d-flex flex-column">
                        <span className="text-muted">Paciente</span>
                        <div className="text-secondary fw-bold"> {patientHeader && patientHeader.name} </div>
                    </div>
                    <div className="mx px-2 d-flex flex-column">
                        <span className="text-muted">Identificación</span>
                        <div className="text-secondary fw-bold">
                            {patientHeader && formatteDocument({ value: patientHeader.cluDocument }).format}
                        </div>
                    </div>

                    <div className="mx px-2 d-flex flex-column">
                        <span className="text-muted">EPS</span>
                        <div className="text-secondary fw-bold"> {patientHeader && patientHeader?.eps}</div>
                    </div>
                    <div className="mx px-2 d-flex flex-column">
                        <span className="text-muted">Contrato</span>
                        <div className="text-secondary fw-bold"> {patientHeader && patientHeader?.companyType}</div>
                    </div>
                    <div className="mx px-2 d-flex flex-column">
                        <span className="text-muted">Edad</span>
                        <div className="text-secondary fw-bold"> {patientHeader && patientHeader?.age} años</div>
                    </div>
                </div>
            </div>
        );
    };
    const footer = () => {
        return (
            <div
                className="controls d-flex justify-content-between"
                style={{ borderRadius: "0px 0px 20px 20px" }}
            >
                <Dropdown className="dropdown">
                    <Button
                        endIcon={<IconChevronUp className="text-primary" />}
                        color="secondary"
                        variant="outline"
                        data-bs-toggle="dropdown"
                        style={{ width: 150 }}
                    >
                        {selectedOption.name}
                    </Button>
                    <DropdownMenu className="w-100">
                        {stageOptions.map((opt) => (
                            <DropdownItem
                                key={opt.prefix}
                                onClick={() => {
                                    if (opt.tab === "images") {
                                        if (dataForm && dataForm.images?.ao && dataForm.images?.ao?.length > 0) {
                                            uploadFilesFunction("ao", true);
                                            setFileSelected({
                                                value: dataForm.images?.ao[0].id ?? dataForm.images?.ao[0].value ?? "",
                                                url:
                                                    dataForm.images?.ao[0].url ??
                                                    `data:application/pdf;base64,${dataForm.images?.ao[0].base64}` ??
                                                    "",
                                            });
                                        } else if (dataForm && dataForm.images?.od && dataForm.images?.od?.length > 0) {
                                            uploadFilesFunction("od", true);
                                            setFileSelected({
                                                value: dataForm.images?.od[0].id ?? dataForm.images?.od[0].value ?? "",
                                                url:
                                                    dataForm.images?.od[0].url ??
                                                    `data:application/pdf;base64,${dataForm.images?.od[0].base64}` ??
                                                    "",
                                            });
                                        } else if (dataForm && dataForm.images?.oi && dataForm.images?.oi?.length > 0) {
                                            uploadFilesFunction("oi", true);
                                            setFileSelected({
                                                value: dataForm.images?.oi[0].id ?? dataForm.images?.oi[0].value ?? "",
                                                url:
                                                    dataForm.images?.oi[0].url ??
                                                    `data:application/pdf;base64,${dataForm.images?.oi[0].base64}` ??
                                                    "",
                                            });
                                        } else {
                                            setFileSelected({ url: "", value: "" });
                                        }
                                    }
                                    if (opt.tab === "report") {
                                        if (dataForm && dataForm?.report?.files && dataForm?.report?.files?.length > 0) {
                                            setFileSelected({
                                                value: dataForm?.report?.files[0].id ?? dataForm?.report?.files[0].value ?? "",
                                                url:
                                                    dataForm?.report?.files[0].url ??
                                                    `data:application/pdf;base64,${dataForm?.report?.files[0].base64}` ??
                                                    "",
                                            });
                                        } else {
                                            setFileSelected({ url: "", value: "" });
                                        }
                                        uploadFilesFunction("report", true);
                                    }
                                    setSelectedOption({ name: opt.name, prefix: opt.prefix, tab: opt.tab });
                                }}
                            >
                                {opt.name}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
                <div>
                    <Badge
                        style={{
                            backgroundColor: (history && history.statusBgColor) || "#E8E8EA",
                            color: (history && history.statusFontColor) || "#6E6F7C",
                        }}
                    >
                        {(history && history.statusName) ?? "No hay, no existe"}
                    </Badge>
                </div>
            </div>
        );
    };

    const unexpectedSituationSection = () => {
        return (
            <div className="px-5">
                <div className="px-2 py-3 ">
                    <div className="cardGeneralInfo row ">
                        <div className="col-3 fw-bold text-secondary">
                            <h5 className="fw-bold">Examen:</h5>
                        </div>
                        <div className="col-9 text-muted">
                            <h5 className="fw-bold">{structure && structure.name}</h5>
                        </div>
                        <div className="col-3 fw-bold text-secondary">
                            <h5 className="fw-bold">Médico remitente:</h5>
                        </div>
                        <div className="col-9 text-muted">
                            <h5 className="fw-bold">{(structure && structure.referringDoctor) ?? "Externo"}</h5>
                        </div>
                    </div>
                </div>
                <SectionCard
                    className="py-4 "
                    cardAidStyle
                >
                    <div>
                        <h5 className="text-secondary fw-bold pb-2">Situaciones inesperadas ayudas diagnósticas</h5>

                        {unexpectedSituationData &&
                            unexpectedSituationData?.length > 0 &&
                            unexpectedSituationData.map((situation, index) => {
                                return (
                                    <div className="d-flex flex-column">
                                        <span className="text-primary">
                                            {index + 1}. {situation.name} {situation.suspensionSeason && "(Causó suspensión de atención)"}
                                        </span>

                                        <div className="text-muted py-2">{situation.description}</div>
                                    </div>
                                );
                            })}
                    </div>
                </SectionCard>
            </div>
        );
    };
    const imagesSection = () => {
        return (
            <div className="px-5 h-100">
                <div className="row py-3">
                    <div className="col-5 d-flex ">
                        {dataForm?.images?.ao && dataForm?.images?.ao.length > 0 && (
                            <Button
                                variant={selectedSection === "ao" ? "primary" : "outline"}
                                className="mx-2"
                                onClick={() => uploadFilesFunction("ao", true)}
                            >
                                AO
                            </Button>
                        )}
                        {dataForm?.images?.od && dataForm?.images?.od.length > 0 && (
                            <Button
                                variant={selectedSection === "od" ? "primary" : "outline"}
                                className="mx-2"
                                onClick={() => uploadFilesFunction("od", true)}
                            >
                                OD
                            </Button>
                        )}
                        {dataForm?.images?.oi && dataForm?.images?.oi.length > 0 && (
                            <Button
                                variant={selectedSection === "oi" ? "primary" : "outline"}
                                className="mx-2"
                                onClick={() => uploadFilesFunction("oi", true)}
                            >
                                OI
                            </Button>
                        )}
                    </div>
                    <div className="col-7 d-flex">
                        <div className="col">
                            {/* <Select
                                key={trigger}
                                options={filesOptions}
                                onChange={({ option }) => {
                                    setFileSelected({
                                        ...fileSelected,
                                        url: (option.url !== "" ? option.url : `data:application/pdf;base64,${option.base64}`) ?? "",
                                    });
                                }}
                                value={filesOptions.find((option) => option.value === fileSelected.value)}
                            /> */}
                        </div>
                        {fileSelected.url && (
                            <div className="col-1">
                                <Button
                                    variant="text"
                                    onClick={() => setShowModalViewer(true)}
                                >
                                    <IconArrowsMaximize />
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                {fileSelected.url ? (
                    <PdfVisualizer
                        trigger={trigger}
                        pdfUrls={filesOptions.map(
                            (option) => (option.url !== "" ? option.url : `data:application/pdf;base64,${option.base64}`) ?? ""
                        )}
                    />
                ) : (
                    <h4
                        className=" w-100 text-muted d-flex align-items-center justify-content-center"
                        style={{ height: "80%" }}
                    >
                        Seleccione un segmento y un documento para visualizarlo
                    </h4>
                )}
            </div>
        );
    };
    const reportSection = () => {
        return (
            <div className="h-100 px-5">
                <div className="row py-3">
                    <div className="col-5 d-flex ">
                        {!fileSelected.url && (
                            <h4
                                className=" mb-2 w-100 text-muted d-flex align-items-center justify-content-center"
                                style={{ height: "80%" }}
                            >
                                Seleccione un documento para visualizarlo
                            </h4>
                        )}
                    </div>
                    <div className="col-7 d-flex">
                        <div className="col">
                            <Select
                                key={trigger}
                                options={filesOptions}
                                onChange={({ option }) => {
                                    setFileSelected({
                                        ...fileSelected,
                                        url: (option.url !== "" ? option.url : `data:application/pdf;base64,${option.base64}`) ?? "",
                                    });
                                }}
                                value={filesOptions.find((option) => option.value === fileSelected.value)}
                            />
                        </div>
                        {fileSelected.url && (
                            <div className="col-1">
                                <Button
                                    variant="text"
                                    onClick={() => setShowModalViewer(true)}
                                >
                                    <IconArrowsMaximize />
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                {fileSelected.url && (
                    <iframe
                        key={trigger}
                        title="document"
                        src={fileSelected?.url}
                        style={{ width: "100%", height: "100%" }}
                        className="pdfRender pb-4"
                    ></iframe>
                )}
            </div>
        );
    };
    const showFullScreenModal = () => {
        return (
            <Modal
                isOpen={showModalViewer}
                onClose={() => setShowModalViewer(false)}
                fullScreen
                className="d-flex justify-content-center"
            >
                <iframe
                    key={trigger}
                    title="document"
                    src={fileSelected?.url}
                    style={{ width: "100%", height: "90%" }}
                    className="pdfRender"
                ></iframe>
            </Modal>
        );
    };
    return (
        <>
            {showFullScreenModal()}
            <Modal
                onClose={handleCloseModal}
                isOpen={configModal.isOpenModal}
                fullScreen
                width={1000}
                className={"p-0"}
            >
                <div className="d-flex flex-column h-100">
                    {header()}
                    <hr className="m-0" />

                    <div
                        className="flex-grow-1"
                        style={{ backgroundColor: "#F5FCFD", overflowY: "auto" }}
                    >
                        {selectedOption.prefix === "diagnosticAids" && selectedOption.tab === "exam" && dataForm && (
                            <DiagnosticAidsSheet
                                onlyBodyAdxSheet={true}
                                structure={structure}
                                dataForm={dataForm}
                                isDisabled={true}
                                key={trigger}
                            />
                        )}
                        {selectedOption.prefix === "orders" && configModal.appId && <OrdersDetail appId={configModal.appId} />}
                        {selectedOption.prefix === "unexpectedSituation" && unexpectedSituationSection()}
                        {selectedOption.prefix === "background" && configModal.appId && <BackgroundDetail appId={configModal.appId} />}
                        {selectedOption.prefix === "diagnosticAids" && selectedOption.tab === "images" && imagesSection()}
                        {selectedOption.prefix === "diagnosticAids" && selectedOption.tab === "report" && reportSection()}
                    </div>

                    {footer()}
                </div>
            </Modal>
        </>
    );
};

export default DiagnosticAdxDetails;
