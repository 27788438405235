import routes from "@/constants/routes";
import { IconPlus, IconX } from "@tabler/icons-react";
import { ChangeEvent, useEffect, useState } from "react";
//
import { IModal } from "@/components/Modal/modal";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { fireCautionAlert, fireWarningAlert } from "@/utils";
//
import { Button, Checkbox, Modal, Select } from "@/components";
import { finishLoading, startLoading } from "@/config/slices";
import {
    DetailedClassification,
    GeneralClassification,
    SituationSuspendPayload,
    UnexpectedSituationPayload,
} from "@/models/shared/unexpectedSituation";
import { IconAlertCircle } from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import "./UnexpectedSituation.scss";
import {
    createUnexpectedSituation,
    getDetailedClassification,
    getGeneralClassification,
    getUnexpectedSituation,
    suspendAttentionMethod,
} from "./unexpectedSituation.actions";

export default function UnexpectedSituation({
    tab,
    status,
    stage,
    ...rest
}: IModal & { tab?: string; status?: string; stage?: { label: string; value: string } }) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const userRole = useAppSelector((state) => state.workspace.profile?.prefijo);
    const currentAppointment = useAppSelector((state) => state.patientAttention.appointmentSelected);
    const accountId = useAppSelector((state) => state.workspace.id);
    const appId = useAppSelector((state) => state.patientAttention.appointmentSelected.app_id);
    const userId = useAppSelector((state) => state.auth.user_data.id);
    const [trigger, setTrigger] = useState<number>(0);
    const [suspendAttention, setSuspendAttention] = useState<{ isActive: boolean; isCancelled: boolean }>({
        isActive: false,
        isCancelled: false,
    });
    const [tabSelected, setTabSelected] = useState<string | number>();
    const [unexpectedPayload, setUnexpectedPayload] = useState<UnexpectedSituationPayload[]>([]);
    const [generalClassificationOptions, setGeneralClassificationOptions] = useState<GeneralClassification[]>([]);
    const [detailedClassificationOptions, setDetailedClassificationOptions] = useState<DetailedClassification[]>([]);
    const [suspendSituationSelected, setSuspendSituationSelected] = useState<SituationSuspendPayload[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [statusAppointment, setStatusAppointment] = useState("");

    useEffect(() => {
        if (status) {
            setStatusAppointment(status);
        } else {
            setStatusAppointment(statusAppointment);
        }
    }, [currentAppointment, status]);

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(
                getUnexpectedSituation({
                    appId: rest.appId ?? appId,
                })
            );
            if (data) {
                setTrigger(trigger + 1);
                if (data.length > 0) {
                    const tempUnex: UnexpectedSituationPayload[] = [];
                    data.forEach((situation, index) => {
                        if (index === 0) {
                            setTabSelected(situation.unsId);
                            getDetailedClassificationFunction(situation.stcId);
                            tempUnex.push({ ...situation, index: situation.unsId, isSelected: true });
                        } else {
                            tempUnex.push({ ...situation, index: situation.unsId });
                        }
                    });
                    setUnexpectedPayload(tempUnex);
                } else {
                    const indexVar = Math.random().toString(16).slice(2);
                    setUnexpectedPayload([
                        {
                            createdBy: undefined,
                            decId: undefined,
                            sitDescription: "",
                            susReason: undefined,
                            unsId: undefined,
                            isSelected: true,
                            index: indexVar,
                            editingAllowed: 0,
                        },
                    ]);
                    setTabSelected(indexVar);
                }
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, accountId, rest.isOpen]);

    useEffect(() => {
        async function fetchData() {
            const attentionListData = await getGeneralClassification({ active: 1, appId: rest.appId ?? appId }, () => {
                // dispatch(finishLoading);
            });
            if (attentionListData) {
                setGeneralClassificationOptions(attentionListData as GeneralClassification[]);
            }
        }
        fetchData();
    }, [appId, rest.appId]);

    const getDetailedClassificationFunction = (stcId?: number) => {
        dispatch(startLoading());
        async function fetchData() {
            const attentionTimesData = await getDetailedClassification({ active: 1, appId: rest.appId ?? appId, stcId }, () => {
                dispatch(finishLoading());
            });
            setDetailedClassificationOptions(attentionTimesData as DetailedClassification[]);
        }
        fetchData();
    };
    const onCloseModal = () => {
        setUnexpectedPayload([]);
        setSuspendSituationSelected([]);
        setSuspendAttention({
            isActive: false,
            isCancelled: false,
        });
        setIsLoading(false);
        rest.onClose();
    };

    const handleChangeSituation = (tag: "sitDescription", value: string) => {
        const tempArr = [...unexpectedPayload];
        const search = tempArr.find((x) => x.index === tabSelected);
        if (search) search[tag] = value;
        setUnexpectedPayload(tempArr);
    };

    const handleChangeSituationSelect = (tag: "stcId" | "decId" | "editingAllowed", value: number) => {
        const tempArr = [...unexpectedPayload];
        const search = tempArr.find((x) => x.index === tabSelected);
        if (search) search[tag] = value;
        setUnexpectedPayload(tempArr);
    };

    const onChangeTab = (tab: UnexpectedSituationPayload) => {
        setTabSelected(tab.index);
        const elem = unexpectedPayload.map((el) => {
            if (el.index === tab.index) {
                el.stcId && getDetailedClassificationFunction(el.stcId);
                return { ...el, isSelected: true };
            } else {
                return { ...el, isSelected: false };
            }
        });
        setUnexpectedPayload(elem);
        setTrigger(trigger + 1);
    };

    const onDeleteTab = (tab: UnexpectedSituationPayload) => {
        const opt = unexpectedPayload.filter((el) => el.index !== tab.index);
        const form = opt[opt.length - 1];
        const b = opt?.map((el) => {
            if (el.index === form.index) {
                setTabSelected(form.index);
                el.stcId && getDetailedClassificationFunction(el.stcId);
                return { ...el, isSelected: true };
            } else {
                return el;
            }
        });
        setTrigger(trigger + 1);
        setUnexpectedPayload(b);
    };

    const onAddTab = () => {
        const indexVar = Math.random().toString(16).slice(2);
        if (unexpectedPayload.length > 0) {
            const lastUnexpectedSituation = unexpectedPayload[unexpectedPayload.length - 1];
            if (!!lastUnexpectedSituation.stcId && lastUnexpectedSituation.sitDescription && lastUnexpectedSituation.decId) {
                const opt: UnexpectedSituationPayload[] = [
                    ...unexpectedPayload.map((el) => ({ ...el, isSelected: false })),
                    {
                        createdBy: undefined,
                        decId: undefined,
                        sitDescription: "",
                        susReason: undefined,
                        unsId: undefined,
                        isSelected: true,
                        index: indexVar,
                        stcId: undefined,
                        editingAllowed: 0,
                    },
                ];
                setDetailedClassificationOptions([]);
                setTabSelected(indexVar);
                setUnexpectedPayload(opt);
                setTrigger(trigger + 1);
            } else {
                fireWarningAlert("Todos los campos son obligatorios para añadir una nueva situación", "Intenta de nuevo");
            }
        } else {
            const opt: UnexpectedSituationPayload[] = [
                ...unexpectedPayload.map((el) => ({ ...el, isSelected: false })),
                {
                    createdBy: undefined,
                    decId: undefined,
                    sitDescription: "",
                    susReason: undefined,
                    unsId: undefined,
                    isSelected: true,
                    index: indexVar,
                    stcId: undefined,
                    editingAllowed: 0
                },
            ];
            setDetailedClassificationOptions([]);
            setTabSelected(indexVar);
            setUnexpectedPayload(opt);
            setTrigger(trigger + 1);
        }
    };

    const handleSaveUnexpectedSituation = async (type: string, isCancelled: boolean) => {
        const data = {
            createdBy: accountId,
            appId: rest.appId ?? appId,
            userId,
            eaccount: accountId,
            situations: unexpectedPayload,
        };

        let isRepeated = true;
        let isEmpty = true;

        if (
            unexpectedPayload.length > 0 &&
            !!unexpectedPayload[0].sitDescription &&
            !!unexpectedPayload[0].stcId &&
            !!unexpectedPayload[0].decId
        ) {
            for (const item of unexpectedPayload) {
                isEmpty = !item.stcId || !item.decId || !item.sitDescription;
                if (isEmpty) {
                    fireWarningAlert("Para crear una situación inesperada, es necesario llenar todos los campos", "Intenta de nuevo");
                    break;
                }
            }
        }

        for (const item of unexpectedPayload) {
            isRepeated = unexpectedPayload
                .filter((e, i) => item.index !== e.index)
                .some((e) => e.stcId === item.stcId && e.decId === item.decId);
            if (isRepeated) {
                fireWarningAlert(
                    "Verifique campos de situaciones inesperadas, alguna combinación de clasificación general y detallada se está repitiendo",
                    "Intenta de nuevo"
                );
                break;
            }
        }
        if (unexpectedPayload.length === 0 || (!isRepeated && !isEmpty)) {
            setIsLoading(true);
            const isSuccess = await dispatch(createUnexpectedSituation(data));

            if (isSuccess) {
                if (type === "suspend") {
                    const data = await dispatch(
                        getUnexpectedSituation({
                            appId: rest.appId ?? appId,
                        })
                    );
                    if (data) {
                        setTrigger(trigger + 1);
                        if (data.length > 0) {
                            const tempUnex: UnexpectedSituationPayload[] = [];
                            data.forEach((situation, index) => {
                                if (index === 0) {
                                    setTabSelected(situation.unsId);
                                    getDetailedClassificationFunction(situation.stcId);
                                    tempUnex.push({ ...situation, index: situation.unsId, isSelected: true });
                                } else {
                                    tempUnex.push({ ...situation, index: situation.unsId });
                                }
                            });
                            setUnexpectedPayload(tempUnex);
                            setTimeout(() => {
                                setSuspendAttention({
                                    isActive: true,
                                    isCancelled,
                                });
                            }, 1000);
                        } else {
                            fireWarningAlert(
                                `Es necesario tener como mínimo una situación inesperada para poder suspender la ${
                                    tab === "surgery" ? "cirugía" : "consulta"
                                }`,
                                "Intenta de nuevo"
                            );
                        }
                        setIsLoading(false);
                    }
                } else {
                    if (userRole?.toLowerCase() === "nurse") {
                        await onCloseModal();
                    }
                    setIsLoading(false);
                }
            }
            setIsLoading(false);
        } else {
            if (isEmpty) {
                fireWarningAlert(
                    `Es necesario tener como mínimo una situación inesperada para poder suspender la ${
                        tab === "surgery" ? "cirugía" : "consulta"
                    }`,
                    "Intenta de nuevo"
                );
            } else if (isRepeated) {
                fireWarningAlert(
                    "Verifique campos de situaciones inesperadas, alguna combinación de clasificación general y detallada se está repitiendo",
                    "Intenta de nuevo"
                );
            }
        }
        setIsLoading(false);
    };

    const handleChangeSelectSituation = (event: ChangeEvent<HTMLInputElement>, situation: UnexpectedSituationPayload) => {
        let tempSuspendSituationList: SituationSuspendPayload[] = [...suspendSituationSelected];
        if (event.target.checked) {
            tempSuspendSituationList.push({ susReason: 1, unsId: situation.unsId });
        } else {
            tempSuspendSituationList = tempSuspendSituationList.filter((situationTemp) => situationTemp.unsId !== situation.unsId);
        }
        setSuspendSituationSelected(tempSuspendSituationList);
    };

    const suspendAttentionValidationFunction = () => {
        if (suspendSituationSelected.length > 0) {
            fireCautionAlert("No podrá revertir los cambios realizados", "¿Está seguro?", suspendAttentionFunction);
        } else {
            fireWarningAlert("Debe seleccionar como mínimo una situación inesperada", "Intenta de nuevo");
        }
    };

    const suspendAttentionFunction = async () => {
        setIsLoading(true);
        const isSuccess = await dispatch(
            suspendAttentionMethod({
                createdBy: accountId,
                appId: rest.appId ?? appId,
                userId,
                resource: location.pathname.includes("DiagnosticAids")
                    ? "diagnostic_aids"
                    : location.pathname.includes("surgery")
                        ? "surgery_ch"
                        : location.pathname.includes("consultation")
                            ? "attention"
                            : "",
                eaccount: accountId,
                situations: suspendSituationSelected,
                stgPrefix: location.pathname.includes("surgery") ? "surgery" : null,
                cancelled: suspendAttention.isCancelled ? "True" : null,
            })
        );
        if (isSuccess) {
            location.pathname.includes("surgery")
                ? navigate(routes.workspaceSurgery)
                : location.pathname.includes("DiagnosticAids")
                    ? navigate(routes.workspaceDiagnosticAids)
                    : navigate(routes.workspaceConsultation);
            await onCloseModal();
        }
        setIsLoading(false);
    };
    const renderSuspension = () => {
        return (
            <>
                <h5 className="mt-3 mx-auto text-muted">
                    Seleccione las situaciones que obligaron a suspender la {tab === "surgery" ? "cirugía" : "consulta"}
                </h5>
                {!suspendAttention.isCancelled && (
                    <span className="mx-auto text-muted my-0 py-1">
                        <IconAlertCircle height={15} /> <b className="">Las cirugías suspendidas podrán ser reprogramadas</b>
                    </span>
                )}
                <div className="d-flex flex-column mx-auto mt-3">
                    {unexpectedPayload.length > 0 &&
                        unexpectedPayload.map((situation) => {
                            return (
                                <Checkbox
                                    className="mb-3"
                                    key={situation.unsId}
                                    label={`${situation.unexpectedSituation}`}
                                    onChange={(event) => {
                                        handleChangeSelectSituation(event, situation);
                                    }}
                                />
                            );
                        })}
                </div>
                <div className="d-flex justify-content-center mt-3">
                    <Button
                        className="me-3"
                        variant="outline-danger"
                        onClick={() => {
                            setSuspendAttention({
                                isActive: false,
                                isCancelled: false,
                            });
                            setIsLoading(false);
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="danger"
                        isLoading={isLoading}
                        onClick={() => {
                            suspendAttentionValidationFunction();
                        }}
                    >
                        {suspendAttention.isCancelled ? "Cancelar ahora" : "Suspender ahora"}
                    </Button>
                </div>
            </>
        );
    };

    const dontShowSuspendButton = () => {
        let result = true;
        if (location.pathname.includes("surgery")) {
            if (
                stage?.value === "recovery" ||
                status === "in_recovery" ||
                status === "suspended" ||
                status === "canceled" ||
                status === "attended"
            ) {
                result = false;
            }
        }
        return result;
    };

    const renderModalContent = () => {
        const generalClassificationListOptions = generalClassificationOptions.map((item) => ({
            label: item.nameClassification,
            value: item.stcId,
        }));
        const detailedClassificationListOptions =
            detailedClassificationOptions.length > 0
                ? detailedClassificationOptions.map((item) => ({ label: item.nameClassificationDetail, value: item.decId }))
                : [];

        const generalValue = unexpectedPayload.find((x) => x.index === tabSelected)?.stcId;
        const detailedValue = unexpectedPayload.find((x) => x.index === tabSelected)?.decId;
        const editValue = unexpectedPayload.find((x) => x.index === tabSelected)?.editingAllowed;


        return (
            <>
                <div
                    className="d-flex align-items-center py-2"
                    style={{ overflowX: "auto" }}
                >
                    {unexpectedPayload?.length > 0 &&
                        unexpectedPayload?.map((sit, index) => (
                            <div
                                key={sit.index}
                                className={`tab-button ${sit.isSelected ? "isActive" : ""}`}
                                onClick={() => onChangeTab(sit)}
                            >
                                <span>Situación {index + 1}</span>
                                {!rest.isDisabledForm && (
                                    <IconX
                                        size={14}
                                        className="delete-icon ms-2 pointer"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            onDeleteTab(sit);
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    {statusAppointment !== "failed" && statusAppointment !== "canceled" && statusAppointment !== "suspended" && (
                        <div
                            className={"tab-button isActive"}
                            onClick={() => onAddTab()}
                        >
                            <IconPlus size={16} />
                        </div>
                    )}
                </div>
                {unexpectedPayload?.length > 0 ? (
                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <span className="text-label">Clasificación general</span>
                            <Select
                                key={trigger}
                                width={"initial"}
                                placeholder="Seleccionar..."
                                variant="plain"
                                options={generalClassificationListOptions}
                                name="stcId"
                                value={generalClassificationListOptions.find((item) => item.value === generalValue)}
                                onChange={(e) => {
                                    handleChangeSituationSelect("stcId", e?.option?.value);
                                    getDetailedClassificationFunction(e?.option?.value);
                                }}
                                style={{ width: "110%" }}
                                disabled={rest.isDisabledForm}
                            />
                        </div>
                        <div className="col-lg-6">
                            <span className="text-label">Clasificación detallada</span>
                            <Select
                                key={trigger}
                                width={"initial"}
                                placeholder="Seleccionar..."
                                variant="plain"
                                options={detailedClassificationListOptions}
                                name="decId"
                                value={detailedClassificationListOptions.find((item) => item.value === detailedValue)}
                                onChange={(e) => {
                                    handleChangeSituationSelect("decId", e.option.value);
                                }}
                                style={{ width: "110%" }}
                                disabled={rest.isDisabledForm}
                            />
                        </div>
                        {tab === "surgery" &&
                            <div className="col-lg-6 pt-2">
                                <Checkbox
                                    className="text-muted fw-normal"
                                    disabled={rest.isDisabledForm}
                                    label="Permite editar programación"
                                    onChange={({ target }) => {
                                        const value = target.checked ? 1 : 0;
                                        handleChangeSituationSelect("editingAllowed", value);
                                    }}
                                    checked={Boolean(editValue)}
                                />
                            </div>
                        }
                        <div className="col-lg-12">
                            <textarea
                                rows={10}
                                key={trigger}
                                className="form-control no-resize mt-3"
                                placeholder="Describir situación..."
                                defaultValue={unexpectedPayload.find((x) => x.index === tabSelected)?.sitDescription ?? ""}
                                onChange={(e) => {
                                    handleChangeSituation("sitDescription", e.target.value);
                                }}
                                disabled={rest.isDisabledForm}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="text-muted text-center py-5"> Añada una situación inesperada</div>
                )}
                <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex">
                        {dontShowSuspendButton() ||
                        (userRole?.toLowerCase() !== "nurse" &&
                            (statusAppointment === "consulting" || statusAppointment === "admitted")) ? (
                                <Button
                                    variant="danger"
                                    disabled={currentAppointment.status === "failed"}
                                    onClick={() => {
                                        handleSaveUnexpectedSituation("suspend", false);
                                    }}
                                >
                                    Suspender {tab === "surgery" ? "cirugía" : "consulta"}
                                </Button>
                            ) : (
                                <div></div>
                            )}
                        {dontShowSuspendButton() && location.pathname.includes("surgery") ? (
                            <div className="mx-2">
                                <Button
                                    variant="outline-danger"
                                    onClick={() => {
                                        handleSaveUnexpectedSituation("suspend", true);
                                    }}
                                >
                                    Cancelar {tab === "surgery" ? "cirugía" : "consulta"}
                                </Button>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                    {statusAppointment !== "failed" && statusAppointment !== "canceled" && statusAppointment !== "suspended" && (
                        <Button
                            variant="primary"
                            onClick={() => handleSaveUnexpectedSituation("save", false)}
                            isLoading={isLoading}
                            disabled={currentAppointment.status === "failed"}
                        >
                            Guardar
                        </Button>
                    )}
                </div>
            </>
        );
    };

    const render = () => {
        return (
            <Modal
                positionModal="center"
                width={!suspendAttention.isActive ? 700 : 500}
                {...rest}
                onClose={onCloseModal}
            >
                <h1 className="text-secondary fw-bold">Situación inesperada</h1>
                {!suspendAttention.isActive ? renderModalContent() : renderSuspension()}
            </Modal>
        );
    };

    return render();
}
