import React, { forwardRef } from "react";
import "./ScrollableTable.scss";

interface IScrollableTable extends React.HTMLAttributes<HTMLDivElement> {
    maxHeight?: number;
}

interface ICell extends React.HTMLAttributes<HTMLDivElement> {
    col?: number;
    align?: "left" | "right" | "center";
}
const ScrollableTableBase = forwardRef<HTMLDivElement, IScrollableTable>(({ maxHeight, className, children, ...rest }, ref) => {
    return (
        <div
            {...rest}
            className={`container-fluid scrollable-table ${className || ""}`}
            style={{ maxHeight, ...rest.style }}
        >
            {children}
        </div>
    );
});

const Head: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
    return (
        <div
            {...props}
            className="row table-head"
        >
            {props.children}
        </div>
    );
};

const Body = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((props, ref) => {
    return (
        <div
            {...props}
            ref={ref}
            className="row table-body"
        >
            <div
                className="col-12"
                style={{ overflowX: "hidden" }}
            >
                {props.children}
            </div>
        </div>
    );
});

const Row: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
    return (
        <div
            {...props}
            className="row"
        >
            {props.children}
        </div>
    );
};

const Cell: React.FC<ICell> = (props) => {
    return (
        <div
            {...props}
            className={`${props.col ? "col-" + props.col : "col"} table-cell ${props.className}`}
            style={{ ...props.style, textAlign: props.align }}
        >
            {props.children}
        </div>
    );
};

const ScrollableTable = Object.assign(ScrollableTableBase, {
    Head,
    Body,
    Row,
    Cell,
});

// Exportar con el tipo correcto para evitar errores en TypeScript
export default ScrollableTable;
