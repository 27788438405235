import { Checkbox, SectionCard, TextField } from "@/components";
import { AdmissionNotes, Checklist, VitalSignsARR } from "@/models/surgery/patientAttention/patientAttention";
import { useEffect, useState } from "react";
import TextConfigModal from "../../Consultation/AttentionOrigin/TextConfig/TextConfigNoIns";

const Admission = ({
    data,
    onSubmitNotes,
    readOnly,
}: {
    data?: AdmissionNotes;
    onSubmitNotes?: (section: string, payload: AdmissionNotes) => void;
    readOnly?: boolean;
}) => {
    const [admissionNotes, setAdmissionNotes] = useState<AdmissionNotes>({
        admissionNotes: "",
        checklist: [],
        userInfo: "",
        vitalSigns: [],
    });

    const [admissionModal, setAdmissionModal] = useState<{ isOpen: boolean; current: string }>({
        isOpen: false,
        current: "surgery_admission_notes",
    });

    const handleOpenNoInsTextModal = (name: string): void =>
        setAdmissionModal({
            current: name,
            isOpen: true,
        });

    const handleCloseNoInsTextModal = (): void =>
        setAdmissionModal({
            current: "surgery_admission_notes",
            isOpen: false,
        });

    const handleChangeDynamic = (value: string | number, name: string, id: number) => {
        if (admissionNotes && onSubmitNotes) {
            const payload = {
                ...admissionNotes,
                [name]: admissionNotes[name].map((key: VitalSignsARR) => (key.vitalSingId === id ? { ...key, value } : key)),
            };
            setAdmissionNotes(payload);
            onSubmitNotes("admission", payload);
        }
    };

    const handleChangeChecks = (value: string | number, name: string, id: number) => {
        if (admissionNotes && onSubmitNotes) {
            const payload = {
                ...admissionNotes,
                [name]: admissionNotes[name].map((key: Checklist) => (key.id === id ? { ...key, checked: value } : key)),
            };
            setAdmissionNotes(payload);
            onSubmitNotes("admission", payload);
        }
    };

    const handleChangeDescription = (value: string | number, name: string) => {
        if (admissionNotes && onSubmitNotes) {
            const payload = {
                ...admissionNotes,
                [name]: value,
            };
            setAdmissionNotes(payload);
            onSubmitNotes("admission", payload);
        }
    };

    useEffect(() => {
        if (data) {
            setAdmissionNotes(data);
        }
    }, [data]);

    return (
        <div className="my-3 mx-4 row">
            <div className="col-4">
                <SectionCard
                    className="mt-3"
                    cardAidStyle
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Signos vitales</h5>
                    </div>
                    <hr className="lineCards" />
                    <div className="row g-3 align-items-center">
                        {admissionNotes.vitalSigns &&
                            admissionNotes.vitalSigns?.length > 0 &&
                            admissionNotes.vitalSigns?.map((vital) => {
                                return (
                                    <div
                                        className="col-lg-6 d-flex align-items-center"
                                        key={vital.vitalSingId}
                                    >
                                        <div className="w-100">{vital.name}</div>
                                        <TextField
                                            value={vital.value}
                                            type="text"
                                            variant="plain"
                                            readOnly={readOnly}
                                            onChange={({ target }) => {
                                                const newValue = target.value.replace(/[^0-9/-]/g, "");
                                                handleChangeDynamic(newValue, "vitalSigns", vital.vitalSingId);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </SectionCard>
            </div>
            <div className="col-8">
                <SectionCard
                    className="mt-3"
                    cardAidStyle
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Notas de admisión</h5>
                    </div>
                    <hr className="lineCards" />
                    <textarea
                        className="form-control no-resize w-100"
                        style={{ height: 100 }}
                        value={admissionNotes.admissionNotes}
                        readOnly={readOnly}
                        onContextMenu={(event) => {
                            event.preventDefault();
                            !readOnly && handleOpenNoInsTextModal("surgery_admission_notes");
                        }}
                        onChange={({ target }) => {
                            handleChangeDescription(target.value, "admissionNotes");
                        }}
                    />
                </SectionCard>
            </div>
            <div className="col-12">
                <SectionCard
                    className="mt-3"
                    cardAidStyle
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Lista de chequeo</h5>
                    </div>
                    <hr className="lineCards" />
                    {admissionNotes?.checklist &&
                        admissionNotes?.checklist?.length > 0 &&
                        admissionNotes?.checklist?.map((check) => {
                            return (
                                <Checkbox
                                    key={check.id}
                                    className="text-muted fw-normal mb-2"
                                    label={check.description}
                                    checked={check?.checked === 1 ? true : false}
                                    readOnly={readOnly}
                                    onChange={({ target }) => {
                                        handleChangeChecks(target.checked ? 1 : 0, "checklist", check.id);
                                    }}
                                />
                            );
                        })}
                </SectionCard>
            </div>
            {!readOnly && (
                <TextConfigModal
                    sheet="nurseryNotes"
                    segment={admissionModal.current}
                    title="Textos predefinidos no institucionales"
                    isOpen={admissionModal.isOpen}
                    current={admissionModal.current as string}
                    onClose={handleCloseNoInsTextModal}
                    width={800}
                    positionModal="center"
                    onSelectItem={(_, item) => {
                        const payload = {
                            ...admissionNotes,
                            admissionNotes: admissionNotes.admissionNotes ? `${admissionNotes.admissionNotes}\n${item}` : item,
                        };
                        setAdmissionNotes(payload);
                        if (onSubmitNotes) {
                            onSubmitNotes("admission", payload);
                        }
                        handleCloseNoInsTextModal();
                    }}
                />
            )}
        </div>
    );
};

export default Admission;
