import { SectionCard } from "@/components";
import { useAppDispatch } from "@/hooks";
import { SectionsChanged } from "@/models/surgery/patientAttention/Anesthesiologist";
import { Surgery as ISurgery, Patient } from "@/models/surgery/patientAttention/headerSurgeryAttention";
import { ITabsDoctorsSheets } from "@/models/surgery/patientAttention/patientAttention";
import { SurgeonNotes } from "@/models/surgery/patientAttention/surgeon";
import { fireSuccessAlert } from "@/utils";
import { IconCircleCheck } from "@tabler/icons-react";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConsultationControls from "../../../Consultation/Controls/Controls";
import History from "../../../Consultation/History/History";
import SurgeryHeader from "../../components/HeaderSurgeryComponeny";
import { finishEvent } from "./anesthesiologist/anesthesiologist.action";
import { finishSurgeryEvent } from "./surgeon/surgeon.action";

interface ParentComponentProps {
    children: React.ReactNode;
    setDataTemplate?: (data: { clhId: number; cluId: number }) => void;
    sectionsChanged?: SectionsChanged | SurgeonNotes | undefined;
    sheet: string;
}



const defaultTabs: ITabsDoctorsSheets[] = [
    { label: "Preparación", value: "anesthesiaPreparation", isActive: false },
    { label: "Cirugía", value: "surgery", isActive: false },
    { label: "Recuperación y egreso", value: "recovery", isActive: false },
];


const TemplateBodyDoctor: React.FC<ParentComponentProps> = ({ children, setDataTemplate, sectionsChanged, sheet }) => {
    const location = useLocation();
    const appId = location.state.data.appId;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const portalAllowed = location.state.portalAllowed ?? "";

    const getTabsBySheet = (sheet: string): ITabsDoctorsSheets[] => {
        return defaultTabs.map((tab) => ({
            ...tab,
            isActive:
                (sheet === "anesthesiaNotes" && tab.value === "anesthesiaPreparation") ||
                (sheet !== "anesthesiaNotes" && tab.value === "surgery"),
        }));
    };


    const [tabs, setTabs] = useState<ITabsDoctorsSheets[]>(getTabsBySheet(sheet));

    useEffect(() => {
        setTabs(getTabsBySheet(sheet));
    }, [sheet]);

    const [services, setServices] = useState<{
        services: ISurgery[];
        patientInfo: Patient;
        status: string;
        clhId: number | null;
        cluId: number | null;
    }>({
        services: [],
        patientInfo: { age: "", document: "", documentType: "", fullName: "", id: 0, photo: "" },
        status: "",
        clhId: null,
        cluId: null,
    });

    function mapState(state: string): string {
        const stateMap: { [key: string]: string } = {
            preparation: "preparation",
            in_preparation: "preparation",
            in_surgery: "surgery",
            surgery: "surgery",
            in_recovery: "recovery",
            recovery: "recovery",
        };
        return stateMap[state] || state;
    }

    const renderStages = () => {
        return (
            <div className="attentionSurgeryControls-tabs mt-3">
                {tabs.map((tab) => (
                    <div
                        key={tab.value}
                        style={{ opacity: tab.isActive ? 1 : 0.5 }}
                        className={`attentionSurgeryControls-tab ${tab.isActive && "active"}`}
                        onClick={() => (!tab.isActive ? handleChangeTab(tab.value) : null)}
                    >
                        {tab.isActive && <IconCircleCheck />}
                        <span>{tab.label}</span>
                    </div>
                ))}
            </div>
        );
    };

    const handleChangeTab = (stage: string): void => {
        const statusTabChanged = tabs.map((tab) => (tab.value === stage ? { ...tab, isActive: true } : { ...tab, isActive: false }));
        setTabs(statusTabChanged);
    };

    const handleGoBack = (): void => navigate(-1);

    const finishEventFunction = async () => {
        if (!sectionsChanged) {
            console.error("sectionsChanged is undefined");
            return;
        }

        try {
            if (sheet === "surgery") {
                const data = sectionsChanged as SurgeonNotes;
                const response = await dispatch(finishSurgeryEvent({ ...data, clhId: services.clhId }));
                if (response.success) {
                    handleGoBack();
                    fireSuccessAlert(response.message, "Cerrado con éxito");
                }
                return sectionsChanged;
            } else {
                const data = sectionsChanged as SectionsChanged;
                const response = await dispatch(finishEvent(data));
                if (response.success) {
                    handleGoBack();
                }
                return sectionsChanged;
            }
        } catch (error) {
            console.error("Failed to finish event", error);
        }
    };

    const renderSurgeries = () => {
        return (
            <div className="row pb-3">
                {services?.services.length > 0
                    ? services?.services.map((service, ind) => {
                        return (
                            <div className="col">
                                <SectionCard
                                    className="w-100 py-2 px-3"
                                    cardAidStyle
                                    key={ind}
                                >
                                    <div>
                                        <div className="text-primary fw-bold">{service.name}</div>
                                        <div className="text-secondary">{service.surgeon}</div>
                                    </div>
                                </SectionCard>
                            </div>
                        );
                    })
                    : null}
            </div>
        );
    };
    const activeTab = useMemo(() => tabs.find((tab) => tab.isActive) || undefined, [tabs]);


    useEffect(() => {
        if (services.clhId && services.cluId && setDataTemplate)
            setDataTemplate({
                clhId: services.clhId,
                cluId: services.cluId,
            });
    }, [services.clhId, services.cluId]);

    return (
        <div className="d-flex flex-column w-100 h-100 surgeryBody">
            {location.state.data.appId && (
                <SurgeryHeader
                    portalAllowed={portalAllowed}
                    appId={appId}
                    setServices={setServices}
                    mapState={mapState}
                    dataSurgery={location.state}
                />
            )}
            <div className="d-flex h-100 overflow-hidden">
                <div className="d-flex flex-column w-100 h-100 overflow-hidden">
                    <div className="px-4 pt-3 ">{renderSurgeries()}</div>
                    {portalAllowed?.toLowerCase() === "anesthesiologist" && <div className="px-4 pb-4">{renderStages()}</div>}
                    <div
                        className="ps-4 pb-4 pt-1 h-100 flex-1"
                        style={{ overflowY: "auto", paddingRight: "1.25rem" }}
                    >
                        {children}
                    </div>
                    <ConsultationControls
                        sectionsChanged={sectionsChanged}
                        finishEvent={finishEventFunction}
                        tab={"surgery"}
                        appId={appId}
                        clhIdProp={services.clhId}
                        cluId={services.cluId || NaN}
                        stage={activeTab ? { label: activeTab.label, value: activeTab.value } : undefined}
                        statusSurgery={mapState(services.status)}
                        realStatusSurgery={services.status}
                    />
                </div>

                <History
                    showClose={false}
                    patientIdProp={location.state.data.patient.id}
                    patientAge={services.patientInfo.age}
                    patientName={services.patientInfo.fullName}
                    patientIdentification={services.patientInfo.document}
                    appId={appId}
                    defaultHistory="ophthalmology"
                    allData
                />
            </div>
        </div>
    );
};

export default TemplateBodyDoctor;
