import { Checkbox, RadioButton, SectionCard, TextField } from "@/components";
import { useAppSelector } from "@/hooks";
import { RecoveryNotes } from "@/models/surgery/patientAttention/patientAttention";
import { IconSquareRoundedPlusFilled } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import TextConfigModal from "../../Consultation/AttentionOrigin/TextConfig/TextConfigNoIns";
import SignatureComponent from "../components/Signature";
import { formatteDocument } from "@/utils";

const Recovery = ({ data, onSubmitNotes, readOnly }: {
    data?: RecoveryNotes;
    onSubmitNotes: (section: string, payload: RecoveryNotes) => void;
    readOnly?: boolean;
}) => {
    const userId = useAppSelector(state => state.auth.user_data.id);

    const [recoveryNotes, setRecoveryNotes] = useState<RecoveryNotes>();
    const [recoveryModal, setRecoveryModal] = useState<{ isOpen: boolean; current: string; }>({
        isOpen: false,
        current: "recoveryNotes"
    });
    const [firmModal, setFirmModal] = useState({
        isOpen: false,
        data: ""
    });
    const handleOpenNoInsTextModal = (name: string): void => setRecoveryModal({
        current: name, isOpen: true
    });

    const handleCloseNoInsTextModal = (): void => setRecoveryModal({
        current: "recoveryNotes", isOpen: false
    });

    const handleAttatchText = (text: string) => {
        if (recoveryNotes) {
            const payload = {
                ...recoveryNotes,
                numId: recoveryNotes.numId,
                numRecoveryNotes: recoveryNotes?.numRecoveryNotes?.length
                    ? `${recoveryNotes.numRecoveryNotes}\n${text}`
                    : text
            };
            setRecoveryNotes(payload);
            onSubmitNotes("recovery", payload);
        }
        handleCloseNoInsTextModal();
    };


    const handleChange = (value: string | number | boolean, name: string) => {
        if (recoveryNotes) {
            const payload = {
                ...recoveryNotes,
                numId: recoveryNotes.numId,
                [name]: value
            };
            setRecoveryNotes(payload);
            onSubmitNotes("recovery", payload);
        }
    };

    const handleChangeCompanion = (value: string | number, name: string) => {
        if (recoveryNotes && userId) {
            const payload = {
                ...recoveryNotes,
                numId: recoveryNotes.numId,
                companion: {
                    ...recoveryNotes.companion,
                    createdBy: Number(userId),
                    [name]: value
                }
            };
            setRecoveryNotes(payload);
            onSubmitNotes("recovery", payload);
        }
    };

    const onCloseFirmModal = () => {
        setFirmModal((state) => ({ ...state, isOpen: false }));
    };



    useEffect(() => {
        if (data) {
            setRecoveryNotes(data);
        }
    }, [data]);





    return (
        <div className="mx-3 mb-3 row">
            <SignatureComponent isOpen={firmModal.isOpen}
                handleChangeCompanion={handleChangeCompanion}
                onClose={onCloseFirmModal} 
                firm={recoveryNotes?.companion?.sucSignature} 
                setFirmModal={setFirmModal}
                dontClose
            />
            <div className="col-lg-12 mt-3">
                {recoveryNotes?.userInfo &&
                    <SectionCard className=" cardAidStyle mb-3 "  >
                        <span className="text-muted"><b className="text-secondary">Último cambio realizado por: </b>
                            {typeof recoveryNotes.userInfo === "string" ? recoveryNotes.userInfo : recoveryNotes.userInfo.fullName}
                        </span>
                    </SectionCard>}
            </div>
            <div className="col-6">
                <SectionCard cardAidStyle >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary required">Observación de recuperación</h5>
                    </div>
                    <hr className="lineCards" />
                    <textarea
                        className="form-control no-resize w-100"
                        style={{ height: 100 }}
                        onContextMenu={(event) => {
                            event.preventDefault();
                            handleOpenNoInsTextModal("recoveryNotes");
                        }}
                        onChange={({ target }) => handleChange(target.value, "numRecoveryNotes")}
                        value={recoveryNotes?.numRecoveryNotes}
                        readOnly={readOnly}
                    />
                </SectionCard>
            </div>
            <div className="col-6">
                <SectionCard className="mt-3" cardAidStyle >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary required">Acompañante</h5>
                    </div>
                    <hr className="lineCards" />
                    <div className="d-flex flex-column text-muted">
                        <div className="d-flex">
                            <div className="col-3 align-self-center">
                                Nombre
                            </div>
                            <div className="col-9">
                                <TextField
                                    value={recoveryNotes?.companion?.sucCompleteName}
                                    type="text"
                                    variant="plain"
                                    onChange={({ target }) => {
                                        handleChangeCompanion(target.value, "sucCompleteName");
                                    }}
                                    readOnly={readOnly}
                                />
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="col-3 align-self-center">
                                Cédula
                            </div>
                            <div className="col-9">
                                <TextField
                                    value={formatteDocument({value: recoveryNotes?.companion.sucDocumentNumber}).format ?? ""}
                                    type="text"
                                    variant="plain"
                                    onChange={({ target }) => {
                                        handleChangeCompanion(formatteDocument({ value:target.value}).value ?? "", "sucDocumentNumber");
                                    }}
                                    readOnly={readOnly}
                                />
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="col-3 align-self-center">
                                Firma
                            </div>
                            <div className="col-9 text-primary">
                                {firmModal.data !== "" || (recoveryNotes?.companion?.sucSignature !== null && recoveryNotes?.companion?.sucSignature !== undefined) ?
                                    <div
                                        onClick={() => !readOnly ? setFirmModal((state) => ({ ...state, isOpen: true })) : null}
                                    >
                                        <img height={40} alt="" src={firmModal.data ? `data:image/png;base64,${firmModal.data}` : recoveryNotes?.companion?.sucSignature} />
                                    </div>
                                    :
                                    <div style={{ height: 40 }} className="d-flex justify-content-start align-items-center">
                                        <div
                                            onClick={() => !readOnly ? setFirmModal((state) => ({ ...state, isOpen: true })) : null}
                                        >
                                            <IconSquareRoundedPlusFilled />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </SectionCard>
            </div>
            <div className="col-12">
                <SectionCard className="mt-3" cardAidStyle >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary required">Chequeos</h5>
                    </div>
                    <hr className="lineCards" />
                    <div className="d-flex flex-column">
                        <b className="text-muted "> El paciente y su acompañante reciben por escrito</b>
                        <Checkbox
                            className="text-muted fw-normal  align-items-start"
                            label="Información sobre el proceso realizado"
                            onChange={({ target }) => handleChange(target.checked, "numCheckInformationProcess")}
                            checked={recoveryNotes?.numCheckInformationProcess}
                            disabled={readOnly}
                        />
                        <Checkbox
                            className="text-muted fw-normal  align-items-start"
                            label="Instrucciones para su cuidado post-quirúrgico"
                            onChange={({ target }) => handleChange(target.checked, "numCheckInstrutionSelfCare")}
                            checked={recoveryNotes?.numCheckInstrutionSelfCare}
                            disabled={readOnly}
                        />
                        <Checkbox
                            className="text-muted fw-normal  align-items-start"
                            label="Formula médica"
                            onChange={({ target }) => handleChange(target.checked, "numCheckMedicalPrescription")}
                            checked={recoveryNotes?.numCheckMedicalPrescription}
                            disabled={readOnly}
                        />
                        <Checkbox
                            className="text-muted fw-normal  align-items-start"
                            label="Recordatorio de cita para revisión"
                            onChange={({ target }) => handleChange(target.checked, "numCheckAppoinmentReminder")}
                            checked={recoveryNotes?.numCheckAppoinmentReminder}
                            disabled={readOnly}
                        />
                        <b className="text-muted mt-2"> Correcta identificación y gestión de las muestras biológicas</b>
                        <div className=" d-flex">
                            <RadioButton
                                className="text-muted fw-normal"
                                label="Sí"
                                checked={recoveryNotes?.numCheckBiologicalSamples !== null && recoveryNotes?.numCheckBiologicalSamples ? true : false}
                                onChange={({ target }) => handleChange(target.checked && true, "numCheckBiologicalSamples")}
                                disabled={readOnly}
                            />
                            <RadioButton
                                className="text-muted fw-normal mx-2"
                                label="No aplica"
                                checked={recoveryNotes?.numCheckBiologicalSamples !== null ? recoveryNotes?.numCheckBiologicalSamples ? false : true : false}
                                onChange={({ target }) => handleChange(target.checked && false, "numCheckBiologicalSamples")}
                                disabled={readOnly}
                            />
                        </div>
                        <b className="text-muted mt-2"> Se realiza procedimiento quirúrgico completo</b>
                        <div className=" d-flex">
                            <RadioButton
                                className="text-muted fw-normal"
                                label="Sí"
                                checked={recoveryNotes?.numCheckSurgeryComplete !== null && recoveryNotes?.numCheckSurgeryComplete ? true : false}
                                onChange={({ target }) => handleChange(target.checked && true, "numCheckSurgeryComplete")}
                                disabled={readOnly}
                            />
                            <RadioButton
                                className="text-muted fw-normal mx-2"
                                label="No"
                                checked={recoveryNotes?.numCheckSurgeryComplete !== null ? recoveryNotes?.numCheckSurgeryComplete ? false : true : false}
                                onChange={({ target }) => handleChange(target.checked && false, "numCheckSurgeryComplete")}
                                disabled={readOnly}
                            />
                        </div>
                    </div>

                </SectionCard>
            </div>
            {!readOnly && (
                <TextConfigModal
                    title="Textos predefinidos no institucionales"
                    isOpen={recoveryModal.isOpen}
                    current=""
                    sheet="nurseryNotes"
                    segment={recoveryModal.current as string}
                    onClose={handleCloseNoInsTextModal}
                    width={800}
                    positionModal="center"
                    onSelectItem={(_, item) => handleAttatchText(item)}
                />
            )}
        </div>
    );
};

export default Recovery;