import { SectionCard } from "@/components";
import { LaboratoryExams, PhysicalExams, PreoperativeSegment } from "@/models/sheets/preoperative";
import { useState } from "react";
import TextConfigModal from "../../AttentionOrigin/TextConfig/TextConfigNoIns";

export default function MedicalExams({ preoperativePayload, setPreoperativePayload, onSubmit, isDisabledForm }: PreoperativeSegment) {
    const [noInsTextModal, setNoInsTextModal] = useState<{ isOpen: boolean; current: string | null }>({
        isOpen: false,
        current: null,
    });

    const oncChangeLaboratoryExams = (field: keyof LaboratoryExams, value: string) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            laboratoryExams: {
                ...preoperativePayload.laboratoryExams,
                [field]: value,
            },
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const oncChangePhysicalExams = (field: keyof PhysicalExams, value: string) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            physicalExam: {
                ...preoperativePayload.physicalExam,
                [field]: value,
            },
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const handleOpenNoInsTextModal = (name: string): void =>
        setNoInsTextModal({
            current: name,
            isOpen: true,
        });

    const handleCloseNoInsTextModal = (): void =>
        setNoInsTextModal({
            current: null,
            isOpen: false,
        });

    const previousData = () => {
        if (preoperativePayload?.physicalExam && noInsTextModal?.current) {
            return preoperativePayload?.physicalExam?.[noInsTextModal?.current];
        } else {
            return null;
        }
    };

    const handleAttachText = (text: string) => {
        oncChangePhysicalExams(String(noInsTextModal?.current), previousData() ? `${previousData()} ${text}` : `${text}`);
    };

    const renderLabsExams = () => {
        return (
            <SectionCard>
                <h5 className="fw-bold text-secondary">Exámenes de laboratorio</h5>
                <hr className="text-primary mt-2" />
                <div className="row g-3">
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Hemoglobina</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.hemoglobin}
                            onChange={(event) => {
                                const values = event.target.value.split(".")[1];
                                if (values && values.length > 2) {
                                    event.preventDefault();
                                } else {
                                    oncChangeLaboratoryExams("hemoglobin", event.target.value);
                                }
                            }}
                            onKeyDown={(e) => e.key === "+" && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Hematocrito</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.hematocrit}
                            onChange={(event) => {
                                if (Number(event.target.value) > 100) {
                                    event.preventDefault();
                                    return;
                                }
                                const values = event.target.value.split(".")[1];
                                if (values && values.length > 2) {
                                    event.preventDefault();
                                } else {
                                    oncChangeLaboratoryExams("hematocrit", event.target.value);
                                }
                            }}
                            onKeyDown={(e) => (e.key === "+" || e.key === "-") && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Leucocitos</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.leukocytes}
                            onChange={(event) => {
                                const values = event.target.value.split(".")[1];
                                if (values && values.length > 2) {
                                    event.preventDefault();
                                } else {
                                    oncChangeLaboratoryExams("leukocytes", event.target.value);
                                }
                            }}
                            onKeyDown={(e) => e.key === "+" && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Plaquetas</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.platelets}
                            onChange={(event) => {
                                const values = event.target.value.split(".")[1];
                                if (values && values.length > 2) {
                                    event.preventDefault();
                                } else {
                                    oncChangeLaboratoryExams("platelets", event.target.value);
                                }
                            }}
                            onKeyDown={(e) => e.key === "+" && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">T. Tromboplastina</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.thromboplastin}
                            onChange={(event) => oncChangeLaboratoryExams("thromboplastin", event.target.value)}
                            onKeyDown={(e) => (e.key === "+" || e.key === "." || e.key === "-") && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">T. Protrombina</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.prothrombin}
                            onChange={(event) => oncChangeLaboratoryExams("prothrombin", event.target.value)}
                            onKeyDown={(e) => (e.key === "+" || e.key === "." || e.key === "-") && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Glicemia</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.glycemia}
                            onChange={(event) => {
                                const values = event.target.value.split(".")[1];
                                if (values && values.length > 2) {
                                    event.preventDefault();
                                } else {
                                    oncChangeLaboratoryExams("glycemia", event.target.value);
                                }
                            }}
                            onKeyDown={(e) => e.key === "+" && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Creatinina</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.creatinine}
                            onChange={(event) => {
                                const values = event.target.value.split(".")[1];
                                if (values && values.length > 2) {
                                    event.preventDefault();
                                } else {
                                    oncChangeLaboratoryExams("creatinine", event.target.value);
                                }
                            }}
                            onKeyDown={(e) => e.key === "+" && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">TSH</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.tsh}
                            onChange={(event) => {
                                const values = event.target.value.split(".")[1];
                                if (values && values.length > 2) {
                                    event.preventDefault();
                                } else {
                                    oncChangeLaboratoryExams("tsh", event.target.value);
                                }
                            }}
                            onKeyDown={(e) => e.key === "+" && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Tiroides</div>
                        <input
                            disabled={isDisabledForm}
                            type="text"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.thyroid}
                            onChange={({ target }) => oncChangeLaboratoryExams("thyroid", target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">EKG</div>
                        <input
                            disabled={isDisabledForm}
                            type="text"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.ekg}
                            onChange={({ target }) => oncChangeLaboratoryExams("ekg", target.value)}
                        />
                    </div>
                    <div className="col">
                        <div className="text-center text-secondary">Otros</div>
                        <input
                            disabled={isDisabledForm}
                            type="text"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.other}
                            onChange={({ target }) => {
                                if (!target.value.length) {
                                    oncChangeLaboratoryExams("value", "");
                                }
                                oncChangeLaboratoryExams("other", target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="w-25 d-flex justify-content-end align-self-end mt-3">
                    <div className="d-flex flex-column align-items-center">
                        <div className="text-secondary">Valor</div>
                        <input
                            type="text"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.value}
                            disabled={!preoperativePayload.laboratoryExams?.other?.length || isDisabledForm}
                            onChange={({ target }) => oncChangeLaboratoryExams("value", target.value)}
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    const renderPhysicalExams = () => {
        return (
            <SectionCard className="mt-4">
                <h5 className="fw-bold text-secondary">Examen físico</h5>
                <hr className="text-primary mt-2" />
                <div className="row g-3">
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Corazón ritmo</div>
                        <textarea
                            disabled={isDisabledForm}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.cardiovascular}
                            onChange={({ target }) => oncChangePhysicalExams("cardiovascular", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("cardiovascular");
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Pulmonar</div>
                        <textarea
                            disabled={isDisabledForm}
                            style={{ minHeight: 48 }}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.pulmonary}
                            onChange={({ target }) => oncChangePhysicalExams("pulmonary", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("pulmonary");
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Extremidades</div>
                        <textarea
                            disabled={isDisabledForm}
                            style={{ minHeight: 48 }}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.extremities}
                            onChange={({ target }) => oncChangePhysicalExams("extremities", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("extremities");
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Sistema nervioso central</div>
                        <textarea
                            disabled={isDisabledForm}
                            style={{ minHeight: 48 }}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.centralNervousSystem}
                            onChange={({ target }) => oncChangePhysicalExams("centralNervousSystem", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("centralNervousSystem");
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Cuello</div>
                        <textarea
                            disabled={isDisabledForm}
                            style={{ minHeight: 48 }}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.neck}
                            onChange={({ target }) => oncChangePhysicalExams("neck", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("neck");
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Tórax</div>
                        <textarea
                            disabled={isDisabledForm}
                            style={{ minHeight: 48 }}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.thorax}
                            onChange={({ target }) => oncChangePhysicalExams("thorax", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("thorax");
                            }}
                        />
                    </div>

                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Soplos</div>
                        <textarea
                            disabled={isDisabledForm}
                            style={{ minHeight: 48 }}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.murmurs}
                            onChange={({ target }) => oncChangePhysicalExams("murmurs", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("murmurs");
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Evaluación cardiaca</div>
                        <textarea
                            disabled={isDisabledForm}
                            style={{ minHeight: 48 }}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.cardiacEvaluation}
                            onChange={({ target }) => oncChangePhysicalExams("cardiacEvaluation", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("cardiacEvaluation");
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Riesgo cardíaco</div>
                        <textarea
                            disabled={isDisabledForm}
                            style={{ minHeight: 48 }}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.cardiacRisk}
                            onChange={({ target }) => oncChangePhysicalExams("cardiacRisk", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("cardiacRisk");
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Abdomen</div>
                        <textarea
                            disabled={isDisabledForm}
                            style={{ minHeight: 48 }}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.abdomen}
                            onChange={({ target }) => oncChangePhysicalExams("abdomen", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("abdomen");
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">EKG</div>
                        <textarea
                            disabled={isDisabledForm}
                            style={{ minHeight: 48 }}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.ekg}
                            onChange={({ target }) => oncChangePhysicalExams("ekg", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("ekg");
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">ASA</div>
                        <textarea
                            disabled={isDisabledForm}
                            style={{ minHeight: 48 }}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.asa}
                            onChange={({ target }) => oncChangePhysicalExams("asa", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("asa");
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Plan anestésico</div>
                        <textarea
                            disabled={isDisabledForm}
                            style={{ minHeight: 48 }}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.anestheticPlan}
                            onChange={({ target }) => oncChangePhysicalExams("anestheticPlan", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("anestheticPlan");
                            }}
                        />
                    </div>

                    <div className="col-lg-9">
                        <div className="text-center text-secondary">Otros</div>
                        <textarea
                            disabled={isDisabledForm}
                            style={{ minHeight: 48 }}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.other}
                            onChange={({ target }) => oncChangePhysicalExams("other", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("other");
                            }}
                        />
                    </div>
                    <div className="col-lg-12">
                        <div className="text-center text-secondary">Recomendaciones</div>
                        <textarea
                            disabled={isDisabledForm}
                            style={{ minHeight: 48 }}
                            className="form-control"
                            value={preoperativePayload.physicalExam?.recommendations}
                            onChange={({ target }) => oncChangePhysicalExams("recommendations", target.value)}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("recommendations");
                            }}
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    const render = () => {
        return (
            <>
                <TextConfigModal
                    isOpen={noInsTextModal.isOpen}
                    current={noInsTextModal.current as string}
                    segment={noInsTextModal.current as string}
                    onClose={handleCloseNoInsTextModal}
                    sheet="preoperative"
                    width={800}
                    onSelectItem={(_, item) => handleAttachText(item)}
                />
                <h4 className="fw-bold text-muted text-center">Complete los resultados exámenes médicos del paciente</h4>
                {renderLabsExams()}
                {renderPhysicalExams()}
            </>
        );
    };

    return render();
}
