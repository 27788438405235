import { useEffect, useState } from "react";
//
import { Button, Modal, Select, TextField } from "@/components";
import { IModal } from "@/components/Modal/modal";
import { SelectOption } from "@/components/Select/select";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { Configuration, PredefinedText, PredefinedTextPayload, PredefinedTextsResponse } from "@/models";
import { fireWarningAlert } from "@/utils";
import { createPredefinedText, getPredefinedTexts, updatePredefinedText } from "../predefinedTexts.actions";
import { getConfiguration } from "./config.actions";

export default function PredefinedTextConfig({ data: configuration, onClose, onSuccess, ...rest }: IModal<PredefinedText>) {
    const dispatch = useAppDispatch();
    const account = useAppSelector((state) => state.workspace.id);
    const userId = useAppSelector((state) => state.auth.user_data.id);

    const PT_INITIAL_STATE: PredefinedTextPayload = {
        description: "",
        dtlSegment: 0,
        eaccount: account,
        users: [userId as number],
        type: "non-institutional",
    };

    const [modules, setModules] = useState<Configuration[]>([]);
    const [components, setComponents] = useState<Configuration[]>([]);
    const [segments, setSegments] = useState<Configuration[]>([]);
    const [dtlSegment, setDtlSegment] = useState<Configuration[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [configurationData, setConfigurationData] = useState<PredefinedTextPayload>(PT_INITIAL_STATE);

    useEffect(() => {
        if (configuration) {
            setConfigurationData({
                description: configuration.description,
                eaccount: configuration.eaccount,
                type: "non-institutional",
                users: [userId as number],
                moduleId: configuration.moduleId,
                componentId: configuration.componentId,
                segmentId: configuration.segId,
                dtlSegment: configuration.dtlSegmentId,
                updatedBy: userId as number,
                createdBy: userId as number,
                id: configuration.id,
            });
        }
    }, [configuration, userId]);

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getConfiguration({ entity: "modules" }));
            setModules(data as Configuration[]);
        }
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        async function fetchData() {
            if (configurationData.moduleId) {
                const data = await dispatch(
                    getConfiguration({
                        entity: "components",
                        moduleId: configurationData.moduleId,
                    })
                );
                setComponents(data as Configuration[]);
            }
        }
        fetchData();
    }, [dispatch, configurationData.moduleId]);

    useEffect(() => {
        async function fetchData() {
            if (configurationData.componentId) {
                const data = await dispatch(
                    getConfiguration({
                        entity: "segments",
                        componentId: configurationData.componentId,
                    })
                );
                setSegments(data as Configuration[]);
            }
        }
        fetchData();
    }, [dispatch, configurationData.componentId]);

    useEffect(() => {
        async function fetchData() {
            if (configurationData.segmentId) {
                const data = await dispatch(
                    getConfiguration({
                        entity: "dtlSegments",
                        segmentId: configurationData.segmentId,
                    })
                );
                setDtlSegment(data as Configuration[]);
            }
        }
        fetchData();
    }, [dispatch, configurationData.segmentId]);

    const formatConfigurations = (item: Configuration[]) => {
        return item?.map((el) => ({
            value: el.id,
            label: el.name || (el.fieldName as string),
        }));
    };

    const validateFields = (): boolean => {
        if (
            !configurationData.moduleId ||
            !configurationData.componentId ||
            !configurationData.segmentId ||
            !configurationData.dtlSegment ||
            !configurationData.description
        ) {
            fireWarningAlert("Señor usuario, asegúrese de completar todos los campos.", "Intenta de nuevo");
            return false;
        }
        return true;
    };

    const handleCloseModal = () => {
        setConfigurationData(PT_INITIAL_STATE);
        onClose();
    };

    const handleSelect = (option: SelectOption, selectName: string) => {
        if (selectName === "moduleId") {
            setConfigurationData({
                ...configurationData,
                moduleId: option.value,
                componentId: "",
                segmentId: "",
                dtlSegment: "",
            });
        }
        if (selectName === "componentId") {
            setConfigurationData({
                ...configurationData,
                componentId: option.value,
                segmentId: "",
                dtlSegment: "",
            });
        }
        if (selectName === "segmentId") {
            setConfigurationData({
                ...configurationData,
                segmentId: option.value,
                dtlSegment: "",
            });
        }
        if (selectName === "dtlSegment") {
            setConfigurationData({
                ...configurationData,
                dtlSegment: option.value,
            });
        }
    };

    const handleDescription = (value: string) => {
        setConfigurationData({
            ...configurationData,
            description: value,
        });
    };

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const isValid = validateFields();
            if (isValid) {
                if (configurationData.id) {
                    await dispatch(
                        updatePredefinedText({
                            ...configurationData,
                            updatedBy: userId as number,
                        })
                    );
                } else {
                    await dispatch(
                        createPredefinedText({
                            ...configurationData,
                            createdBy: userId as number,
                        })
                    );
                }
                const data = await dispatch(
                    getPredefinedTexts({
                        eaccount: account,
                        type: "non-institutional",
                        users: [userId as number],
                        perpage: 10,
                        page: 1,
                    })
                );
                onSuccess?.(data as PredefinedTextsResponse);
                handleCloseModal();
            }
        } finally {
            setIsLoading(false);
        }
    };

    const renderModalContent = () => {
        return (
            <div className="">
                <h3 className="text-title text-center">{configurationData.id ? "Editar" : "Crear"} texto predefinido no institucional</h3>
                <hr />
                {configuration?.id && (
                    <div>
                        <span className="text-label">Código</span>
                        <TextField
                            value={configuration?.id}
                            className="mb-3"
                            variant="default"
                            placeholder="Código"
                            readOnly
                        />
                    </div>
                )}
                <div className=" d-flex w-100 gap-2">
                    <Select
                        className="mb-3"
                        width={"100%"}
                        label="Módulo"
                        options={formatConfigurations(modules)}
                        onChange={({ option, name }) => handleSelect(option, name)}
                        value={formatConfigurations(modules)?.find((item) => item.value === configurationData.moduleId)}
                        name="moduleId"
                        emptyOptionsLabel="No hay opciones disponibles."
                    />
                    <Select
                        className="mb-3"
                        width={"100%"}
                        label="Componente"
                        options={formatConfigurations(components)}
                        name="componentId"
                        onChange={({ option, name }) => handleSelect(option, name)}
                        value={formatConfigurations(components)?.find((item) => item.value === configurationData.componentId)}
                        disabled={!configurationData.moduleId}
                        emptyOptionsLabel="No hay opciones disponibles."
                    />
                </div>
                <div className=" d-flex w-100 gap-2">
                    <Select
                        className="mb-3"
                        width={"100%"}
                        label="Segmento"
                        name="segmentId"
                        options={formatConfigurations(segments)}
                        onChange={({ option, name }) => handleSelect(option, name)}
                        value={formatConfigurations(segments)?.find((item) => item.value === configurationData.segmentId)}
                        disabled={!configurationData.componentId}
                        emptyOptionsLabel="No hay opciones disponibles."
                    />
                    <Select
                        className="mb-3"
                        width={"100%"}
                        label="Detalle del segmento"
                        name="dtlSegment"
                        options={formatConfigurations(dtlSegment)}
                        onChange={({ option, name }) => handleSelect(option, name)}
                        value={formatConfigurations(dtlSegment)?.find((item) => item.value === configurationData.dtlSegment)}
                        disabled={!configurationData.segmentId}
                        emptyOptionsLabel="No hay opciones disponibles."
                    />
                </div>

                <div>
                    <span className="text-label">Descripción</span>
                    <textarea
                        className="form-control no-resize mb-3"
                        rows={5}
                        placeholder="Escribe..."
                        onChange={({ target }) => handleDescription(target.value)}
                        value={configurationData.description}
                    />
                </div>
                <div className="d-flex align-items-center justify-content-end w-100">
                    <Button
                        variant="outline"
                        className="me-3"
                        onClick={handleCloseModal}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSubmit}
                        isLoading={isLoading}
                    >
                        Aceptar
                    </Button>
                </div>
            </div>
        );
    };

    const render = () => {
        return (
            <Modal
                onClose={handleCloseModal}
                {...rest}
            >
                {renderModalContent()}
            </Modal>
        );
    };

    return render();
}
