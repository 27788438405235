import { Button } from "@/components";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { useEffect, useState } from "react";

const PdfVisualizer = ({ trigger, pdfUrls, height }: { trigger: number; pdfUrls: string[]; height?: string }) => { 
    const [currentIndex, setCurrentIndex] = useState(0); 
    useEffect(() => {
        setCurrentIndex(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);
    

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? pdfUrls.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === pdfUrls.length - 1 ? 0 : prevIndex + 1));
    };

    const showButtons = pdfUrls.length > 1;
    return (
        <div
            className="w-100 "
            style={{ width: "100%", height: height ? height : "100%" }}
        >
            <div
                className="position-relative "
                style={{ width: "100%", height: height ? height : "100%" }}
            >
                <div className="text-end py-2">
                    {(currentIndex + 1)} de {pdfUrls.length} 
                </div>
                {showButtons && (currentIndex !== 0)  &&( 
                    <Button
                        variant="text"
                        style={{ alignSelf: "anchor-center", left: 10, opacity: 0.9 }}
                        className="position-absolute p-0"
                        onClick={goToPrevious}
                    >
                        <IconChevronLeft style={{ width: 40, height: 40 }} />
                    </Button>
                )}

                {showButtons && ((currentIndex + 1) !==  (pdfUrls.length))  && (
                    <Button
                        variant="text"
                        style={{ alignSelf: "anchor-center", right: 10, opacity: 0.9 }}
                        className="position-absolute p-0"
                        onClick={goToNext}
                    >
                        <IconChevronRight style={{ width: 40, height: 40 }} />
                    </Button>
                )}
                <iframe
                    key={trigger}
                    title="document"
                    src={pdfUrls[currentIndex]}
                    style={{ width: "100%", height:"100%" }}
                    className="pdfRender pb-4"
                ></iframe>

            </div>
        </div>
    );
};

export default PdfVisualizer;
