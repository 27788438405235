interface HistoryRedirectOptions {
    history?: { push: (location: { pathname: string; state?: object }) => void } | null;
    path: string;
    params?: Record<string, string | number | boolean>;
    getParams?: Record<string, string | number>;
    isInternal?: boolean;
}

export const historyRedirect = ({ history = null, path, params, isInternal = true }: HistoryRedirectOptions): void => {
    const queryParams: string[] = [];
    if (queryParams.length > 0) {
        path += `${path.includes("?") ? "&" : "?"}${queryParams.join("&")}`;
    }

    if (params && Object.keys(params).length > 0 && !isInternal) {
        const paramsArray = Object.entries(params).map(([key, value]) => `${key}=${encodeURIComponent(value)}`);
        path += `&params=${paramsArray.join("-")}`;
    }

    if (isInternal && history) {
        history.push({ pathname: path, state: params });
    } else if (!isInternal) {
        window.open(path, "_blank");
    }
};
