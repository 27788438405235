import { IconCheck, IconChevronRight, IconCirclePlus, IconPencil, IconSearch, IconTrash, IconX } from "@tabler/icons-react";
//
import { BadgeTextField, Button, Checkbox, Modal, Select, TextField } from "@/components";
//
import ScrollableTable from "@/components/ScrollableTable/ScrollableTable";
import {
    createPredefinedText,
    getPredefinedTexts,
    updatePredefinedText,
} from "@/features/Workspace/Gate/PredefinedTextsNoIns/predefinedTexts.actions";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { PredefinedText, PredefinedTextsResponse } from "@/models";
import { ExamConfig, Exams, FindingItems } from "@/models/physicalExam";
import { FindingItem, ITextConfigModal } from "@/models/select/findings";
import { useEffect, useRef, useState } from "react";
import { getFindings } from "./textConfig.actions";
import "./TextConfig.scss";

interface TextPayload {
    id?: number;
    dtlSegmentId?: number;
    currentReason: string;
    selectedText: string;
    isSelectedTextDisabled: boolean;
    creatable?: boolean;
}

export default function TextConfigModal({ config, onClose, onSaveConfig, ...rest }: ITextConfigModal) {
    const dispatch = useAppDispatch();
    const accountId = useAppSelector((state) => state.workspace.id);
    const userId = useAppSelector((state) => state.auth.user_data.id);
    const selectedTextRef = useRef<HTMLTextAreaElement>(null);
    const [findings, setFindings] = useState<FindingItem[]>([]);
    const [predefinedTextsResult, setPredefinedTextsResult] = useState<PredefinedTextsResponse>();
    const [searchValue, setSearchValue] = useState<string>("");
    const [textPayload, setTextPayload] = useState<TextPayload>({
        currentReason: "",
        selectedText: "",
        isSelectedTextDisabled: true,
    });

    const CONFIG_INITIAL_STATE: ExamConfig = {
        [config.currentEye]: {
            findings: [],
            justifications: "",
            normal: config.isNormal,
        },
        prefix: config.segmentPrefix as Exams,
    };

    const [result, setResult] = useState<ExamConfig>(CONFIG_INITIAL_STATE);
    useEffect(() => {
        if (config) {
            setResult((prevState) => ({
                ...prevState,
                [config.currentEye]: {
                    findings: config.options?.findings,
                    justifications: config.options?.justifications,
                    normal: config.options?.normal,
                },
                prefix: config.segmentPrefix as Exams,
            }));
        }
    }, [config]);

    useEffect(() => {
        if (!textPayload.isSelectedTextDisabled) selectedTextRef.current?.focus();
    }, [textPayload.isSelectedTextDisabled]);

    useEffect(() => {
        async function fetchData() {
            if (rest.isOpen) {
                const data = await dispatch(
                    getPredefinedTexts({
                        eaccount: accountId,
                        type: "non-institutional",
                        search: searchValue,
                        users: [userId as number],
                        active: 1,
                        dtlSegmentPrefix: config.segmentPrefix,
                    })
                );
                setPredefinedTextsResult(data as PredefinedTextsResponse);
            }
        }
        fetchData();
    }, [dispatch, searchValue, userId, accountId, config.segmentPrefix, rest.isOpen]);

    useEffect(() => {
        async function fetchData() {
            if (config.segment) {
                const data = await dispatch(
                    getFindings({
                        segmentDetId: config.segment as number,
                        normal: config.options?.normal,
                    })
                );
                setFindings(data as FindingItem[]);
            }
        }
        fetchData();
    }, [dispatch, config.segment, config.options?.normal]);

    const handleSearch = (value: string) => setSearchValue(value);

    const handleOnCloseModal = () => {
        setFindings([]);
        setResult(CONFIG_INITIAL_STATE);
        setTextPayload({
            id: undefined,
            dtlSegmentId: undefined,
            currentReason: "",
            isSelectedTextDisabled: true,
            selectedText: "",
        });
        onClose();
    };

    const handleSetNormal = async (checked: boolean) => {
        const parsedBool = checked ? 1 : 0;
        const options = {
            ...result,
            [config.currentEye]: {
                ...result[config.currentEye],
                normal: parsedBool,
                findings: [],
                justifications: result[config.currentEye]?.justifications ?? "",
            },
        };
        setResult(options);
        const data = await dispatch(
            getFindings({
                segmentDetId: config.segment as number,
                normal: checked ? 1 : 0,
            })
        );
        setFindings(data as FindingItem[]);
    };
    const handleAddFinding = (item: FindingItem | undefined) => {
        if (item) {
            const options = {
                ...result,
                [config.currentEye]: {
                    ...result[config.currentEye],
                    findings: [
                        ...((result[config.currentEye]?.findings as FindingItems[]) || []),
                        {
                            id: item.id,
                            name: item.name,
                            order: (result[config.currentEye]?.findings?.length as number) + 1 || config.options?.findings?.length + 1 || 1,
                        },
                    ],
                    justifications: result[config.currentEye]?.justifications as string,
                    // normal: item.normal,
                },
            };
            setResult(options);
        }
    };

    const handleRemoveFinding = (itemId: number | string) => {
        let options: ExamConfig = { ...result };
        options = {
            ...options,
            [config.currentEye]: {
                findings: options[config.currentEye]?.findings.filter((item) => item.id !== itemId) as FindingItems[],
                justifications: "" as string,
            },
        };
        if ((options[config.currentEye]?.findings?.length as number) < 1) {
            options = {
                ...options,
                [config.currentEye]: {
                    ...options[config.currentEye],
                    justifications: "",
                },
            };
        }
        setResult(options);
    };

    const handleSelectText = (text: PredefinedText) => {
        setTextPayload({
            ...textPayload,
            id: text.id,
            dtlSegmentId: text.dtlSegmentId,
            selectedText: text.description,
            currentReason: text.description,
            isSelectedTextDisabled: true,
        });
    };

    const handleChangeReason = (value: string) => {
        let options: ExamConfig = { ...result };
        options = {
            ...options,
            [config.currentEye]: {
                ...options[config.currentEye],
                findings: options[config.currentEye]?.findings as FindingItems[],
                justifications: value,
            },
        };
        setResult(options);
    };

    const handleAddNewText = async () => {
        setTextPayload({
            dtlSegmentId: config.segment as number,
            selectedText: "",
            currentReason: "",
            isSelectedTextDisabled: false,
            creatable: true,
        });
    };

    const handleAttachText = (text: string) => {
        let options: ExamConfig = { ...result };
        options = {
            ...options,
            [config.currentEye]: {
                ...options[config.currentEye],
                findings: options[config.currentEye]?.findings as FindingItems[],
                justifications: options[config.currentEye]?.justifications?.length
                    ? `${options[config.currentEye]?.justifications}\n${text}`
                    : text,
            },
        };
        setResult(options);
    };

    const handleUpdateJustification = (value: string) => {
        setTextPayload({
            ...textPayload,
            currentReason: value,
        });
    };

    const isSuccessRequest = async () => {
        const data = await dispatch(
            getPredefinedTexts({
                eaccount: accountId,
                type: "non-institutional",
                search: searchValue,
                users: [userId as number],
                active: 1,
                dtlSegmentPrefix: config.segmentPrefix,
            })
        );
        setPredefinedTextsResult(data as PredefinedTextsResponse);
        setTextPayload({
            id: undefined,
            currentReason: "",
            selectedText: "",
            isSelectedTextDisabled: true,
        });
    };

    const handleCreatePredefinedText = async () => {
        const isSuccess = await dispatch(
            createPredefinedText({
                type: "non-institutional",
                createdBy: userId as number,
                description: textPayload.currentReason,
                dtlSegment: config.segment,
                eaccount: accountId,
                users: [userId as number],
            })
        );
        if (isSuccess) {
            await isSuccessRequest();
        }
    };

    const handleUpdatePredefinedtext = async () => {
        const isSuccess = await dispatch(
            updatePredefinedText({
                id: textPayload.id,
                updatedBy: userId as number,
                type: "non-institutional",
                description: textPayload.currentReason,
                dtlSegment: config.segment,
                eaccount: accountId,
                active: true,
            })
        );
        if (isSuccess) {
            await isSuccessRequest();
        }
    };

    const handleDeletePredefinedText = async () => {
        const isSuccess = await dispatch(
            updatePredefinedText({
                active: false,
                eaccount: accountId,
                id: textPayload.id,
                updatedBy: userId as number,
            })
        );
        if (isSuccess) {
            await isSuccessRequest();
        }
    };

    const renderFinding = () => {
        const findingsFormatted = findings.map((item) => ({ label: item.name, value: item.id }));

        return (
            <>
                <div className="d-flex align-items-center w-100 mb-2">
                    <Select
                        width="100%"
                        style={{ width: "100%" }}
                        variant="filled"
                        placeholder="Buscar hallazgo..."
                        options={findingsFormatted}
                        onChange={({ option }) => handleAddFinding(findings.find((item) => item.id === option.value))}
                        isSearchable
                        clearOnSelect
                        disabled={result[config.currentEye]?.normal ? true : false || rest.isDisabledForm}
                    />
                </div>
                <BadgeTextField
                    disabled={rest.isDisabledForm}
                    className="w-100 mt-2 mb-2"
                    bookmarks={result[config.currentEye]?.findings?.map((fin) => ({ label: fin.name as string, value: fin.id, erasable: fin.name !== "Normal" }))}
                    onDeleteItem={(itemId) => handleRemoveFinding(itemId)}
                    style={{ height: 80 }}
                    isErasable={!rest.isDisabledForm && !result[config.currentEye]?.normal}
                />
            </>
        );
    };

    const renderRow = (item: PredefinedText) => {
        return (
            <ScrollableTable.Row key={item.id}>
                <ScrollableTable.Cell
                    col={4}
                    onClick={() =>
                        (!rest.isDisabledForm || !result[config.currentEye]?.findings?.length) && handleAttachText(item.description)
                    }
                    className="pointer"
                >
                    {item.id}
                </ScrollableTable.Cell>
                <ScrollableTable.Cell
                    col={6}
                    onClick={() =>
                        (!rest.isDisabledForm || !result[config.currentEye]?.findings?.length) && handleAttachText(item.description)
                    }
                    className="pointer"
                >
                    {item.description}
                </ScrollableTable.Cell>
                <ScrollableTable.Cell
                    col={2}
                    className="justify-content-center"
                >
                    <IconChevronRight
                        size={15}
                        className="text-secondary pointer"
                        onClick={() => (!rest.isDisabledForm || !result[config.currentEye]?.findings?.length) && handleSelectText(item)}
                    />
                </ScrollableTable.Cell>
            </ScrollableTable.Row>
        );
    };

    const renderReason = () => {
        return (
            <>
                <textarea
                    className="form-control no-resize"
                    rows={5}
                    disabled={rest.isDisabledForm || !result[config.currentEye]?.findings?.length}
                    value={result[config.currentEye]?.justifications}
                    onChange={({ target }) => handleChangeReason(target.value)}
                />
                <div className="d-flex justify-content-between align-items-center w-100 mt-3">
                    <span className="text-primary fw-bold fs-5">Textos predefinidos no institucionales</span>
                    <Button
                        disabled={rest.isDisabledForm || !result[config.currentEye]?.findings?.length}
                        variant="text"
                        startIcon={<IconCirclePlus />}
                        onClick={() => handleAddNewText()}
                    >
                        Nuevo texto
                    </Button>
                </div>
                <TextField
                    disabled={rest.isDisabledForm || !result[config.currentEye]?.findings?.length}
                    variant="filled"
                    placeholder="Escribe aquí código o descripción"
                    className="w-100 mt-2 mb-2"
                    endIcon={<IconSearch />}
                    value={searchValue}
                    onChange={({ target }) => handleSearch(target.value)}
                />
                <div
                    className="mb-2"
                    style={{ borderRadius: "1rem" }}
                >
                    <ScrollableTable
                        className="my-3"
                        maxHeight={150}
                    >
                        <ScrollableTable.Head>
                            <ScrollableTable.Cell col={4}>Código</ScrollableTable.Cell>
                            <ScrollableTable.Cell col={6}>Descripción</ScrollableTable.Cell>
                            <ScrollableTable.Cell col={2}></ScrollableTable.Cell>
                        </ScrollableTable.Head>
                        {result[config.currentEye]?.findings && (result[config.currentEye]?.findings?.length ?? [].length) > 0 && (
                            <ScrollableTable.Body>{predefinedTextsResult?.results?.map((item) => renderRow(item))}</ScrollableTable.Body>
                        )}
                    </ScrollableTable>
                </div>
                <textarea
                    ref={selectedTextRef}
                    className="form-control no-resize"
                    rows={3}
                    value={textPayload.currentReason}
                    onChange={({ target }) => handleUpdateJustification(target.value)}
                    disabled={textPayload.isSelectedTextDisabled || rest.isDisabledForm}
                />
                <div className="d-flex align-items-center text-muted mt-2">
                    {textPayload.isSelectedTextDisabled ? (
                        textPayload.selectedText ? (
                            <>
                                <IconPencil
                                    size={20}
                                    className="pointer me-2"
                                    onClick={() =>
                                        setTextPayload({
                                            ...textPayload,
                                            isSelectedTextDisabled: false,
                                            creatable: false,
                                        })
                                    }
                                />
                                <IconTrash
                                    size={20}
                                    className="pointer"
                                    onClick={handleDeletePredefinedText}
                                />
                            </>
                        ) : null
                    ) : (
                        <>
                            <IconCheck
                                size={20}
                                className="text-success pointer me-2"
                                onClick={textPayload.creatable ? handleCreatePredefinedText : handleUpdatePredefinedtext}
                            />
                            <IconX
                                size={20}
                                className="text-danger pointer"
                                onClick={() =>
                                    setTextPayload({
                                        ...textPayload,
                                        isSelectedTextDisabled: true,
                                        currentReason: textPayload.selectedText,
                                    })
                                }
                            />
                        </>
                    )}
                </div>
            </>
        );
    };

    const onSubmit = (data: ExamConfig) => {
        onSaveConfig(data, config.currentEye);
    };

    const render = () => {
        return (
            <Modal
                modalTitle={config.segmentName}
                onClose={handleOnCloseModal}
                className="textconfig-modal align-self-center"
                {...rest}
            >
                <div className="d-flex justify-content-between align-items-center">
                    <p className="text-secondary fw-bold fs-2 mb-2">Hallazgos</p>
                    <Checkbox
                        label="Normal"
                        disabled={rest.isDisabledForm}
                        checked={result[config.currentEye]?.normal ? true : false}
                        onChange={({ target }) => handleSetNormal(target.checked)}
                    />
                </div>

                {renderFinding()}
                <p className="text-secondary fw-bold fs-2 mb-2">Justificación</p>

                {renderReason()}

                <Button 
                    variant="primary" 
                    className="align-self-end mt-3" 
                    onClick={() => {
                        onSubmit(result);
                        handleOnCloseModal();
                    }}>
                    Guardar
                </Button>
            </Modal>
        );
    };

    return render();
}
