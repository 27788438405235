import { Button, RadioButton, SectionCard, Select } from "@/components";
import { setAttentionEnd } from "@/config/slices/canAttentionEnd";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { userData } from "@/models/sheets/orthoptic";
import { PreoperativeSegment } from "@/models/sheets/preoperative";
import { IconFiles } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { getUsers } from "../../../Surgery/patientAttentionSurgery.action";
import { getCanAttentionEnd } from "../../consultation.actions";

export default function Approval({ preoperativePayload, setPreoperativePayload, onSubmit, isDisabledForm }: PreoperativeSegment) {
    const signature = useAppSelector((state) => state.auth.user_data.signature);
    const dispatch = useAppDispatch();
    const workspace = useAppSelector((state) => state.workspace);
    const medicalFormId = useAppSelector((state) => state.patientAttention.patientStatus.mcfId);
    const [users, setUsers] = useState<userData[]>([]);
    const riskOptions = [
        { label: "Bajo", value: 1 },
        { label: "Normal", value: 2 },
        { label: "Alto", value: 3 },
    ];

    useEffect(() => {
        async function fetchData() {
            if (!isDisabledForm) {
                const data = await dispatch(getCanAttentionEnd(medicalFormId));
                dispatch(setAttentionEnd(data.results ?? false));
            }
        }
        fetchData();
    }, [dispatch, medicalFormId, isDisabledForm]);

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getUsers({ anesthesia_assistant: 1, eaccount: workspace.id }));
            setUsers(data as userData[]);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, workspace.id]);

    const onChangeAnestheticRisk = (value: 1 | 0) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            suitableForSurgery: {
                ...preoperativePayload.suitableForSurgery,
                suitable: value,
            },
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const onChangeJustification = (value: string) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            suitableForSurgery: {
                ...preoperativePayload.suitableForSurgery,
                justification: value,
            },
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const onChangeRiskValue = (value: number) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            suitableForSurgery: {
                ...preoperativePayload.suitableForSurgery,
                anestheticRisk: value,
            },
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const onChangeAssistance = (value: number) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            suitableForSurgery: {
                ...preoperativePayload.suitableForSurgery,
                anestheticAssistant: value,
            },
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const onChangeSigned = (value?: boolean) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            suitableForSurgery: {
                ...preoperativePayload.suitableForSurgery,
                signed: !value,
            },
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const render = () => {
        const riskValue = riskOptions.find((item) => item.value === preoperativePayload.suitableForSurgery?.anestheticRisk);
        const formattedUsers = users.map((item) => ({
            value: item.id,
            label: `${item.first_name} ${item.first_surname}`,
        }));
        const helperValue = formattedUsers.find((item) => item.value === preoperativePayload.suitableForSurgery?.anestheticAssistant);

        return (
            <SectionCard className="align-items-start align-self-center">
                <h5 className="fw-bold text-secondary">Apto para cirugía</h5>
                <hr className="text-primary mt-2" />
                <div className="select-option-item">
                    <span>El paciente se encuentra apto para cirugía</span>
                    <div className="flex-grow-1"></div>
                    <RadioButton
                        disabled={isDisabledForm}
                        className="me-5"
                        label="Sí"
                        name="option-1"
                        id="yes"
                        checked={preoperativePayload.suitableForSurgery?.suitable === 1}
                        onChange={() => onChangeAnestheticRisk(1)}
                    />
                    <RadioButton
                        disabled={isDisabledForm}
                        label="No"
                        name="option-1"
                        id="no"
                        checked={preoperativePayload.suitableForSurgery?.suitable === 0}
                        onChange={() => onChangeAnestheticRisk(0)}
                    />
                </div>
                <textarea
                    className="form-control mt-3 no-resize"
                    disabled={preoperativePayload.suitableForSurgery?.suitable === null || isDisabledForm}
                    value={preoperativePayload.suitableForSurgery?.justification}
                    onChange={({ target }) => onChangeJustification(target.value)}
                />
                <p className="mt-3">Clasificación riesgo anestésico</p>
                <hr className="w-100 mt-0" />
                <Select
                    value={riskValue}
                    options={riskOptions}
                    onChange={({ option }) => onChangeRiskValue(option.value)}
                    disabled={preoperativePayload.suitableForSurgery?.suitable === null || isDisabledForm}
                />
                <div className="d-flex justify-content-between w-100 align-items-end">
                    <Button
                        className="mt-3"
                        variant="text"
                        startIcon={<IconFiles />}
                        onClick={() => onChangeSigned(preoperativePayload.suitableForSurgery?.signed)}
                        disabled={preoperativePayload.suitableForSurgery?.suitable === null || isDisabledForm}
                    >
                        {preoperativePayload.suitableForSurgery?.signed ? "Quitar firma" : "Agregar firma"}
                    </Button>
                    <Select
                        value={helperValue}
                        options={formattedUsers}
                        label="Ayudante de anestesia"
                        placeholder="Seleccionar..."
                        onChange={({ option }) => onChangeAssistance(option.value)}
                        disabled={preoperativePayload.suitableForSurgery?.suitable === null || isDisabledForm}
                    />
                </div>
                {preoperativePayload.suitableForSurgery?.signed === true && (
                    <img
                        src={signature}
                        alt="doc signature"
                        style={{ maxWidth: 150 }}
                    />
                )}
            </SectionCard>
        );
    };

    return render();
}
