import { Button, SectionCard, Select } from "@/components";
import TextConfigModal from "@/features/Workspace/PatientAttention/Consultation/AttentionOrigin/TextConfig/TextConfigNoIns";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { AnesthesiaNotes, SectionsChanged } from "@/models/surgery/patientAttention/Anesthesiologist";
import { CreateVitalSingsPayload, RecordChart, VitalSign } from "@/models/surgery/patientAttention/patientAttention";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import RecordChartComponent from "../../../components/RecordChart";
import VitalSignsModal from "../../../components/VitalSingsModalComponent";
import { createVitalSingsSurgery } from "../../../patientAttentionSurgery.action";
import TemplateBodyDoctor from "../TemplateBodyDoctors";
import { getAneType, getAnesthesiaNotes, getCurrentAnestesiologist, getOxygenTypes, updateSections } from "./anesthesiologist.action";

const Anesthesiologist = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const userId = useAppSelector((state) => state.auth.user_data.id);
    const portalAllowed = location.state.portalAllowed ?? "";
    const isDisabledFields = portalAllowed !== "anesthesiologist";

    const data = location.state.data || {};
    const ansId = location.state.startAttentionInfo.ansId || location.state.megaId;
    const [vitalSingsData, setVitalSingsData] = useState<CreateVitalSingsPayload>({
        isOpen: false,
        stgPrefix: "surgery",
        appId: data.appId,
        visRegistrationDate: "",
        vitalSigns: [],
        userId,
    });
    const [selectedOxygenType, setSelectedOxygenType] = useState();
    const [anesthesiaNotes, setAnesthesiaNotes] = useState<AnesthesiaNotes>();

    const [generalNotesModal, setGeneralNotesModal] = useState<{ isOpen: boolean; current: string; type: string }>({
        isOpen: false,
        current: "",
        type: "",
    });
    const [selectedAnestesiologist, setSelectedAnestesiologist] = useState({
        label: "",
        value: "",
    });
    const [anesthesiaTypes, setAnesthesiaTypes] = useState([
        {
            label: "Seleccionar...",
            value: 0,
        },
    ]);
    const [oxygenTypes, setOxygenTypes] = useState([
        {
            label: "Seleccionar...",
            value: 0,
        },
    ]);

    const [selectedAnesthesiaType, setSelectedAnesthesiaType] = useState(
        anesthesiaTypes?.find((option) => option?.label === data?.anesthesia)?.value
    );
    const [dataRecord, setDataRecord] = useState<RecordChart>({
        reload: 0,
        appId: location.state.data.appId,
        endOfSurgery: "",
        startOfAnesthesia: "",
        startOfSurgery: "",
        vitalSigns: {
            fc: [],
            o2: [],
            pad: [],
            pas: [],
        },
    });

    const [sectionsChanged, setSectionsChanged] = useState<SectionsChanged>({
        ansId,
        ansGeneralAnesthesiaNotes: anesthesiaNotes?.ansGeneralAnesthesiaNotes || "",
        ansDischargeAnesthesiaNotes: anesthesiaNotes?.ansDischargeAnesthesiaNotes || "",
        anesthesiaId: userId as number,
        aneId: anesthesiaNotes?.aneId || anesthesiaTypes?.find((option) => option?.label === data?.anesthesia)?.value,
        oxiId: anesthesiaNotes?.oxiId,
        createdBy: userId ? userId : NaN,
    });

    useEffect(() => {
        const newValue = anesthesiaTypes?.find((option) => option?.label === data?.anesthesia)?.value;
        setSectionsChanged({ ...sectionsChanged, aneId: newValue });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anesthesiaTypes]);

    useEffect(() => {
        const newValueGeneral = anesthesiaNotes?.ansGeneralAnesthesiaNotes;
        const newValueDischarge = anesthesiaNotes?.ansDischargeAnesthesiaNotes;
        const newValueOxi = anesthesiaNotes?.oxiId;
        setSectionsChanged({
            ...sectionsChanged,
            ansGeneralAnesthesiaNotes: newValueGeneral,
            ansDischargeAnesthesiaNotes: newValueDischarge,
            oxiId: newValueOxi,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anesthesiaNotes]);

    async function fetchData() {
        const data = await dispatch(getCurrentAnestesiologist());
        const name = data.firstName;
        const firstSurname = data.firstSurname;
        const fullName = `${name} ${firstSurname}`;
        setSelectedAnestesiologist({ label: fullName, value: "1" });
    }
    async function fetchAneType() {
        const response = await dispatch(getAneType());
        const options = response.results.map((type) => ({
            label: type.aneName,
            value: type.aneId,
        }));
        setAnesthesiaTypes(options);
    }
    async function fetchOxygenType() {
        const response = await dispatch(getOxygenTypes());
        const options = response.results.map((type) => ({
            label: type.oxiName,
            value: type.oxiId,
        }));
        setOxygenTypes(options);
    }
    async function getAnesthesiologyNotes() {
        const response = await dispatch(getAnesthesiaNotes({ ansId }));
        setAnesthesiaNotes(response);
    }

    useEffect(() => {
        fetchData();
        fetchAneType();
        fetchOxygenType();
        getAnesthesiologyNotes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpenNoInsTextModal = (name: string, type: string): void =>
        setGeneralNotesModal({
            current: name,
            isOpen: true,
            type: type,
        });

    const handleCloseNoInsTextModal = (): void =>
        setGeneralNotesModal({
            current: "",
            isOpen: false,
            type: "",
        });

    const handleAttatchText = (text: string) => {
        if (generalNotesModal && anesthesiaNotes) {
            if (generalNotesModal.type === "general") {
                const payload = {
                    ...anesthesiaNotes,
                    ansGeneralAnesthesiaNotes: anesthesiaNotes?.ansGeneralAnesthesiaNotes?.length
                        ? `${anesthesiaNotes.ansGeneralAnesthesiaNotes}\n${text}`
                        : text,
                };
                setAnesthesiaNotes(payload);
            } else if (generalNotesModal.type === "discharge") {
                const payload = {
                    ...anesthesiaNotes,
                    ansDischargeAnesthesiaNotes: anesthesiaNotes?.ansDischargeAnesthesiaNotes?.length
                        ? `${anesthesiaNotes.ansDischargeAnesthesiaNotes}\n${text}`
                        : text,
                };
                setAnesthesiaNotes(payload);
            }
        }
    };

    useEffect(() => {
        if (generalNotesModal && anesthesiaNotes) {
            if (generalNotesModal.type === "general") {
                handleChangeSectionsFunction(anesthesiaNotes.ansGeneralAnesthesiaNotes, "ansGeneralAnesthesiaNotes");
                handleCloseNoInsTextModal();
            } else if (generalNotesModal.type === "discharge") {
                handleChangeSectionsFunction(anesthesiaNotes.ansDischargeAnesthesiaNotes ?? "", "ansDischargeAnesthesiaNotes");
                handleCloseNoInsTextModal();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anesthesiaNotes]);

    const handleChangeText = (value: string, type: string) => {
        if (anesthesiaNotes) {
            if (type === "general") {
                setAnesthesiaNotes({
                    ...anesthesiaNotes,
                    ...sectionsChanged,
                    ansGeneralAnesthesiaNotes: value ?? "",
                });
                handleChangeSectionsFunction(value, "ansGeneralAnesthesiaNotes");
            } else if (type === "discharge") {
                setAnesthesiaNotes({
                    ...anesthesiaNotes,
                    ...sectionsChanged,
                    ansDischargeAnesthesiaNotes: value ?? "",
                });
                handleChangeSectionsFunction(value ?? "", "ansDischargeAnesthesiaNotes");
            }
        }
    };

    const handleChangeSectionsFunction = (value: string | number, name: string) => {
        if (sectionsChanged) {
            const payload = {
                ...sectionsChanged,
                [name]: value,
            };
            setSectionsChanged(payload);
            handleChangeSections(payload);
        }
    };
    const handleChangeSections = useDebouncedCallback((payload: SectionsChanged) => {
        if (sectionsChanged) {
            dispatch(updateSections(payload));
        }
    }, 800);

    const formatTimeToAmPm = (time: string): string => {
        if (time) {
            return dayjs("1/1/1 " + time).format("hh:mm a");
        } else return "-";
    };

    function calcTime(time1: string, time2: string) {
        const difSeconds = Date.parse(time1) - Date.parse(time2);

        const hours = Math.floor(difSeconds / (3600 * 1000));
        const min = Math.round((difSeconds % (3600 * 1000)) / (60 * 1000));

        const formato = `${hours ? hours + "h " : ""} ${min}m`;
        return formato;
    }

    const handleCloseVitalSings = () => {
        setVitalSingsData((data) => ({ ...data, isOpen: false }));
        setDataRecord((state) => ({ ...state, reload: state.reload + 1 })); 
    };

    const handleSubmitVitalSings = async (info: VitalSign[]) => {
        const fullData = {
            stgPrefix: "surgery",
            appId:  data.appId,
            userId,
            vitalSigns: info,
            visRegistrationDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        };
        const res = await dispatch(createVitalSingsSurgery(fullData));
        if (res.success) {
            handleCloseVitalSings();
        }
    };

    const render = () => {
        const renderAnesthesiaInformation = () => {
            return (
                <>
                    <SectionCard className="mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                            <h5 className="fw-bold text-secondary">Información de anestesia</h5>
                        </div>
                        <div className="row">
                            <div className="col-1 text-muted align-self-center pr-0">Anestesiólogo</div>
                            <div className="col-3 pl-0 align-items-left">
                                <Select
                                    variant="plain"
                                    readOnly
                                    value={selectedAnestesiologist}
                                />
                            </div>
                            <div
                                style={{ whiteSpace: "nowrap" }}
                                className="col-1 text-muted align-self-center pr-1"
                            >
                                Tipo de anestesia
                            </div>
                            <div className="col-3 px-4 align-items-left">
                                <Select
                                    variant="plain"
                                    readOnly={isDisabledFields}
                                    options={anesthesiaTypes}
                                    value={
                                        selectedAnesthesiaType === undefined
                                            ? anesthesiaTypes.find((option) => option.label === data?.anesthesia)
                                            : anesthesiaTypes.find((option) => option.value === selectedAnesthesiaType)
                                    }
                                    onChange={(selectedOption) => {
                                        setSelectedAnesthesiaType(selectedOption.option.value);
                                        handleChangeSectionsFunction(selectedOption.option.value, "aneId");
                                    }}
                                />
                            </div>
                            <div className="col-1 text-muted align-self-center pr-0">Oxígeno</div>
                            <div className="col-3 pl-0 align-items-left">
                                <Select
                                    variant="plain"
                                    options={oxygenTypes}
                                    readOnly={isDisabledFields}
                                    value={
                                        anesthesiaNotes?.oxiId
                                            ? oxygenTypes?.find((option) => option?.value === anesthesiaNotes?.oxiId)
                                            : oxygenTypes.find((option) => option.value === selectedOxygenType)
                                    }
                                    onChange={(selectedOption) => {
                                        setSelectedOxygenType(selectedOption.option.value);
                                        handleChangeSectionsFunction(selectedOption.option.value, "oxiId");
                                    }}
                                />
                            </div>
                        </div>
                    </SectionCard>
                </>
            );
        };

        const renderDrugsForInduction = () => {
            return (
                <SectionCard
                    className="mt-3"
                    cardAidStyle
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Drogas para la inducción</h5>
                    </div>
                    <hr className="lineCards" />
                    <div className="d-flex flex-column">
                        {anesthesiaNotes?.drugs_for_induction != null && anesthesiaNotes.drugs_for_induction.length > 0 ? (
                            anesthesiaNotes.drugs_for_induction.map((drug) => (
                                <div
                                    key={drug}
                                    className="text-muted"
                                >
                                    {drug}
                                </div>
                            ))
                        ) : (
                            <div className="text-muted">No se han agregado insumos</div>
                        )}
                    </div>
                </SectionCard>
            );
        };
        const renderGeneralAnesthesiaNotes = () => {
            return (
                <>
                    <SectionCard
                        className="mt-3"
                        cardAidStyle
                    >
                        <div className="d-flex align-items-center justify-content-between">
                            <h5 className="fw-bold text-secondary required">Notas generales de anestesia</h5>
                        </div>
                        <hr className="lineCards" />
                        <div className="d-flex flex-column">
                            <textarea
                                className="form-control no-resize w-100"
                                style={{ height: 80 }}
                                onContextMenu={(event) => {
                                    event.preventDefault();
                                    handleOpenNoInsTextModal("generalAnesthesiaNotes", "general");
                                }}
                                readOnly={isDisabledFields}
                                onChange={({ target }) => handleChangeText(target.value, "general")}
                                value={anesthesiaNotes?.ansGeneralAnesthesiaNotes}
                            />
                        </div>
                    </SectionCard>
                    {!isDisabledFields && (
                        <TextConfigModal
                            title="Textos predefinidos no institucionales"
                            sheet="anesthesiaNotesSurgery"
                            isOpen={generalNotesModal.isOpen}
                            segment={generalNotesModal.current}
                            current={generalNotesModal.current as string}
                            onClose={handleCloseNoInsTextModal}
                            width={800}
                            positionModal="center"
                            onSelectItem={(_, item) => handleAttatchText(item)}
                        />
                    )}
                </>
            );
        };
        const renderLiquidsAndDrugs = () => {
            return (
                <>
                    <SectionCard
                        className="mt-3"
                        cardAidStyle
                    >
                        <div className="d-flex align-items-center justify-content-between">
                            <h5 className="fw-bold text-secondary">Líquidos y drogas - anestesia</h5>
                        </div>
                        <hr className="lineCards" />
                        <div className="d-flex flex-column">
                            {anesthesiaNotes?.liquids_and_drugs != null && anesthesiaNotes.liquids_and_drugs.length > 0 ? (
                                anesthesiaNotes.liquids_and_drugs.map((drug) => (
                                    <div
                                        key={drug}
                                        className="text-muted"
                                    >
                                        {drug}
                                    </div>
                                ))
                            ) : (
                                <div className="text-muted">No se han agregado insumos</div>
                            )}
                        </div>
                    </SectionCard>
                    {!isDisabledFields && (
                        <TextConfigModal
                            title="Textos predefinidos no institucionales"
                            sheet="anesthesiaNotesSurgery"
                            isOpen={generalNotesModal.isOpen}
                            segment={generalNotesModal.current}
                            current={generalNotesModal.current as string}
                            onClose={handleCloseNoInsTextModal}
                            width={800}
                            positionModal="center"
                            onSelectItem={(_, item) => handleAttatchText(item)}
                        />
                    )}
                </>
            );
        };

        const renderRecordChart = () => {
            return (
                <>
                    <VitalSignsModal
                        handleGenericFunction={handleSubmitVitalSings}
                        isOpen={vitalSingsData.isOpen}
                        onClose={handleCloseVitalSings}
                        setVitalSingsData={setVitalSingsData}
                        appId={data.appId}
                        dontClose={false}
                        defaultDataRecord={dataRecord?.vitalSigns}
                    />
                    <SectionCard
                        className="mt-3"
                        cardAidStyle
                    >
                        <div className="d-flex align-items-center justify-content-between">
                            <h5 className="fw-bold text-secondary">Record anestésico</h5>
                        </div>
                        <hr className="lineCards" />
                        <div className="row align-items-center">
                            <div className="col-lg-10">
                                <RecordChartComponent
                                    appId={dataRecord.appId || location.state.data.appId}
                                    setDataRecord={setDataRecord}
                                    reload={dataRecord.reload}
                                    heightProp={350}
                                    widthProp="100%"
                                />
                            </div>

                            <div className="d-flex flex-column px-4 pt-2 col-lg-2 align-self-center">
                                <div className="pb-2">
                                    <b className="text-secondary">Inicio anestesia:</b>{" "}
                                    <span className="text-muted"> {formatTimeToAmPm(dataRecord?.startOfAnesthesia?.split(" ")[1])}</span>
                                </div>
                                <div className="pb-2">
                                    <b className="text-secondary">Inicio cirugía:</b>{" "}
                                    <span className="text-muted"> {formatTimeToAmPm(dataRecord?.startOfSurgery?.split(" ")[1])}</span>
                                </div>
                                <div className="pb-2">
                                    <b className="text-secondary">Fin cirugía:</b>{" "}
                                    <span className="text-muted"> {formatTimeToAmPm(dataRecord?.endOfSurgery?.split(" ")[1])}</span>
                                </div>
                                <div className="pb-2">
                                    <b className="text-secondary">Duración cirugía:</b>{" "}
                                    <span className="text-muted">
                                        {" "}
                                        {dataRecord.endOfSurgery ? calcTime(dataRecord?.endOfSurgery, dataRecord?.startOfSurgery) : "-"}
                                    </span>
                                </div>
                                <div className="pb-2">
                                    <b className="text-secondary">PAS:</b>{" "}
                                    <span className="text-muted">
                                        {dataRecord?.vitalSigns?.pas?.length > 0
                                            ? dataRecord?.vitalSigns?.pas[dataRecord?.vitalSigns?.pas.length - 1]?.value
                                            : "0"}{" "}
                                        (mm/hg)
                                    </span>
                                </div>
                                <div className="pb-2">
                                    <b className="text-secondary">PAD:</b>{" "}
                                    <span className="text-muted">
                                        {" "}
                                        {dataRecord?.vitalSigns?.pad?.length > 0
                                            ? dataRecord?.vitalSigns?.pad[dataRecord?.vitalSigns?.pad.length - 1]?.value
                                            : "0"}{" "}
                                        (mm/hg)
                                    </span>
                                </div>
                                <div className="pb-2">
                                    <b className="text-secondary">FC:</b>{" "}
                                    <span className="text-muted">
                                        {" "}
                                        {dataRecord?.vitalSigns?.fc?.length > 0
                                            ? dataRecord?.vitalSigns?.fc[dataRecord?.vitalSigns?.fc.length - 1]?.value
                                            : "0"}{" "}
                                        (lpm)
                                    </span>
                                </div>
                                <div className="pb-2">
                                    <b className="text-secondary">O2:</b>{" "}
                                    <span className="text-muted">
                                        {" "}
                                        {dataRecord?.vitalSigns?.o2?.length > 0
                                            ? dataRecord?.vitalSigns?.o2[dataRecord?.vitalSigns?.o2.length - 1]?.value
                                            : "0"}{" "}
                                        (%)
                                    </span>
                                </div>

                                {!isDisabledFields && (
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            setVitalSingsData((state) => ({ ...state, isOpen: true }));
                                        }}
                                    >
                                        Registrar signos vitales
                                    </Button>
                                )}
                            </div>
                        </div>
                    </SectionCard>
                </>
            );
        };
        const renderAnesthesiaDischargeNotes = () => {
            return (
                <>
                    <SectionCard
                        className="mt-3"
                        cardAidStyle
                    >
                        <div className="d-flex align-items-center justify-content-between">
                            <h5 className="fw-bold text-secondary required">Notas de egreso anestesia</h5>
                        </div>
                        <hr className="lineCards" />
                        <div className="d-flex flex-column">
                            <textarea
                                className="form-control no-resize w-100"
                                style={{ height: 80 }}
                                onContextMenu={(event) => {
                                    event.preventDefault();
                                    handleOpenNoInsTextModal("anesthesiaDischargeNotes", "discharge");
                                }}
                                readOnly={isDisabledFields}
                                onChange={({ target }) => handleChangeText(target.value, "discharge")}
                                value={anesthesiaNotes?.ansDischargeAnesthesiaNotes}
                            />
                        </div>
                    </SectionCard>
                </>
            );
        };

        return (
            <>
                <div> {renderAnesthesiaInformation()}</div>
                <div className="row">
                    <div className="col-6"> {renderDrugsForInduction()} </div>
                    <div className="col-6"> {renderGeneralAnesthesiaNotes()} </div>
                </div>
                <div className="row">
                    <div className="col-6"> {renderLiquidsAndDrugs()} </div>
                    <div className="col-6"> {renderAnesthesiaDischargeNotes()} </div>
                </div>
                <div> {renderRecordChart()}</div>
            </>
        );
    };

    return (
        <TemplateBodyDoctor
            sheet="anesthesiaNotes"
            sectionsChanged={{ ...sectionsChanged, createdBy: ansId }}
        >
            <div>{render()}</div>
        </TemplateBodyDoctor>
    );
};

export default Anesthesiologist;
