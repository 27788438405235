interface UserData {
    id: number | null;
    first_name?: string;
    first_surname?: string;
    second_surname?: string;
    image?: string;
}

interface Account {
    id: number;
    profile?: number;
}

interface Category {
    id: number;
    url: string;
    name: string;
    title?: string;
}

interface LoginReducer {
    Authorization: string | null;
    user_data: UserData;
    currentAccount: Account;
    all_categories?: Category[];
}

interface GetExternalUrlParams {
    loginReducer: LoginReducer;
    category: Category;
}

export const getExternalUrl = ({ loginReducer, category }: GetExternalUrlParams): string => {
    const newModules = ["Tesorería"];

    const { Authorization, user_data, currentAccount } = loginReducer;

    const fullName = `${user_data?.first_name || ""} ${user_data?.first_surname || ""} ${user_data?.second_surname || ""}`;
    const currentCategory = loginReducer?.all_categories?.find((cat) => cat?.name === (category?.title || category?.name));

    const idModule = `idModule=${currentCategory?.id ?? ""}`;
    const nameModule = `nameModule=${currentCategory?.name ?? ""}`;
    const token = `token=${Authorization}`;
    const idAccount = `idAccount=${currentAccount?.id ?? ""}`;
    const idUser = `idUser=${user_data?.id ?? ""}`;
    const idProfile = `idProfile=${currentAccount?.profile ?? ""}`;
    const userImage = `userImage=${user_data?.image ?? ""}`;
    const fullNameUser = `fullNameUser=${encodeURIComponent(fullName)}`;
    const url = category?.url ?? "";
    const urlBase = `${url}${url.endsWith("/") ? "" : "/"}${newModules.includes(currentCategory?.name ?? "") ? "" : "validate"}`;

    return `${urlBase}?${idModule}&${nameModule}&${idUser}&${idAccount}&${idProfile}&${userImage}&${fullNameUser}&${token}&`;
};
