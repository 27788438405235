import { SectionCard } from "@/components";
import { finishLoading, startLoading } from "@/config/slices/ui";
import { useAppDispatch } from "@/hooks";
import { Surgery as ISurgery, Patient } from "@/models/surgery/patientAttention/headerSurgeryAttention";
import { AdmissionNotes, ITabs, IncomeNotes, PreparationNotes, RecoveryNotes, SurgeryNotes } from "@/models/surgery/patientAttention/patientAttention";
import { fireCautionAlert } from "@/utils";
import { IconCircleCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import ConsultationControls from "../../Consultation/Controls/Controls";
import History from "../../Consultation/History/History";
import "../PatientAttentionSurgery.scss";
import Auth from "../components/Auth";
import SurgeryHeader from "../components/HeaderSurgeryComponeny";
import { getNurseryNotes, updateNurseryNotes } from "../patientAttentionSurgery.action";
import Admission from "./Admission";
import Income from "./Income";
import Preparation from "./Preparation";
import Recovery from "./Recovery";
import Surgery from "./Surgery";


const AttentionSurgery = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const numId = location.state.startAttentionInfo.numId || location.state.megaId;
    const lastStatus = location.state.startAttentionInfo.lastStatus ?? "";
    const portalAllowed = location.state.portalAllowed ?? "";
    const isDisabledFields = portalAllowed !== "nursery";
    const [authValidation, setAuthValidation] = useState<{ isOpen: boolean; prefix: string; }>({
        isOpen: false,
        prefix: ""
    });
    const [services, setServices] = useState<{ services: ISurgery[], patientInfo: Patient; status: string; clhId: number | null; }>(
        {
            services: [],
            patientInfo: { age: "", document: "", documentType: "", fullName: "", id: 0, photo: "" },
            clhId: null,
            status: ""
        });

    const [tempPayload, setTempPayload] = useState<{ section: string, payload: AdmissionNotes | IncomeNotes | PreparationNotes | SurgeryNotes | RecoveryNotes; }>({
        section: "",
        payload: {
            numId: 0,
            numSurgeryAreaAdmissionNotes: "",
            numCheckOrgan: 0,
            editedBy: "",
        }
    });

    const [tempJsonData, setTempJsonData] = useState< AdmissionNotes | IncomeNotes | PreparationNotes | SurgeryNotes | RecoveryNotes>({
        numId: 0,
        numSurgeryAreaAdmissionNotes: "",
        numCheckOrgan: 0,
        editedBy: "",
    });
    const [tempJsonDataModified, setTempJsonDataModified] = useState<AdmissionNotes | IncomeNotes | PreparationNotes | SurgeryNotes | RecoveryNotes>({
        numId: 0,
        numSurgeryAreaAdmissionNotes: "",
        numCheckOrgan: 0,
        editedBy: "",
    });

    const [reloadDataNotes, setReloadDataNotes] = useState(0);
    function mapState(state: string): string {
        const stateMap: { [key: string]: string; } = {
            "preparation": "preparation",
            "in_preparation": "preparation",
            "in_surgery": "surgery",
            "surgery": "surgery",
            "in_recovery": "recovery",
            "recovery": "recovery",
            "admitted": "admission",
        };
        return stateMap[state] || state;
    }

    function validatingJson(originalJson: AdmissionNotes | IncomeNotes | PreparationNotes | SurgeryNotes | RecoveryNotes,
        newJson: AdmissionNotes | IncomeNotes | PreparationNotes | SurgeryNotes | RecoveryNotes,
        keys: string[]): boolean {

        if (!originalJson || !newJson) {
            return false;
        }

        for (const key of keys) { 
            if (originalJson[key] !== newJson[key] && newJson[key] !== "") { 
                return true;
            }
        }

        return false;
    }


    const validatingStagesDataChanged = () => {
        if (tabs.find(tab => tab.isActive)?.value === "income") {
            return validatingJson(tempJsonData, tempJsonDataModified, ["numSurgeryAreaAdmissionNotes"]); 
        } else if (tabs.find(tab => tab.isActive)?.value === "preparation") {
            return validatingJson(tempJsonData, tempJsonDataModified, ["numAsepsisAntisepsis"]); 
        } else if (tabs.find(tab => tab.isActive)?.value === "surgery") {
            return validatingJson(tempJsonData, tempJsonDataModified, ["numReviewsUsedMedicalDevices", "numSurgeryObservation"]); 
        } else if (tabs.find(tab => tab.isActive)?.value === "recovery") {
            return validatingJson(tempJsonData, tempJsonDataModified, ["numRecoveryNotes"]); 
        }else if (tabs.find(tab => tab.isActive)?.value === "admission") {
            return validatingJson(tempJsonData, tempJsonDataModified, ["vitalSings", "admissionNotes", "checklist"]);
        }
        return false;
    };

    const onSubmitNotes = useDebouncedCallback((section: string, payload: AdmissionNotes | IncomeNotes | PreparationNotes | SurgeryNotes | RecoveryNotes) => {
        if (tabs.find(tab => tab.isActive)?.value === mapState(services.status)) {
            dispatch(updateNurseryNotes({
                [section]: payload
            }));
            setTempJsonDataModified(payload);
        } else {
            setTempPayload({ section, payload });
            setTempJsonDataModified(payload);
        }
    }, 1000);


    const onSubmitNotesSaveButton = async () => {
        const response = await dispatch(updateNurseryNotes({
            [tempPayload.section]: tempPayload.payload
        }));
        validateChangingTab(tabs.find(tab => tab.isActive)?.value ?? "");

        return response.success;
    };

    const [tabs, setTabs] = useState<ITabs[]>([
        { label: "Admisión", value: "admission", isDone: false, isActive: false, render: <Admission onSubmitNotes={onSubmitNotes} readOnly={isDisabledFields} /> },
        { label: "Ingreso", value: "income", isDone: false, isActive: false, render: <Income onSubmitNotes={onSubmitNotes} readOnly={isDisabledFields} /> },
        { label: "Preparación", value: "preparation", isDone: false, isActive: false, render: <Preparation onSubmitNotes={onSubmitNotes} readOnly={isDisabledFields} /> },
        { label: "Cirugía", value: "surgery", isDone: false, isActive: false, render: <Surgery onSubmitNotes={onSubmitNotes} statusSurgery={mapState(services.status)} appId={location.state.data.appId} numId={numId} readOnly={isDisabledFields} /> },
        { label: "Recuperación y egreso", value: "recovery", isDone: false, isActive: false, render: <Recovery onSubmitNotes={onSubmitNotes} readOnly={isDisabledFields} /> },
    ]);


    const renderBlocksTabs = (tabA: ITabs[]) => {

        if (mapState(services.status) === "admission") {
            const condition = (label: string) => (label !== "admission" );
            const statusBlocked = tabA.map(tab => condition(tab.value) ? { ...tab, isDone: false } : { ...tab, isDone: true });
            return statusBlocked;
        } else if (mapState(services.status) === "income") {
            const condition = (label: string) => (label !== "admission" && label !== "income");
            const statusBlocked = tabA.map(tab => condition(tab.value) ? { ...tab, isDone: false } : { ...tab, isDone: true });
            return statusBlocked;
        } else if (mapState(services.status) === "preparation") {
            const condition = (label: string) => (label !== "admission" && label !== "income" && label !== "preparation");
            const statusBlocked = tabA.map(tab => condition(tab.value) ? { ...tab, isDone: false } : { ...tab, isDone: true });
            return statusBlocked;
        } else if (mapState(services.status) === "surgery") {
            const condition = (label: string) => (label !== "admission" && label !== "income" && label !== "preparation" && label !== "surgery");
            const statusBlocked = tabA.map(tab => condition(tab.value) ? { ...tab, isDone: false } : { ...tab, isDone: true });
            return statusBlocked;
        } else {
            const statusBlocked = tabA.map(tab => ({ ...tab, isDone: true }));
            return statusBlocked;

        }
    };


    const handleChangeTab = (stage: string, tabsUpdate?: ITabs[]): void => {
        if (tabsUpdate) {
            const statusTabChanged = renderBlocksTabs(tabsUpdate).map(tab => tab.value === stage ? { ...tab, isActive: true } : { ...tab, isActive: false });
            setTabs(statusTabChanged);
        } else {
            if (validatingStagesDataChanged() && renderBlocksTabs(tabs).find(tab => tab.isActive)?.value !== mapState(services.status)) {
                fireCautionAlert("¿Está seguro?, si continúa perderá los cambios.", "Cambios sin guardar", () => {
                    const statusTabChanged = tabs.map(tab => tab.value === stage ? { ...tab, isActive: true } : { ...tab, isActive: false });
                    setTabs(statusTabChanged);
                    validateChangingTab(stage);
                });
            } else {
                const statusTabChanged = renderBlocksTabs(tabs).map(tab => tab.value === stage ? { ...tab, isActive: true } : { ...tab, isActive: false });
                setTabs(statusTabChanged);
                validateChangingTab(stage);
            }
        }
    };

    const renderSurgeries = () => {

        return (
            <div className='row'>
                {services?.services.length > 0 ? services?.services.map((service, ind) => {
                    return (
                        <div className="col">
                            <SectionCard className="w-100 py-2 px-3" cardAidStyle key={ind}>
                                <div>
                                    <div className="text-primary fw-bold">{service.name}</div>
                                    <div className="text-secondary">{service.surgeon}</div>
                                </div>
                            </SectionCard>
                        </div>
                    );
                }) : null}
            </div>
        );

    };

    const renderStages = () => {
        return (
            <div className="attentionSurgeryControls-tabs mt-3">
                {tabs.map(tab => (
                    <div
                        key={tab.value}
                        style={{ opacity: tab.isDone ? 1 : 0.5 }}
                        className={`attentionSurgeryControls-tab ${tab.isActive && "active"}`}
                        onClick={() => tab.isDone ? handleChangeTab(tab.value) : null}
                    >
                        {(tab.isDone && tab.value !== mapState(services.status)) && <IconCircleCheck />}
                        <span>{tab.label}</span>
                    </div>
                ))}
            </div>

        );
    };

    const handleCloseAuthValidation = () => {
        setAuthValidation((state) => ({ ...state, isOpen: false }));
    };

    useEffect(() => {
        const activeTab = tabs.find(tab => tab.isActive);
        if (activeTab?.value) {
            validateChangingTab(activeTab?.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadDataNotes]);

    useEffect(() => {
        if (lastStatus !== "") {
            validateChangingTab(lastStatus);
        } else {
            validateChangingTab(services.status);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [services.status]);


    const validateChangingTab = async (status: string) => {
        try {
            dispatch(startLoading());
            
            if (status === "income") {
                await getNurseryNotesFunction("income");

            } else if (status === "in_preparation" || status === "preparation") {
                await getNurseryNotesFunction("preparation");

            } else if (status === "in_surgery" || status === "surgery") {
                await getNurseryNotesFunction("surgery");

            } else if (status === "in_recovery" || status === "recovery") {
                await getNurseryNotesFunction("recovery");
            } else {
                await getNurseryNotesFunction("admission");
            }
        }
        finally {
            dispatch(finishLoading());
        }
    };

    const getNurseryNotesFunction = async (stage: string) => {
        async function fetchData() {
            const data = await dispatch(getNurseryNotes({ tab: stage, numId }));
            if (stage === "admission") {
                const statusTabChanged = tabs.map(tab => tab.value === stage ? {
                    ...tab,
                    render:
                        <Admission
                            onSubmitNotes={onSubmitNotes} 
                            data={data.admission}
                            readOnly={isDisabledFields} 
                        />
                }
                    : tab);
                setTabs(statusTabChanged);
                if (data.admission) {
                    setTempPayload({ section: "admission", payload: data.admission });
                    setTempJsonDataModified(data.admission);
                    setTempJsonData(data.admission);
                }
                handleChangeTab("admission", statusTabChanged);
            } else if (stage === "income") {
                const statusTabChanged = tabs.map(tab => tab.value === stage ? {
                    ...tab,
                    render:
                        <Income
                            data={data.income}
                            onSubmitNotes={onSubmitNotes}
                            readOnly={isDisabledFields}
                        />
                }
                    : tab);
                setTabs(statusTabChanged);
                if (data.income) {
                    setTempPayload({ section: "income", payload: data.income });
                    setTempJsonDataModified(data.income);
                    setTempJsonData(data.income);
                }
                handleChangeTab("income", statusTabChanged);
            } else if (stage === "preparation") {
                const statusTabChanged = tabs.map(tab => tab.value === stage ? {
                    ...tab,
                    render:
                        <Preparation
                            data={data.preparation}
                            onSubmitNotes={onSubmitNotes}
                            readOnly={isDisabledFields}
                        />
                }
                    : tab);
                setTabs(statusTabChanged);
                if (data.preparation) {
                    setTempPayload({ section: "preparation", payload: data.preparation });
                    setTempJsonDataModified(data.preparation);
                    setTempJsonData(data.preparation);
                }
                handleChangeTab("preparation", statusTabChanged);
            } else if (stage === "surgery") {
                const statusTabChanged = tabs.map(tab => tab.value === stage ? {
                    ...tab,
                    render:
                        <Surgery
                            data={data.surgery}
                            statusSurgery={mapState(services.status)}
                            onSubmitNotes={onSubmitNotes}
                            appId={location.state.data.appId}
                            numId={numId}
                            readOnly={isDisabledFields}
                        />
                } : tab);
                setTabs(statusTabChanged);
                if (data.surgery) {
                    setTempPayload({ section: "surgery", payload: data.surgery });
                    setTempJsonDataModified(data.surgery);
                    setTempJsonData(data.surgery);
                }
                handleChangeTab("surgery", statusTabChanged);
            } else if (stage === "recovery") {
                const statusTabChanged = tabs.map(tab => tab.value === stage ? {
                    ...tab,
                    render:
                        <Recovery
                            data={data.recovery}
                            onSubmitNotes={onSubmitNotes}
                            readOnly={isDisabledFields}
                        />
                }
                    : tab);
                setTabs(statusTabChanged);
                if (data.recovery) {
                    setTempPayload({ section: "recovery", payload: data.recovery });
                    setTempJsonDataModified(data.recovery);
                    setTempJsonData(data.recovery);
                }
                handleChangeTab("recovery", statusTabChanged);
            }
        }
        await fetchData();
    };

    return (
        <div className="d-flex flex-column w-100 h-100 surgeryBody">
            {
                location.state.data.appId &&
                <SurgeryHeader
                    appId={location.state.data.appId}
                    setServices={setServices}
                    mapState={mapState}
                    setAuthValidation={setAuthValidation}
                    stage={tabs.find(tab => tab.isActive)}
                    dataChanged={validatingStagesDataChanged}
                    numId={numId}
                    dataSurgery={location.state}
                    portalAllowed={portalAllowed}
                />
            }
            <div className="d-flex h-100 overflow-hidden">
                <div className="d-flex flex-column w-100 h-100 overflow-hidden">
                    <div className="px-4 pt-3">
                        {renderSurgeries()}
                    </div>
                    <div className="attentionSurgeryControls mx-4 pb-2">
                        {renderStages()}
                    </div>
                    <div className="bodyHeight h-100">
                        {tabs.find(tab => tab.isActive)?.render}
                    </div>
                    <ConsultationControls
                        stage={tabs.find(tab => tab.isActive)}
                        setAuthValidation={setAuthValidation}
                        setReloadDataNotes={setReloadDataNotes}
                        cluId={services.patientInfo.id}
                        clhIdProp={services.clhId}
                        realStatusSurgery={services.status}
                        tab={"surgery"} appId={location.state.data.appId} statusSurgery={mapState(services.status)} />
                </div>
                <History showClose={false} patientIdProp={location.state.data.patient.id}
                    patientAge={services.patientInfo.age}
                    patientName={services.patientInfo.fullName}
                    patientIdentification={services.patientInfo.document}
                    defaultHistory="ophthalmology" allData
                    appId={location.state.data.appId}

                />
                <Auth
                    isOpen={authValidation.isOpen}
                    onClose={handleCloseAuthValidation}
                    authValidation={authValidation}
                    stage={tabs.find(tab => tab.isActive)}
                    appId={location.state.data.appId}
                    handleGenericFunction={onSubmitNotesSaveButton}
                    isStageTrue={tabs.find(tab => tab.isActive)?.value === mapState(services.status)}
                />
            </div>

        </div>
    );
};

export default AttentionSurgery; 