import { CategoryResponse } from "@/models/sidebar";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const storedUserData = localStorage.getItem("category");
const userData = storedUserData ? JSON.parse(storedUserData).all_Categories : [];
const userDataNewCat = storedUserData ? JSON.parse(storedUserData).new_category : [];

const initialState = {
    category: {
        all_categories: userData,
        new_category: userDataNewCat,
    },
};

export const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        setCategorySlice: (state, action: PayloadAction<CategoryResponse>) => {
            state.category.all_categories = action.payload.all_Categories || [];
            state.category.new_category = action.payload.new_category || [];
        },
    },
});

export const { setCategorySlice } = categorySlice.actions;
