import { Fragment, useEffect, useState } from "react";

import { useAppDispatch } from "@/hooks";
import { getOrderDetail } from "../../../../history.actions";

import { OrderDetail } from "@/models/historyDetails/orders";

export default function OrdersDetail({ appId, data, download }: { appId?: number; data?: OrderDetail; download?: boolean; }) {

    const dispatch = useAppDispatch();

    const [orderDetail, setOrderDetail] = useState<OrderDetail | null>((download && data) ? data : null);

    useEffect(() => {
        void (async function () {
            if (data) {
                setOrderDetail(data);
            } else {
                const response = await dispatch(getOrderDetail({ prefix: "orders", appId: appId }));
                if (response) {
                    setOrderDetail(response);
                }
            }
        })();
    }, [appId, data, dispatch]);

    const renderComponent = () => {
        return (
            <div className={`${data ? "px-0" : "px-5"} w-100 py-4 h-100 overflow-auto`}>
                <h4 className="fw-bold text-secondary mb-3">Ordenamiento</h4>
                <h5><span className="fw-bold text-muted">Diagnóstico: </span>{orderDetail?.mainDiagnostic}</h5>

                {!!orderDetail?.services?.length && orderDetail?.services.map(serv => (
                    <div className="my-4 w-75">
                        <h5 className="text-primary fw-bold">Orden de servicio #{serv.id}</h5>
                        <table className="table table-bordered">
                            <thead>
                                <tr className="text-primary">
                                    <th>Órgano</th>
                                    <th>Cups</th>
                                    <th>Servicio</th>
                                    <th>Programación</th>
                                </tr>
                            </thead>
                            <tbody>
                                {serv.services.map((item, index) => (
                                    <tr key={`${item.cups}-${index}`}>
                                        <td>{item.organ}</td>
                                        <td>{item.cups}</td>
                                        <td>{item.service}</td>
                                        <td>{item.scheduling}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="text-muted"><span className="fw-bold">Observación general: </span>{serv.generals_observations ?? "-"}</div>
                    </div>
                ))}

                {!!orderDetail?.presurgicalExams?.length && (
                    <div className="my-4 w-75">
                        <h5 className="text-primary fw-bold">Exámenes prequirúrgicos</h5>
                        <hr className="mb-1" />
                        {orderDetail?.presurgicalExams.map((ord, index) => (
                            <div
                                key={index}
                                className="text-muted py-2 fw-bold"
                                style={{ borderBottom: "1px solid #ccc" }}
                            >
                                {ord}
                            </div>
                        ))}
                    </div>
                )}

                {!!orderDetail?.medication?.length && (
                    <div className="my-4">
                        <h5 className="text-primary fw-bold">Orden de medicamentos</h5>
                        {orderDetail?.medication.map(item => (
                            <div className="mb-4 w-75">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="text-primary">
                                            <th>Medicamento</th>
                                            <th>Indicaciones</th>
                                            <th>Vía</th>
                                            <th>Órgano</th>
                                            <th>Volumen</th>
                                            <th>Cantidad</th>
                                            <th>Observaciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item.medicines.map((med, index) => (
                                            <tr key={index}>
                                                <td>{med.medicine}</td>
                                                <td>{med.indications}</td>
                                                <td>{med.via}</td>
                                                <td>{med.organ}</td>
                                                <td>{med.volume}</td>
                                                <td>{med.quantity}</td>
                                                <td>{med.observations}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="text-muted"><span className="fw-bold">Observación general: </span>{item.generals_observations ?? "-"}</div>
                            </div>
                        ))}
                    </div>
                )}

                {!!orderDetail?.references?.length && (
                    <div className="my-4">
                        <h5 className="text-primary fw-bold">Referencia y contrarreferencia</h5>
                        {orderDetail?.references.map(item => (
                            <div className="mb-4 w-75">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="text-primary">
                                            <th>Referencia {item.id}</th>
                                            <th>Motivo</th>
                                            <th>Expectativa</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item.references.map((ref, index) => (
                                            <Fragment key={index}>
                                                <tr>
                                                    <td>{ref.refType}</td>
                                                    <td>{ref.cause}</td>
                                                    <td colSpan={2}>{ref.expectation}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} className="text-primary fw-bold">Contrarreferencia</td>
                                                </tr>
                                                <tr>
                                                    <td>{ref.counterreferences.referenceToFulllname}</td>
                                                    <td>{ref.counterreferences.position}</td>
                                                    <td>{ref.counterreferences.cellphone}</td>
                                                    <td>{ref.counterreferences.time}</td>
                                                </tr>
                                            </Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </div>
                )}

                {!!orderDetail?.assist?.length && orderDetail?.assist.map(item => (
                    <div className="my-4 w-75">
                        <h5 className="text-primary fw-bold">Certificado de asistencia #{item.id}</h5>
                        <div>{item.today}</div>
                        <div className="mt-1"><span className="fw-bold">Observaciones: </span>{item.observations ?? "-"}</div>
                    </div>
                ))}

                {!!orderDetail?.inhabilities?.length && orderDetail?.inhabilities.map(inh => (
                    <div className="my-4 w-75">
                        <h5 className="text-primary fw-bold">Incapacidad médica #{inh.id}</h5>
                        <div>Incapacidad por {inh.medicalInhabilities.days} días. Desde {inh.medicalInhabilities.startDate} hasta {inh.medicalInhabilities.endDate}</div>
                        <div className="mt-1"><span className="fw-bold">Observaciones: </span>{inh.observations ?? "-"}</div>
                    </div>
                ))}
            </div>
        );
    };

    return renderComponent();
}