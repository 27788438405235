import { IconX } from "@tabler/icons-react";
//
import { IBadgeTextField } from "./badgeTextField";
import "./BadgeTextField.scss";
import { IconAttach } from "@/assets/icons";

export function BadgeTextField({
    className,
    bookmarks,
    withDetails,
    onClick,
    onDeleteItem,
    onContextMenu,
    rows,
    style,
    value,
    onChangeJustification,
    isErasable,
    centered,
    disabled,
    placeholder,
    fileType,
    sameAction,
    classNameDetails,
}: IBadgeTextField) {

    return (
        <div className={`textfield-modal ${className}`}>
            <div
                className={`${disabled ? "disabled" : "bg-white"} textfield-modal__input  align-items-${centered ? "center" : "start"} ${withDetails && "no-border-bottom"} pointer`}
                style={style}
                onClick={onClick}
            >
                {bookmarks?.length === 0 &&
                    <span className="text-black-50">{placeholder ?? ""}</span>
                }
                {bookmarks?.length ? bookmarks.map((item, index) => (
                    <div
                        key={index}
                        className="textfield-badge"
                    >
                        <span className="pointer">{item.label}</span>
                        {(isErasable) && (
                            <IconX
                                className="text-primary ms-2 pointer"
                                size={12}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onDeleteItem?.(item.value);
                                }}
                            />
                        )}
                    </div>
                )) : null}
                {fileType &&
                    <span className="ms-auto">
                        <IconAttach />
                    </span>
                }
            </div>
            {withDetails ? (
                <textarea
                    onClick={(e) => {
                        if (sameAction && onClick) {
                            onClick(e);
                        }
                    }}
                    rows={rows}
                    className={`${classNameDetails} form-control textfield-modal__textarea no-border-top no-resize`}
                    onContextMenu={onContextMenu}
                    value={value}
                    readOnly={disabled}
                    onChange={({ target }) => onChangeJustification?.(target.value)}
                />
            ) : null}
        </div>
    );
}