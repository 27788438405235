import { Segments } from "@/models/generalFields";
import {
    ExtDiagnostic
} from "@/models/historyDetails/lowVision";
import { Keratometry, LowVisionPayload, NasopupillaryDistance, ObjectiveRefraction, PupillaryDistance, SubjectiveRefraction, VisualAcuity } from "@/models/sheets/lowVision";
import { formatHistoryDetails } from "@/utils";
import "../Details.scss";

export default function LowVisionDetails({ lowVisionDetails, isCustom }: { lowVisionDetails: LowVisionPayload; isCustom?: boolean; }) {

    const renderAttentionOrigin = (attentionOrigin: Segments | null) => {

        const motiveIss = attentionOrigin?.predefinedTxts ? attentionOrigin?.predefinedTxts?.find(t=> t.prefix === "current_disease") : {predifinedText: []};
        const motiveReason = attentionOrigin?.predefinedTxts ? attentionOrigin?.predefinedTxts?.find(t=> t.prefix === "reason_attention") : { predifinedText: []};


        if (attentionOrigin) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Origen de atención
                    </h4>
                    {attentionOrigin?.generalFields?.attentionOrigin !== null ? (
                        <h5>
                            <span className="fw-bold">Origen de la atención: </span>{" "}
                            {attentionOrigin?.generalFields?.attentionOrigin?.name}
                        </h5>
                    ) : ""}
                    {attentionOrigin.generalFields.abserveEvent !== null ? (
                        <h5>
                            <span className="fw-bold">Evento adverso: </span>{" "}
                            {attentionOrigin.generalFields.abserveEvent === 1 ? "SÍ" : "NO"}
                        </h5>
                    ) : ""}

                    <>
                        <h5 className="fw-bold mt-3">
                                Motivo consulta:
                        </h5> 
                        <br/>
                        <h5 style={{ whiteSpace: "pre-line" }}>{motiveReason?.predifinedText.map(x=> x.name).join(", ")}</h5>
                            
                        <br/>
                        <h5 style={{ whiteSpace: "pre-line" }}>{attentionOrigin.reasonForConsultingJst}</h5>
                    </>
                    <>
                        <h5 className="fw-bold mt-3">
                                Enfermedad actual:
                        </h5>
                        <br/>

                        <h5 style={{ whiteSpace: "pre-line" }}>{motiveIss?.predifinedText.map(x=> x.name).join(", ")}</h5>
                        <br/>
                        <h5 style={{ whiteSpace: "pre-line" }}>{attentionOrigin.currentDiseaseJst || ""}</h5>
                    </>
                    {attentionOrigin.generalFields.evolutionTime ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>{" "}
                            {attentionOrigin.generalFields.evolutionTime} - {attentionOrigin.generalFields.evolutionTimeUnit?.name}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };
    const renderVisualAcuity = (visualAcuity: VisualAcuity | null) => {
        if (visualAcuity) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Agudeza visual
                    </h4>
                    <h5>
                        {visualAcuity.optotype ? (
                            <>
                                <span className="fw-bold">Optotipo: </span>{" "}
                                {visualAcuity.optotype}
                            </>
                        ) : ""}
                        {visualAcuity.optotype && visualAcuity.clasification ? " - " : ""}
                        {visualAcuity.clasification ? (
                            <>
                                <span className="fw-bold">Clasificación: </span>{" "}
                                {visualAcuity.clasification}
                            </>
                        ) : ""}
                    </h5>
                    {visualAcuity.noCorrection ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                AV sin corrección
                            </h5>
                            <h5>
                                {visualAcuity.noCorrection.od ? (
                                    <>
                                        <span className="fw-bold">AV OD </span>{" "}
                                        <span className="">Visión Lejana: {visualAcuity?.noCorrection?.od?.far?.value} </span>{" "}
                                        <span className="">Visión Cercana: {visualAcuity?.noCorrection?.od?.near?.value} </span>{" "}
                                    </>
                                ) : ""}
                                <br />
                                {visualAcuity.noCorrection.oi ? (
                                    <>
                                        <span className="fw-bold">AV OI </span>{" "}
                                        <span className="">Visión Lejana: {visualAcuity?.noCorrection?.oi?.far?.value} </span>{" "}
                                        <span className="">Visión Cercana: {visualAcuity?.noCorrection?.oi?.near?.value} </span>{" "}
                                    </>
                                ) : ""}
                                <br />

                                {visualAcuity.noCorrection.ao ? (
                                    <>
                                        <span className="fw-bold">AV AO: </span>{" "}
                                        <span className="">Visión Lejana: {visualAcuity?.noCorrection?.ao?.far?.value} </span>{" "}
                                        <span className="">Visión Cercana: {visualAcuity?.noCorrection?.ao?.near?.value} </span>{" "}
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}

                    {visualAcuity.ph ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Pinhole (PH)
                            </h5>
                            <h5>
                                {visualAcuity.ph.od ? (
                                    <>
                                        <span className="fw-bold">AV OD: </span>{" "}
                                        {visualAcuity.ph?.od}
                                    </>
                                ) : ""}
                                {visualAcuity.ph.od && visualAcuity.ph.od ? " / " : ""}
                                {visualAcuity.ph.oi ? (
                                    <>
                                        <span className="fw-bold">AV OI: </span>{" "}
                                        {visualAcuity.ph?.oi}
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}

                    {visualAcuity.lentsInUse ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Lentes en uso
                            </h5>
                            <h5>
                                {visualAcuity.lentsInUse.lentsOd ? (
                                    <>
                                        <br />

                                        <span className="fw-bold">Lente OD </span>{" "}
                                        <br />

                                        <div className="d-flex flex-column">
                                            <span className="fw-bold">Esfera OD: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.lentsOd.sphere}</span>
                                        </div>
                                        <div>
                                            <span className="fw-bold">Cilindro OD: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.lentsOd.cylinder}</span>
                                        </div>
                                        <div>
                                            <span className="fw-bold">Eje OD: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.lentsOd.axis}</span>
                                        </div>
                                        <div>
                                            <span className="fw-bold">ADD OD: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.lentsOd.add}</span>
                                        </div>
                                        <div>
                                            <span className="fw-bold">Tipo de lente OD: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.lentsOd.len}</span>
                                        </div>
                                        <div>
                                            <span className="fw-bold">Marca OD: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.lentsOd.brand}</span>
                                        </div>

                                    </>
                                ) : ""}
                                {visualAcuity.lentsInUse.lentsOi ? (
                                    <>
                                        <br />

                                        <span className="fw-bold">Lente OI </span>{" "}
                                        <br />
                                        <div>
                                            <span className="fw-bold">Esfera OD: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.lentsOi.sphere}</span>
                                        </div>
                                        <div>
                                            <span className="fw-bold">Cilindro OD: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.lentsOi.cylinder}</span>
                                        </div>
                                        <div>
                                            <span className="fw-bold">Eje OD: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.lentsOi.axis}</span>
                                        </div>
                                        <div>
                                            <span className="fw-bold">ADD OD: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.lentsOi.add}</span>
                                        </div>
                                        <div>
                                            <span className="fw-bold">Tipo de lente OD: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.lentsOi.len}</span>
                                        </div>
                                        <div>
                                            <span className="fw-bold">Marca OD: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.lentsOi.brand}</span>
                                        </div>                                    </>
                                ) : ""}
                            </h5>
                            <h5>
                                {visualAcuity.lentsInUse.od ? (
                                    <>
                                        <br />

                                        <span className="fw-bold">AV OD </span>{" "}
                                        <br />

                                        <div>
                                            <span className="fw-bold">Visión Lejana OD: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.od.far}</span>
                                        </div>
                                        <div>
                                            <span className="fw-bold">Visión Cercana OD: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.od.near}</span>
                                        </div>
                                    </>
                                ) : ""}
                                {visualAcuity.lentsInUse.od && visualAcuity.lentsInUse.oi ? " / " : ""}
                                {visualAcuity.lentsInUse.oi ? (
                                    <>
                                        <br />
                                        <span className="fw-bold">AV OI </span>{" "}
                                        <br />

                                        <div>
                                            <span className="fw-bold">Visión Lejana OI: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.oi.far}</span>
                                        </div>
                                        <div>
                                            <span className="fw-bold">Visión Cercana OI: </span>{" "}
                                            <span>{visualAcuity.lentsInUse?.oi.near}</span>
                                        </div>                                        </>
                                ) : ""}
                            </h5>
                            {visualAcuity.lentsInUse.observations ? (
                                <h5>
                                    <span className="fw-bold">Observaciones: </span>{visualAcuity.lentsInUse.observations}
                                </h5>
                            ) : ""}
                        </>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderRefraction = (objective: ObjectiveRefraction | null) => {
        if (objective) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Autorefracto sin cicloplejía
                    </h4>
                    {objective ? (
                        <>
                            <h5 className="text-muted fw-bold">Autorefracto sin cicloplejía</h5>
                            <h5 className="text-muted"> <b>Cicloplejia:</b> {objective.cycloplegia === 1 ? "Sí" : "No"} </h5>
                            <h5 className="text-muted">
                                {objective?.oi ? (
                                    <>
                                        <span className="fw-bold">Esfera OI: </span>{" "}
                                        <span>{objective.oi.sphere}</span>
                                    </>
                                ) : ""}
                                <br />

                                {objective?.od ? (
                                    <>
                                        <span className="fw-bold">Esfera OD: </span>{" "}
                                        <span>{objective.od.sphere}</span>
                                    </>
                                ) : ""}
                                <br />

                                {objective?.oi ? (
                                    <>
                                        <span className="fw-bold">Cilindro OI: </span>{" "}
                                        <span>{objective.oi.cylinder}</span>
                                    </>
                                ) : ""}
                                <br />

                                {objective?.od ? (
                                    <>
                                        <span className="fw-bold">Cilindro OD: </span>{" "}
                                        <span>{objective.od.cylinder}</span>
                                    </>
                                ) : ""}
                                <br />

                                {objective?.oi ? (
                                    <>
                                        <span className="fw-bold">Eje OI: </span>{" "}
                                        <span>{objective.oi.axis}</span>
                                    </>
                                ) : ""}
                                <br />

                                {objective?.od ? (
                                    <>
                                        <span className="fw-bold">Eje OD: </span>{" "}
                                        <span>{objective.od.axis}</span>
                                    </>
                                ) : ""}
                                <br />

                                {objective?.oi ? (
                                    <>
                                        <span className="fw-bold">ADD OI: </span>{" "}
                                        <span>{objective.oi.add}</span>
                                    </>
                                ) : ""}
                                <br />

                                {objective?.od ? (
                                    <>
                                        <span className="fw-bold">ADD OD: </span>{" "}
                                        <span>{objective.od.add}</span>
                                    </>
                                ) : ""}
                                <br />

                            </h5>
                            {objective.observations ? (
                                <h5 className="text-muted mb-3">
                                    <span className="fw-bold">Observaciones: </span>{" "}
                                    {objective?.observations}
                                </h5>
                            ) : ""}
                        </>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderKeratometry = (keratometry: Keratometry | null) => {
        if (keratometry) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Queratometría
                    </h4>
                    {keratometry.od ? (
                        <>
                            <h5 className=" fw-bold">
                                Queratometría OD
                            </h5>
                            <h5>
                                {keratometry.od.k1 ? (
                                    <>
                                        <div>
                                            <span className="fw-bold">Poder K1 : </span>{" "}
                                            {keratometry.od?.k1.value}D
                                        </div>
                                        <div>
                                            <span className="fw-bold">Grados K1: </span>{" "}
                                            {keratometry.od?.k1.grade}
                                        </div>
                                    </>
                                ) : ""}
                                {keratometry.od.k2 ? (
                                    <>
                                        <div>
                                            <span className="fw-bold">Poder K2 : </span>{" "}
                                            {keratometry.od?.k2.value}D
                                        </div>
                                        <div>
                                            <span className="fw-bold">Grados k2: </span>{" "}
                                            {keratometry.od?.k2.grade}
                                        </div>
                                    </>
                                ) : ""}

                                {keratometry.od ? (
                                    <>
                                        <span className="fw-bold">Astigmatismo: </span>{" "}
                                        {(Number(keratometry.od.k2?.value) - Number(keratometry.od.k1?.value))}D
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}

                    {keratometry.oi ? (
                        <>
                            <br />

                            <h5 className=" fw-bold">
                                Queratometría OI
                            </h5>
                            <h5>
                                {keratometry.oi.k1 ? (
                                    <>
                                        <div>
                                            <span className="fw-bold">Poder K1 : </span>{" "}
                                            {keratometry.oi?.k1.value}D
                                        </div>
                                        <div>
                                            <span className="fw-bold">Grados K1: </span>{" "}
                                            {keratometry.oi?.k1.grade}
                                        </div>
                                    </>
                                ) : ""}
                                {keratometry.oi.k2 ? (
                                    <>
                                        <div>
                                            <span className="fw-bold">Poder K2 : </span>{" "}
                                            {keratometry.oi?.k2.value}D
                                        </div>
                                        <div>
                                            <span className="fw-bold">Grados k2: </span>{" "}
                                            {keratometry.oi?.k2.grade}
                                        </div>
                                    </>
                                ) : ""}

                                {keratometry.oi ? (
                                    <>
                                        <span className="fw-bold">Astigmatismo: </span>{" "}
                                        {(Number(keratometry.oi.k2?.value) - Number(keratometry.oi.k1?.value))}D
                                    </>
                                ) : ""}
                               
                            </h5>
                        </>
                    ) : ""}

                    {keratometry.observations ? (
                        <>
                            <span className="fw-bold">Observaciones: </span>{" "}
                            {keratometry.observations}
                        </>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderSubjectiveRefraction = (subjective: SubjectiveRefraction | null) => {
        if (subjective) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Refracción subjetiva sin cicloplejía
                    </h4>

                    {subjective ? (
                        <>
                            <h5 className="text-muted fw-bold">Refracción subjetiva sin cicloplejía</h5>
                            <h5 className="text-muted"> <b>Cicloplejia:</b> {subjective.cycloplegia === 1 ? "Sí" : "No"} </h5>
                            <h5>
                                {subjective?.oi ? (
                                    <>
                                        <span className="fw-bold">Esfera OI: </span>{" "}
                                        <span>{subjective.oi.sphere}</span>
                                    </>
                                ) : ""}
                                <br />

                                {subjective?.od ? (
                                    <>
                                        <span className="fw-bold">Esfera OD: </span>{" "}
                                        <span>{subjective.od.sphere}</span>
                                    </>
                                ) : ""}
                                <br />

                                {subjective?.oi ? (
                                    <>
                                        <span className="fw-bold">Cilindro OI: </span>{" "}
                                        <span>{subjective.oi.cylinder}</span>
                                    </>
                                ) : ""}
                                <br />

                                {subjective?.od ? (
                                    <>
                                        <span className="fw-bold">Cilindro OD: </span>{" "}
                                        <span>{subjective.od.cylinder}</span>
                                    </>
                                ) : ""}
                                <br />

                                {subjective?.oi?.axis ? (
                                    <>
                                        <span className="fw-bold">Eje OI: </span>{" "}
                                        <span>{subjective.oi.axis}</span>
                                    </>
                                ) : ""}
                                <br />

                                {subjective?.od?.axis ? (
                                    <>
                                        <span className="fw-bold">Eje OD: </span>{" "}
                                        <span>{subjective.od.axis}</span>
                                    </>
                                ) : ""}
                                <br />

                                {subjective?.oi?.add ? (
                                    <>
                                        <span className="fw-bold">ADD OI: </span>{" "}
                                        <span>{subjective.oi.add}</span>
                                    </>
                                ) : ""}
                                <br />

                                {subjective?.od?.add ? (
                                    <>
                                        <span className="fw-bold">ADD OD: </span>{" "}
                                        <span>{subjective.od.add}</span>
                                    </>
                                ) : ""}
                                <br />

                            </h5>
                            {subjective.observations ? (
                                <h5 className="text-muted">
                                    <span className="fw-bold">Observaciones: </span>{" "}
                                    {subjective?.observations}
                                </h5>
                            ) : ""}
                        </>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };


    const renderAvWithCorrection = (visualAcuity: VisualAcuity | null) => {
        if (visualAcuity) {
            return (
                <div className="item">
                    {visualAcuity.correction ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                AV con corrección
                            </h5>
                            <h5>
                                {visualAcuity.correction.od ? (
                                    <>
                                        <span className="fw-bold">AV OD: </span>{" "}
                                        <span className="">Visión Lejana: {visualAcuity?.correction?.od?.far?.value} </span>{" "}
                                        <span className="">Visión Cercana: {visualAcuity?.correction?.od?.near?.value} </span>{" "}
                                    </>
                                ) : ""}                                 <br />

                                {visualAcuity.correction.oi ? (
                                    <>
                                        <span className="fw-bold">AV OI: </span>{" "}
                                        <span className="">Visión Lejana: {visualAcuity?.correction?.oi?.far?.value} </span>{" "}
                                        <span className="">Visión Cercana: {visualAcuity?.correction?.oi?.near?.value} </span>{" "}                                       </>
                                ) : ""}                                 <br />

                                {visualAcuity.correction.ao ? (
                                    <>
                                        <span className="fw-bold">AV AO: </span>{" "}
                                        <span className="">Visión Lejana: {visualAcuity?.correction?.ao?.far?.value} </span>{" "}
                                        <span className="">Visión Cercana: {visualAcuity?.correction?.ao?.near?.value} </span>{" "}                                       </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderPupillaryDistance = (pupillaryDistance: PupillaryDistance | null) => {
        if (pupillaryDistance) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Distancia pupilar
                    </h4>
                    {pupillaryDistance ? (
                        <>
                            <h5 className="text-muted fw-bold">
                                Distancia pupilar
                            </h5>
                            <h5 className="text-muted">
                                {pupillaryDistance.vl ? (
                                    <>
                                        <span className="fw-bold">Visión Lejana: </span>{" "}
                                        {pupillaryDistance.vl}
                                    </>
                                ) : ""}
                                {pupillaryDistance.vl && pupillaryDistance.vp ? " / " : ""}
                                {pupillaryDistance.vp ? (
                                    <>
                                        <span className="fw-bold">Visión Cercana: </span>{" "}
                                        {pupillaryDistance.vp}
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderNasoPupillaryDistance = (nasopupillaryDistance: NasopupillaryDistance | null) => {
        if (nasopupillaryDistance) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Distancia nasopupilar
                    </h4>

                    {nasopupillaryDistance ? (
                        <>
                            <h5 className="text-muted fw-bold">
                                Distancia nasopupilar
                            </h5>
                            <h5 className="text-muted">
                                {nasopupillaryDistance.od ? (
                                    <>
                                        <span className="fw-bold">OD: </span>{" "}
                                        {nasopupillaryDistance.od}
                                    </>
                                ) : ""}
                                {nasopupillaryDistance.od && nasopupillaryDistance.oi ? " / " : ""}
                                {nasopupillaryDistance.oi ? (
                                    <>
                                        <span className="fw-bold">OI: </span>{" "}
                                        {nasopupillaryDistance.oi}
                                    </>
                                ) : ""}
                            </h5>
                        </>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderTiflo = (tiflo: string | null) => {
        if (tiflo) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Ayudas VSN - Tiflo en uso
                    </h4>
                    <h5 className="text-muted">
                        <span className="fw-bold">Observaciones: </span>
                        <span>{tiflo}</span>
                    </h5>
                </div>
            );
        } else {
            return "";
        }
    };

    const renderExtDiagnostic = (extDiagnostic: ExtDiagnostic | null) => {
        if (extDiagnostic && Object.values(extDiagnostic).length) {
            const mainExt = Object.values(extDiagnostic).find(item => item.isMain === 1);

            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Impresión diagnóstica
                    </h4>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="text-secondary">
                                <th>Fecha</th>
                                <th className="text-center">CIE-10</th>
                                <th>Impresión diagnóstica</th>
                                <th className="text-center">Ojo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(extDiagnostic).map((item) => (
                                <tr key={`${item.id}-${item.clhId}`} style={{ backgroundColor: item.isMain ? "#fcfae6" : undefined }}>
                                    <td className="align-middle">{item.date}</td>
                                    <td align="center" className="align-middle">{item.cie10Code}</td>
                                    <td style={{ whiteSpace: "pre-line" }}>{item.extDiagnosticId ? item.extDiagnosticDescription : item.cie10Description}</td>
                                    <td className="align-middle" align="center">{item.dbo?.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {mainExt && mainExt.evolution ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>
                            {mainExt.evolution ?? "-"} {mainExt.evolutionTime?.name ?? "-"}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderAnalysisAndPlan = (analysisPlan: string | null) => {
        if (analysisPlan) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Análisis y plan
                    </h4>
                    <h5 className="text-muted">{analysisPlan}</h5>
                </div>
            );
        } else {
            return "";
        }
    };

    const render = () => {
        const attentionOrigin = lowVisionDetails.generalsFields ? lowVisionDetails.generalsFields[0] : null;
        const visualAcuity = formatHistoryDetails(lowVisionDetails.visualAcuity);
        const objective = formatHistoryDetails(lowVisionDetails.objectiveRefraction);
        const subjective = formatHistoryDetails(lowVisionDetails.subjectiveRefraction);
        const keratometry = formatHistoryDetails(lowVisionDetails.keratometry);
        const pupillaryDistance = formatHistoryDetails(lowVisionDetails.pupillaryDistance);
        const nasopupillaryDistance = formatHistoryDetails(lowVisionDetails.nasopupillaryDistance);
        const tiflo = formatHistoryDetails(lowVisionDetails.supportsVsnTifloInUse);
        const extDiagnostic = formatHistoryDetails(lowVisionDetails.cie10ExtDiagnostic);
        const analysisPlan = formatHistoryDetails(lowVisionDetails.generalsFields ? lowVisionDetails.generalsFields[0].analysisPlanJst : "");

        return (
            <div className="detail-container">
                <div className="columns">
                    {!isCustom && renderAttentionOrigin(attentionOrigin)}
                    {renderVisualAcuity(visualAcuity)}
                    {renderRefraction(objective)}
                    {renderKeratometry(keratometry)}
                    {renderSubjectiveRefraction(subjective)}
                    {renderAvWithCorrection(visualAcuity)}
                    {renderPupillaryDistance(pupillaryDistance)}
                    {renderNasoPupillaryDistance(nasopupillaryDistance)}
                    {renderTiflo(tiflo)}
                    <div className="block-item">
                        {renderExtDiagnostic(extDiagnostic)}
                        {renderAnalysisAndPlan(analysisPlan)}
                    </div>
                </div>
            </div>
        );
    };

    return render();
}
