import {
    Cie10EXTDiagnostic,
    LaboratoryExams,
    PhysicalExam,
    SuitableForSurgery
} from "@/models/historyDetails/preoperative";
import { PreoperativePayload } from "@/models/sheets/preoperative";
import { formatHistoryDetails } from "@/utils";
import "../Details.scss";

export default function PreoperativeDetails({ preoperativeDetails }: { preoperativeDetails: PreoperativePayload; }) {

    const renderBackgroundMedical = (backgroundMedical: PreoperativePayload | null) => {
        if (backgroundMedical) {
            const prevSurgeries = backgroundMedical.previousSurgeries ?? {};

            const currentCondition = (
                prevSurgeries?.surgeries ||
                backgroundMedical?.actualCondition?.familyMemberProblemsAnesthesia ||
                backgroundMedical?.actualCondition?.smoker ||
                backgroundMedical?.actualCondition?.drinkAlcohol ||
                backgroundMedical?.general?.dentalProthesis ||
                backgroundMedical?.actualCondition?.contactLenses ||
                backgroundMedical?.actualCondition?.possiblePregnancy);

            const lungProblems = formatHistoryDetails(backgroundMedical?.lungProblems) || {};
            const digestiveProblems = formatHistoryDetails(backgroundMedical?.digestiveProblems) || {};
            const hematologicalProblems = formatHistoryDetails(backgroundMedical?.hematologicalProblems) || {};
            const musculoskeletalProblems = formatHistoryDetails(backgroundMedical?.musculoskeletalProblems) || {};
            const neurologicalProblems = formatHistoryDetails(backgroundMedical?.neurologicalProblems) || {};
            const metabolicProblems = formatHistoryDetails(backgroundMedical?.metabolicProblems) || {};
            const urinaryProblems = formatHistoryDetails(backgroundMedical?.urinaryProblems) || {};
            const heartProblems = formatHistoryDetails(backgroundMedical?.heartProblems) || {};

            const hasLungProblems = Object.values(lungProblems).some((val) => !!val);
            const hasDigestiveProblems = Object.values(digestiveProblems).some(val => !!val);
            const hasHematologicalProblems = Object.values(hematologicalProblems).some(val => !!val);
            const hasMusculoskeletalProblems = Object.values(musculoskeletalProblems).some(val => !!val);
            const hasNeurologicalProblems = Object.values(neurologicalProblems).some(val => !!val);
            const hasMetabolicProblems = Object.values(metabolicProblems).some(val => !!val);
            const hasUrinaryProblems = Object.values(urinaryProblems).some(val => !!val);
            const hasHeartProblems = Object.values(heartProblems).some(val => !!val);

            return (
                <>
                    <h4 className="text-primary fw-bold mb-2 mt-4">
                        Antecedentes médicos
                    </h4>
                    {currentCondition ? (
                        <div className="item py-1">
                            <h5>
                                <span className="fw-bold">Condición actual</span>
                            </h5>
                            <ul className="fs-5 mb-3">
                                {backgroundMedical?.actualCondition?.familyMemberProblemsAnesthesia ? (
                                    <li>Familiar con problemas de anestesia</li>
                                ) : ""}
                                {backgroundMedical.actualCondition?.smoker ? (
                                    <li>Fuma</li>
                                ) : ""}
                                {backgroundMedical?.actualCondition?.drinkAlcohol ? (
                                    <li>Toma alcohol</li>
                                ) : ""}
                                {backgroundMedical?.actualCondition?.contactLenses ? (
                                    <li>Usa lentes de contactos</li>
                                ) : ""}
                                {backgroundMedical?.actualCondition?.possiblePregnancy ? (
                                    <li>Posiblemente enbarazada</li>
                                ) : ""}

                            </ul>
                        </div>
                    ) : ""}

                    <div className="item py-1">
                        <h5 className="mb-2">
                            <span className="fw-bold">Historial de anestesias</span>
                        </h5>
                        {prevSurgeries.surgeries && prevSurgeries?.surgeries?.length > 0 ? (
                            <ul className="fs-5">
                                {prevSurgeries?.surgeries.map((item, index) => (
                                    <li key={index}>Servicio: {item.surgery} - Año: {item.year} - Anestesia: {item.anesthesia_description}</li>
                                ))}
                            </ul>
                        ) : ""}
                    </div>

                    <div className="item py-1">
                        <h5 className="mb-2">
                            <span className="fw-bold">Condición general</span>
                        </h5>
                        <ul className="fs-5">
                            {backgroundMedical?.general?.allergies?.check ? (
                                <li>Tiene alergias - {backgroundMedical?.general?.allergies?.observations}</li>
                            ) : ""}
                            {backgroundMedical?.general?.seriousIllness?.check ? (
                                <li>Ha padecido alguna enfermedad grave: {backgroundMedical.general?.seriousIllness.observations}</li>
                            ) : ""}
                            {backgroundMedical?.general?.aspirinLastWeek?.check ? (
                                <li>Toma aspirina </li>
                            ) : ""}
                            {backgroundMedical?.general?.dentalProthesis?.check ? (
                                <li>Tiene prótesis dental</li>
                            ) : ""}
                        </ul>
                    </div>

                    {hasHeartProblems ? (
                        <div className="item py-1">
                            <h5 className="mb-2">
                                <span className="fw-bold">Problemas cardíacos</span>
                            </h5>
                            <ul className="fs-5">
                                {heartProblems?.lowBloodPressure ? (
                                    <li>Presión baja</li>
                                ) : ""}
                                {heartProblems?.highBloodPressure ? (
                                    <li>Presión alta</li>
                                ) : ""}
                                {heartProblems?.myocardialInfarction ? (
                                    <li>Infarto del miocardio</li>
                                ) : ""}
                                {heartProblems?.murmurs ? (
                                    <li>Soplos cardiacos</li>
                                ) : ""}
                                {heartProblems?.chestPain ? (
                                    <li>Dolor de pecho</li>
                                ) : ""}
                                {heartProblems?.arrhythmias ? (
                                    <li>Ritmo cardiaco irregular (Arritmias)</li>
                                ) : ""}
                                {heartProblems?.ankleSwelling ? (
                                    <li>Hinchazón de tobillos</li>
                                ) : ""}
                                {heartProblems?.difficultyBreathing_night ? (
                                    <li>Dificultad para respirar</li>
                                ) : ""}
                            </ul>
                            {heartProblems?.other ? (
                                <h5>
                                    <span className="fw-bold">Otros: </span>
                                    {heartProblems?.otherText}

                                </h5>
                            ) : ""}
                            {heartProblems?.medicationsDoTake ? (
                                <h5>
                                    <span className="fw-bold">Comentarios: </span>
                                    {heartProblems?.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}

                    {hasLungProblems ? (
                        <div className="item py-1">
                            <h5 className="mb-2">
                                <span className="fw-bold">Problemas pulmonares</span>
                            </h5>
                            <ul className="fs-5">
                                {lungProblems?.asthma ? (
                                    <li>Asma</li>
                                ) : ""}
                                {lungProblems?.bronchitis ? (
                                    <li>Bronquitis</li>
                                ) : ""}
                                {lungProblems?.frequent_cough ? (
                                    <li>Tos frecuente</li>
                                ) : ""}
                            </ul>
                            {lungProblems?.other ? (
                                <h5>
                                    <span className="fw-bold">Otros: </span>
                                    {lungProblems?.otherText}
                                </h5>
                            ) : ""}

                        </div>
                    ) : ""}

                    {hasNeurologicalProblems ? (
                        <div className="item py-1">
                            <h5 className="mb-2">
                                <span className="fw-bold">Problemas neurológicos</span>
                            </h5>
                            <ul className="fs-5">
                                {neurologicalProblems?.epilepsies ? (
                                    <li>Epilepsias o convulsiones</li>
                                ) : ""}
                                {neurologicalProblems?.strongBlowsSkull ? (
                                    <li>Golpes fuertes en el craneo</li>
                                ) : ""}
                                {neurologicalProblems?.lossConsciousness ? (
                                    <li>Pérdida de conocimiento</li>
                                ) : ""}
                                {neurologicalProblems?.faintsEasily ? (
                                    <li>Se desmaya fácilmente</li>
                                ) : ""}
                            </ul>
                            {neurologicalProblems?.other ? (
                                <h5>
                                    <span className="fw-bold">Otros: </span>
                                    {neurologicalProblems?.otherText}
                                </h5>
                            ) : ""}
                            {neurologicalProblems?.medicationsDoTake ? (
                                <h5 className=" mb-3">
                                    <span className="fw-bold">Comentarios: </span>
                                    {neurologicalProblems?.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}

                    {hasDigestiveProblems ? (
                        <div className="item py-1">
                            <h5 className=" mb-2">
                                <span className="fw-bold">Problemas digestivo</span>
                            </h5>
                            <ul className=" fs-5">
                                {digestiveProblems?.ulcer ? (
                                    <li>Úlcera o gastritis</li>
                                ) : ""}
                                {digestiveProblems?.chronicDiarrhea ? (
                                    <li>Diarrea crónica</li>
                                ) : ""}
                                {digestiveProblems?.hepatitis ? (
                                    <li>hepatitis</li>
                                ) : ""}
                            </ul>
                            {digestiveProblems?.other ? (
                                <h5>
                                    <span className="fw-bold">Otros: </span>
                                    {digestiveProblems?.otherText}
                                </h5>
                            ) : ""}
                            {digestiveProblems?.medicationsDoTake ? (
                                <h5 className=" mb-3">
                                    <span className="fw-bold">Comentarios: </span>
                                    {digestiveProblems?.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}

                    {hasHematologicalProblems ? (
                        <div className="item py-1">
                            <h5 className=" mb-2">
                                <span className="fw-bold">Problemas hematológicos</span>
                            </h5>
                            <ul className=" fs-5">
                                {hematologicalProblems?.anemias ? (
                                    <li>Anemias</li>
                                ) : ""}
                                {hematologicalProblems?.excessiveBleeding ? (
                                    <li>Sangrado excesivo</li>
                                ) : ""}
                            </ul>
                            {hematologicalProblems?.other ? (
                                <h5>
                                    <span className="fw-bold">Otros: </span>
                                    {hematologicalProblems?.otherText}
                                </h5>
                            ) : ""}
                            {hematologicalProblems?.medicationsDoTake ? (
                                <h5 className=" mb-3">
                                    <span className="fw-bold">Comentarios: </span>
                                    {hematologicalProblems?.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}

                    {hasMusculoskeletalProblems ? (
                        <div className="item py-1">
                            <h5 className=" mb-2">
                                <span className="fw-bold">Problemas musculo-esqueléticos</span>
                            </h5>
                            <ul className=" fs-5">
                                {musculoskeletalProblems?.arthritis ? (
                                    <li>Artritis</li>
                                ) : ""}
                                {musculoskeletalProblems?.muscularWeakness ? (
                                    <li>Debilidad muscular</li>
                                ) : ""}
                                {musculoskeletalProblems?.frequentCramps ? (
                                    <li>Calambres o espasmos frecuentes</li>
                                ) : ""}
                            </ul>
                            {musculoskeletalProblems?.other ? (
                                <h5>
                                    <span className="fw-bold">Otros: </span>
                                    {musculoskeletalProblems?.otherText}
                                </h5>
                            ) : ""}
                            {musculoskeletalProblems?.medicationsDoTake ? (
                                <h5 className=" mb-3">
                                    <span className="fw-bold">Comentarios: </span>
                                    {musculoskeletalProblems?.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}

                    {hasUrinaryProblems ? (
                        <div className="item py-1">
                            <h5 className="mb-2">
                                <span className="fw-bold">Problemas urinarios</span>
                            </h5>
                            <ul className=" fs-5">
                                {urinaryProblems?.whichText ? (
                                    <li>{urinaryProblems?.whichText}</li>
                                ) : ""}
                            </ul>
                            {urinaryProblems?.medicationsDoTake ? (
                                <h5 className=" mb-3">
                                    <span className="fw-bold">Comentarios: </span>
                                    {urinaryProblems?.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}

                    {hasMetabolicProblems ? (
                        <div className="item py-1">
                            <h5 className=" mb-2">
                                <span className="fw-bold">Problemas metabólicos</span>
                            </h5>
                            <ul className=" fs-5">
                                {metabolicProblems?.diabetes ? (
                                    <li>Diabetes</li>
                                ) : ""}
                                {metabolicProblems?.thyroidDiseases ? (
                                    <li>Enfermedades de tiroides</li>
                                ) : ""}
                            </ul>
                            {metabolicProblems?.other ? (
                                <h5>
                                    <span className="fw-bold">Otros: </span>
                                    {metabolicProblems?.otherText}
                                </h5>
                            ) : ""}
                            {metabolicProblems?.medicationsDoTake ? (
                                <h5>
                                    <span className="fw-bold">Comentarios: </span>
                                    {metabolicProblems?.medicationsDoTake}
                                </h5>
                            ) : ""}
                        </div>
                    ) : ""}
                </>
            );
        } else return "";
    };

    const renderLaboratoryExams = (laboratoryExams: LaboratoryExams | null) => {
        if (laboratoryExams) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold">
                        Exámenes de laboratorio
                    </h4>
                    {laboratoryExams.hemoglobin ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Hemoglobina: </span>{" "}
                            {laboratoryExams.hemoglobin}
                        </h5>
                    ) : ""}
                    {laboratoryExams.hematocrit ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Hematocrito: </span>{" "}
                            {laboratoryExams.hematocrit}
                        </h5>
                    ) : ""}
                    {laboratoryExams.leukocytes ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Leucocitos: </span>{" "}
                            {laboratoryExams.leukocytes}
                        </h5>
                    ) : ""}
                    {laboratoryExams.platelets ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Plaquetas: </span>{" "}
                            {laboratoryExams.platelets}
                        </h5>
                    ) : ""}
                    {laboratoryExams.thromboplastin ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">T. Tromboplastina: </span>{" "}
                            {laboratoryExams.thromboplastin}
                        </h5>
                    ) : ""}
                    {laboratoryExams.prothrombin ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">T. Protombina: </span>{" "}
                            {laboratoryExams.prothrombin}
                        </h5>
                    ) : ""}
                    {laboratoryExams.glycemia ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Glicemia: </span>{" "}
                            {laboratoryExams.glycemia}
                        </h5>
                    ) : ""}
                    {laboratoryExams.creatinine ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Creatinina: </span>{" "}
                            {laboratoryExams.creatinine}
                        </h5>
                    ) : ""}
                    {laboratoryExams.tsh ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">TSH: </span>{" "}
                            {laboratoryExams.tsh}
                        </h5>
                    ) : ""}
                    {laboratoryExams.thyroid ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Tiroides: </span>{" "}
                            {laboratoryExams.thyroid}
                        </h5>
                    ) : ""}
                    {laboratoryExams.ekg ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">EKG: </span>{" "}
                            {laboratoryExams.ekg}
                        </h5>
                    ) : ""}
                    {laboratoryExams.other ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Otro: </span>{" "}
                            {laboratoryExams.other}
                        </h5>
                    ) : ""}
                    {laboratoryExams.value ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Valor: </span>{" "}
                            {laboratoryExams.value}
                        </h5>
                    ) : ""}
                </div>
            );
        }
    };

    const renderPhysicalExams = (physicalExams: PhysicalExam) => {
        if (physicalExams) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Exámenes físico
                    </h4>
                    {physicalExams.cardiovascular ? (
                        <h5 className=" ">
                            <span className="fw-bold">Cardiovascular: </span>{" "}
                            {physicalExams.cardiovascular}
                        </h5>
                    ) : ""}
                    {(physicalExams.cardiovascular && physicalExams.pulmonary) ? (
                        <hr className="my-3" />
                    ) : ""}
                    {physicalExams.pulmonary ? (
                        <h5 className=" ">
                            <span className="fw-bold">Pulmonar: </span>{" "}
                            {physicalExams.pulmonary}
                        </h5>
                    ) : ""}
                    {(physicalExams.pulmonary && physicalExams.extremities) ? (
                        <hr className="my-3" />
                    ) : ""}
                    {physicalExams.extremities ? (
                        <h5 className=" ">
                            <span className="fw-bold">Extremidades: </span>{" "}
                            {physicalExams.extremities}
                        </h5>
                    ) : ""}
                    {(physicalExams.extremities && physicalExams.centralNervousSystem) ? (
                        <hr className="my-3" />
                    ) : ""}
                    {physicalExams.centralNervousSystem ? (
                        <h5 className="">
                            <span className="fw-bold">Sistema nervioso central: </span>{" "}
                            {physicalExams.centralNervousSystem}
                        </h5>
                    ) : ""}
                    {(physicalExams.centralNervousSystem && physicalExams.other) ? (
                        <hr className="my-3" />
                    ) : ""}
                    {physicalExams.other ? (
                        <h5 className=" mb-3">
                            <span className="fw-bold">Otros: </span>{" "}
                            {physicalExams.other}
                        </h5>
                    ) : ""}
                </div>
            );
        }
    };

    const renderExtDiagnostic = (extDiagnostic: Cie10EXTDiagnostic | null) => {
        if (extDiagnostic && Object.values(extDiagnostic).length) {
            const mainExt = Object.values(extDiagnostic).find(item => item.isMain === 1);

            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Impresión diagnóstica
                    </h4>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="text-secondary">
                                <th>Fecha</th>
                                <th className="text-center">CIE-10</th>
                                <th>Impresión diagnóstica</th>
                                <th className="text-center">Ojo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(extDiagnostic).map((item) => (
                                <tr key={`${item.id}-${item.clhId}`} style={{ backgroundColor: item.isMain ? "#fcfae6" : undefined }}>
                                    <td className="align-middle">{item.date}</td>
                                    <td align="center" className="align-middle">{item.cie10Code}</td>
                                    <td style={{ whiteSpace: "pre-line" }}>{item.extDiagnosticId ? item.extDiagnosticDescription : item.cie10Description}</td>
                                    <td className="align-middle" align="center">{item.dbo?.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {mainExt && mainExt.evolution ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>
                            {mainExt.evolution} {mainExt.evolutionTime?.name}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderSuitableForSurgery = (suitableForSurgery: SuitableForSurgery | null) => {
        if (suitableForSurgery) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Decisión especialista
                    </h4>
                    {suitableForSurgery.anestheticRisk ? (
                        <h5 className=" mb-3">
                            <span className="fw-bold">Clasificación del riesgo anestésico: </span>{" "}
                            {suitableForSurgery.anestheticRisk}
                        </h5>
                    ) : ""}
                    {suitableForSurgery.suitable ? (
                        <h5 className=" mb-3">
                            <b>Paciente se encuentra apto para cirugía:</b>
                            <span className="">
                                {suitableForSurgery.suitable === 1 ? " SÍ" : "NO"}
                            </span>{" "}
                            <br />
                            {suitableForSurgery.justification || ""}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const render = () => {
        const backgroundMedical = preoperativeDetails;
        const extDiagnostic = formatHistoryDetails(preoperativeDetails.cie10ExtDiagnostic);
        const laboratoryExams = formatHistoryDetails(preoperativeDetails.laboratoryExams);
        const physicalExams = formatHistoryDetails(preoperativeDetails.physicalExam);
        const suitableForSurgery = formatHistoryDetails(preoperativeDetails.suitableForSurgery);

        return (
            <div className="detail-container">
                <div className="columns">
                    {renderBackgroundMedical(backgroundMedical)}
                    {renderLaboratoryExams(laboratoryExams)}
                    {renderPhysicalExams(physicalExams)}
                    {renderSuitableForSurgery(suitableForSurgery)}
                    <div className="block-item">
                        {renderExtDiagnostic(extDiagnostic)}
                    </div>
                </div>
            </div>
        );
    };

    return render();
}
