import { statusCodes } from "@/constants/http";
import { IResponse } from "@/models";
import { fireWarningAlert } from "@/utils";
import axios, { AxiosError } from "axios";

export const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_URL_PROTOCOL}${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_URL_GATEWAY}${process.env.REACT_APP_API_VERSION}`,
});

// let cancelRequest: (reason?: string) => void; // Función para cancelar la solicitud
axiosInstance.interceptors.request.use((config) => {
    // Cancela la solicitud anterior si existe
    // if (cancelRequest) {
    //     cancelRequest("Solicitud anterior cancelada");
    // }
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error: AxiosError<IResponse>) => {
        if (error.response?.status === statusCodes.UNAUTHORIZED) {
            if (error.response.data.action === "LOGOUT") {
                fireWarningAlert("Vuelva a iniciar sesión nuevamente para continuar", "Su sesión ha finalizado", () =>
                    window.location.reload()
                );
            } else {
                fireWarningAlert(error.response.data.message, "Intenta de nuevo");
            }
        } else {
            fireWarningAlert(error.response?.data.message as string, "Intenta de nuevo");
        }
        if (axios.isCancel(error)) {
            // La solicitud fue cancelada
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);
