const PdfViewer = ({ pdfUrl }: { pdfUrl: string; }) => {

    return (
        <div className="h-100">
            <iframe
                id="pdfIframe"
                src={`${pdfUrl}#toolbar=1&navpanes=0&embedded=true`}
                title="HC escaneada"
                style={{ width: "100%", height: "100%" }}
                className="pdfRender"
            ></iframe>
        </div>
    );
};

export default PdfViewer;
