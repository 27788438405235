import {
    IconCheckupList,
    IconChevronDown,
    IconChevronLeft,
    IconClipboardHeart,
    IconExclamationMark,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
//
import {
    Avatar, Badge,
    Button,
    Modal,
    Select,
} from "@/components";
import { setCurrentSheet, setRequestStatus } from "@/config/slices/surgery";
import routes from "@/constants/routes";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { Comment } from "@/models/comment";
import { HeaderSurgery, Patient, Surgery } from "@/models/surgery/patientAttention/headerSurgeryAttention";
import { AppointmentSurgery, ITabs, OperatingRooms, StartAttentionResponse } from "@/models/surgery/patientAttention/patientAttention";
import { fireCautionAlert, fireWarningAlert, formatteDocument } from "@/utils";
import { IconClipboardData, IconX } from "@tabler/icons-react";
import Comments from "../../Consultation/Comments/Comments";
import { createComment, deleteComment, getComments } from "../../Consultation/Comments/comments.actions";
import Antecedents from "../../Consultation/Header/Antecedents/AntedecentsModal";
import "../../Consultation/Header/Header.scss";
import GeneralConsumptionSheet from "../consumptionSheet/GeneralConsumptionSheet";
import { getHeaderSurgery, getSheetsIds } from "../patientAttentionSurgery.action";
import CheckList from "./CheckList";
import SelectRoomSurgery from "./SelectRoomSurgery";

interface DataInterface {
    show: boolean;
    appointment?: AppointmentSurgery;
    data?: OperatingRooms[];
}

export default function SurgeryHeader({
    dataSurgery,
    isDisabledForm,
    numId,
    appId,
    setServices,
    stage,
    mapState,
    setAuthValidation,
    dataChanged,
    portalAllowed
}: {
    isDisabledForm?: boolean;
    numId?: number;
    setAuthValidation?: (state: { isOpen: boolean; prefix: string }) => void;
    mapState?: (state: string) => string;
    appId?: number | null;
    setServices?: (state: {
        services: Surgery[];
        patientInfo: Patient;
        status: string;
        clhId: number | null;
        cluId: number | null;
    }) => void;
    stage?: ITabs;
    dataChanged?: () => boolean;
    dataSurgery?: {
        tab: string;
        data: AppointmentSurgery;
        startAttentionInfo: StartAttentionResponse;
        sheet: "nurseNotes" | "anesthesiologist" | "surgeon";
    };
    portalAllowed: string;
}) {
    dayjs.extend(relativeTime);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const workspace = useAppSelector((state) => state.workspace);
    const userId = useAppSelector((state) => state.auth.user_data.id);
    const eaccount = useAppSelector((state) => state.workspace.id);
    const rolUser = workspace.profile?.prefijo.toLowerCase();
    const [selectedSheet, setSelectedSheet] = useState("");

    const optionsSheets = [
        {
            label: "Notas de enfermería",
            value: routes.workspaceSurgeryAttention,
            prefix: "nurseNotes",
        },
        {
            label: "Anestesiólogo",
            value: routes.workspaceSurgeryAttentionAnesthesiologist,
            prefix: "anesthesiologist",
        },
        {
            label: "Descripción quirúrgica",
            value: routes.workspaceSurgeryAttentionSurgeon,
            prefix: "surgeon",
        },
    ];

    const [patientHeader, setPatientHeader] = useState<HeaderSurgery>();
    const [comments, setComments] = useState<Comment[]>([]);
    const [isCommentsDrawerOpen, setIsCommentsDrawerOpen] = useState<boolean>(false);
    const [consumptionSheetGeneralModal, setConsumptionSheetGeneralModal] = useState({
        isOpen: false,
    });
    const [patientInfoModal, setPatientInfoModal] = useState({
        isOpen: false,
    });
    const [antecedentsConfig, setAntecedentsConfig] = useState({
        isOpen: false,
    });
    const [selectRoomSurgeryModal, setSelectRoomSurgeryModal] = useState<DataInterface>({
        show: false,
        data: undefined,
    });

    const [checkListModal, setCheckListModal] = useState({
        isOpen: false,
    });

    useEffect(() => {
        if (dataSurgery && selectedSheet === "") {
            setSelectedSheet(dataSurgery.sheet);
            dispatch(setCurrentSheet(dataSurgery.sheet));
        }
    }, [dataSurgery]);

    useEffect(() => {
        async function fetchData() {
            if (patientHeader?.patient.id) {
                const data = await dispatch(
                    getComments({
                        entity: "userclient",
                        id: patientHeader?.patient.id,
                    })
                );
                setComments(data as Comment[]);
            }
        }
        fetchData();
    }, [dispatch, patientHeader?.patient.id]);

    useEffect(() => {
        async function fetchData() {
            if (appId) {
                const data = await dispatch(getHeaderSurgery(appId));
                if (data) {
                    setPatientHeader(data);
                    data.surgeries &&
                        setServices &&
                        setServices({
                            patientInfo: data.patient,
                            services: data.surgeries,
                            status: data.status.value,
                            clhId: data.clhId,
                            cluId: data.cluId,
                        });
                    dispatch(setRequestStatus(data?.requestStatus?.value));
                }
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appId, eaccount, dispatch]);

    const handleCloseCommentsDrawer = (): void => setIsCommentsDrawerOpen(false);
    const handleOpenCommentsDrawer = (): void => setIsCommentsDrawerOpen(true);

    const handleCloseAntecedentsModal = (): void =>
        setAntecedentsConfig({
            isOpen: false,
        });
    const handleOpenAntecedentsModal = (): void =>
        setAntecedentsConfig({
            isOpen: true,
        });

    const handleReloadHeader = async () => {
        if (appId && eaccount) {
            if (appId) {
                const data = await dispatch(getHeaderSurgery(appId));
                if (data) {
                    setPatientHeader(data);
                    dispatch(setRequestStatus(data?.requestStatus?.value));
                }
            }
        }
    };

    const handleSubmitComment = async (text: string): Promise<void> => {
        await dispatch(
            createComment({
                comment: text,
                entity: "userclient",
                userId: userId as number,
                id: patientHeader?.patient.id as number,
            })
        );
        const data = await dispatch(
            getComments({
                entity: "userclient",
                id: patientHeader?.patient.id,
            })
        );

        setComments(data as Comment[]);
    };

    const handleDeleteComment = async (id: string): Promise<void> => {
        await dispatch(deleteComment(id));
        const data = await dispatch(
            getComments({
                entity: "userclient",
                id: patientHeader?.patient.id,
            })
        );
        setComments(data as Comment[]);
    };

    const handleOpenPatientInfoModal = () => {
        setPatientInfoModal({ ...patientInfoModal, isOpen: true });
    };

    const handleClosePatientInfoModal = () => {
        setPatientInfoModal({ ...patientInfoModal, isOpen: false });
    };

    const renderModal = () => {
        return (
            <Modal
                onClose={handleClosePatientInfoModal}
                isOpen={patientInfoModal.isOpen}
                positionModal={"center"}
                width={700}
                className="textconfig-modal"
            >
                <div className="p-4">
                    <div className="d-flex justify-content-center">
                        <Avatar
                            size="extraLarge"
                            src={
                                patientHeader?.patient?.photo
                                    ? IMG_EXT_REGEX.test(patientHeader?.patient?.photo)
                                        ? patientHeader?.patient?.photo
                                        : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"
                                    : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"
                            }
                            onClick={handleOpenPatientInfoModal}
                        />
                    </div>
                    <div className="d-flex flex-column text-center p-2">
                        <b className="text-secondary fw-bold fs-2 ms-2">{patientHeader?.patient?.fullName}</b>
                        <span className="text-secondary fw-bold fs-6 ms-2">
                            {patientHeader?.patient?.documentType} {formatteDocument({ value: patientHeader?.patient?.document }).format} |
                            EPS {patientHeader?.cclientName}
                        </span>
                    </div>
                    <div className="d-flex px-4 py-2 f-4 text-muted">
                        <div className="col-6 px-4">
                            <div className="d-flex">
                                <b className="col-4">Edad</b>
                                <div className="col-8"> {patientHeader?.patient?.age} años</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Género</b>
                                <div className="col-8"> {patientHeader?.patient?.gender}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Teléfono</b>
                                <div className="col-8"> {patientHeader?.patient?.cellphone}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Email</b>
                                <div className="col-8"> {patientHeader?.patient.email}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Servicio</b>
                                <div className="col-8 d-flex flex-column">{patientHeader?.serviceMain} </div>
                            </div>
                        </div>
                        <div className="col-6 px-4">
                            <div className="d-flex">
                                <b className="col-4">Aseguradora</b>
                                <div className="col-8"> {patientHeader?.cclientName}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Contrato</b>
                                <div className="col-8"> {patientHeader?.contractName}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Médico</b>
                                <div className="col-8"> {patientHeader?.surgeonMain}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Fecha</b>
                                <div className="col-8"> {patientHeader?.appointmentDate}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Hora</b>
                                <div className="col-8"> {patientHeader?.appointmentTime}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button
                    variant="primary"
                    className="align-self-center mt-3"
                    onClick={() => {
                        handleClosePatientInfoModal();
                    }}
                >
                    Aceptar
                </Button>
            </Modal>
        );
    };

    const showModalSurgeryRoom = () => {
        setSelectRoomSurgeryModal((state) => ({ ...state, show: true }));
    };

    const handleCloseSelectRoomModal = () => {
        setSelectRoomSurgeryModal((state) => ({ ...state, show: false }));
        handleReloadHeader();
    };

    const handleOpenCheckListModal = () => {
        setCheckListModal((state) => ({ ...state, isOpen: true }));
    };
    const handleCloseCheckListModal = () => {
        setCheckListModal((state) => ({ ...state, isOpen: false }));
    };

    const IMG_EXT_REGEX = /\.(jpeg|jpg|png|gif)$/i;

    const renderToAnotherSheet = async (option: {
        label: string;
        value: string;
        prefix: "nurseNotes" | "anesthesiologist" | "surgeon";
    }) => {
        if (appId) {
            const goTo = (megaId: number | null) => {
                setSelectedSheet(option.prefix);
                dispatch(setCurrentSheet(option.prefix));
                navigate(option.value, {
                    state: {
                        tab: dataSurgery?.tab,
                        data: dataSurgery?.data,
                        startAttentionInfo: dataSurgery?.startAttentionInfo,
                        sheet: option.prefix,
                        numId: patientHeader?.numId,
                        megaId,
                        portalAllowed,
                    },
                });
            };

            const data = await dispatch(getSheetsIds(appId));
            const sheetIdMap = {
                nurseNotes: data.numId,
                anesthesiologist: data.ansId,
                surgeon: data.sunId,
            };

            if (sheetIdMap[option.prefix]) {
                goTo(sheetIdMap[option.prefix]);
            } else {
                fireWarningAlert("La hoja no tiene información", "Intenta de nuevo");
            }
        }
    };

    const render = () => {
        return (
            <>
                {renderModal()}
                <div className="consultation-header align-items-end">
                    <div
                        className="align-self-center text-secondary pointer"
                        onClick={() => {
                            if (mapState && setAuthValidation && dataChanged) {
                                if (stage?.value === mapState(patientHeader?.status?.value ?? "") && dataChanged()) {
                                    setAuthValidation({ isOpen: true, prefix: "back" });
                                } else if (dataChanged()) {
                                    fireCautionAlert("¿Está seguro?, si continúa perderá los cambios.", "Cambios sin guardar", () => {
                                        navigate(routes.workspaceSurgery);
                                    });
                                } else {
                                    navigate(routes.workspaceSurgery);
                                }
                            } else {
                                navigate(routes.workspaceSurgery);
                            }
                        }}
                    >
                        <IconChevronLeft />
                    </div>
                    <Avatar
                        src={
                            patientHeader?.patient?.photo
                                ? IMG_EXT_REGEX.test(patientHeader?.patient?.photo)
                                    ? patientHeader?.patient?.photo
                                    : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"
                                : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"
                        }
                        onClick={handleOpenPatientInfoModal}
                        className="pointer align-self-center"
                    />
                    <div className="d-flex align-items-center ms-3">
                        <div className="ms-3">
                            <small className="text-muted">Paciente</small>
                            <div className="fw-bold text-secondary">{patientHeader?.patient?.fullName}</div>
                        </div>
                    </div>
                    <div className="ms-4">
                        <small className="text-muted">Identificación</small>
                        <div className="fw-bold text-secondary">
                            {patientHeader?.patient?.documentType} {formatteDocument({ value: patientHeader?.patient?.document }).format}
                        </div>
                    </div>
                    <div className="ms-4">
                        <small className="text-muted">EPS</small>
                        <div className="fw-bold text-secondary">{patientHeader?.cclientName}</div>
                    </div>
                    <div className="ms-4">
                        <small className="text-muted">Contrato</small>
                        <div className="fw-bold text-secondary">{patientHeader?.contractName}</div>
                    </div>
                    <div className="ms-4">
                        <small className="text-muted">Edad</small>
                        <div className="fw-bold text-secondary">{patientHeader?.patient?.age} años</div>
                    </div>
                    <Badge
                        className="mx-2 align-self-center"
                        style={{
                            backgroundColor: patientHeader?.status.bgColor || "#E8E8EA",
                            color: patientHeader?.status.fontColor || "#6E6F7C",
                        }}
                    >
                        {patientHeader?.status?.name}
                    </Badge>
                    <div
                        className="pointer mx-2 text-primary align-self-center"
                        onClick={() => {
                            setConsumptionSheetGeneralModal((state) => ({ ...state, isOpen: true }));
                        }}
                        data-tooltip-id="allergies-tooltip"
                        data-tooltip-content={"Hoja de consumo general"}
                    >
                        <IconClipboardData />
                    </div>
                    {(stage?.value === "surgery" || rolUser === "medical") && (
                        <div
                            className="text-primary pointer align-self-center"
                            onClick={handleOpenCheckListModal}
                            data-tooltip-id="allergies-tooltip"
                            data-tooltip-content={"Lista de chequeo"}
                        >
                            <IconCheckupList />
                        </div>
                    )}
                    <div className="flex-fill"></div>
                    <Select
                        className="textGray100"
                        isSearchable
                        showDefaultOption={false}
                        options={optionsSheets}
                        value={optionsSheets.find((x) => x.prefix === selectedSheet)}
                        onChange={({ option }) => {
                            renderToAnotherSheet(option);
                        }}
                    />

                    {stage?.value === "surgery" && patientHeader?.status?.value === "in_surgery" && (
                        <Badge
                            className="mx-2 align-self-center pointer"
                            onClick={() => {
                                showModalSurgeryRoom();
                            }}
                            style={{
                                backgroundColor: "#D6F3F7",
                                color: "#00B4CC",
                            }}
                            data-tooltip-id="allergies-tooltip"
                            data-tooltip-content={"Cambiar quirófano"}
                        >
                            {patientHeader?.qx?.name ?? "Quirófano xxxx"}
                            <IconChevronDown style={{ color: "#00B4CC", width: "20px", height: "20px" }} />
                        </Badge>
                    )}
                    <div
                        className="pointer mx-2 text-primary align-self-center"
                        onClick={handleOpenAntecedentsModal}
                        data-tooltip-id="allergies-tooltip"
                        data-tooltip-content={"Antecedentes"}
                    >
                        <IconClipboardHeart />
                    </div>
                    <div className="d-flex align-items-center align-self-center">
                        <div
                            className="message-icon ms-3"
                            onClick={handleOpenCommentsDrawer}
                            data-tooltip-id="allergies-tooltip"
                            data-tooltip-content={"Comentarios"}
                        >
                            {comments?.length}
                        </div>
                    </div>
                    {patientHeader?.alertsOcularNotes && (
                        <div
                            className="dynamic-icon dynamic-icon__warning ms-3 d-flex align-items-center align-self-center"
                            data-tooltip-id="allergies-tooltip"
                            data-tooltip-content={patientHeader.alertsOcularNotes}
                        >
                            <IconX />
                        </div>
                    )}
                    {patientHeader?.allergiesGeneralNotes && (
                        <div
                            className="dynamic-icon dynamic-icon__danger ms-3 d-flex align-items-center align-self-center"
                            data-tooltip-id="allergies-tooltip"
                            data-tooltip-content={patientHeader.allergiesGeneralNotes}
                        >
                            <IconExclamationMark strokeWidth={3} />
                        </div>
                    )}
                    <GeneralConsumptionSheet
                        isOpen={consumptionSheetGeneralModal.isOpen}
                        appId={appId}
                        stage={patientHeader?.status?.value}
                        onClose={() => setConsumptionSheetGeneralModal((state) => ({ ...state, isOpen: false }))}
                    />
                    <SelectRoomSurgery
                        action="transfer"
                        isOpen={selectRoomSurgeryModal.show}
                        onClose={handleCloseSelectRoomModal}
                        appId={appId}
                    />

                    <Antecedents
                        isOpen={antecedentsConfig.isOpen}
                        onClose={handleCloseAntecedentsModal}
                        isDisabledForm={isDisabledForm}
                        onSuccess={handleReloadHeader}
                        appId={appId}
                        data={{ tag: "adx" }}
                        cluId={patientHeader?.cluId}
                    />
                    <Comments
                        data={comments}
                        isOpen={isCommentsDrawerOpen}
                        onClose={handleCloseCommentsDrawer}
                        width={400}
                        drawer="right"
                        onCreateComment={(value) => handleSubmitComment(value)}
                        onDeleteComment={(id) => handleDeleteComment(id)}
                    />
                    <Tooltip id="allergies-tooltip" />
                </div>
                <CheckList
                    numId={rolUser === "medical" ? patientHeader?.numId : numId}
                    isOpen={checkListModal.isOpen}
                    onClose={handleCloseCheckListModal}
                />
            </>
        );
    };

    return render();
}