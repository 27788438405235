import { IconCirclePlus, IconTrash } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebounce, useDebouncedCallback } from "use-debounce";
//
import { useAppDispatch, useAppSelector } from "@/hooks";
import { DIListItem, DIPayload, DISelectItem, DIStatus, EyeListItem, UpdateDIPayload } from "@/models/select/diagnosticImpression";
import { getAttentionTimes } from "../AttentionOrigin/attentionOrigin.actions";
import { createDI, deleteDI, getDIList, getDISelects, getDIStatus, updateDI } from "./diagnosticImpression.action";
//
import { SectionCard, Select } from "@/components";
import ScrollableTable from "@/components/ScrollableTable/ScrollableTable";
import { setDICounter, setSelectedDI } from "@/config/slices/counters";
import { AttentionTime } from "@/models/attentionOrigin";
import { PreoperativeDiagnosesResult } from "@/models/surgery/patientAttention/patientAttention";
import { Tooltip } from "react-tooltip";
import { surgicalDiagnosis } from "../../Surgery/stages/doctorsSheets/surgeon/surgeon.action";
import { finishLoading, startLoading } from "@/config/slices";
interface DiagnosticImpressionProps {
    isDisabledForm?: boolean;
    historySheet?: boolean;
    isSurgery?: boolean;
    data?: {
        appId: number | undefined,
        cluId: number | undefined,
        userId: number | undefined,
        eaccount: number | undefined,
        clhId: number | undefined;
        sheet: string;
        information?: DIListItem[];
    };
}
export default function DiagnosticImpression({ isDisabledForm, data, historySheet, isSurgery = false }: DiagnosticImpressionProps) {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const selectRef = useRef<{ clearInput: () => void; }>();

    const currentSheet = location.pathname.split("/")[location.pathname.split("/").length - 1];
    const appointmentIdState = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const accountId = useAppSelector(state => state.workspace.id);
    const clinicalHistoryIdState = useAppSelector(state => state.patientAttention.patientStatus.clhId);
    const patientIdState = useAppSelector(state => state.patientAttention.appointmentSelected.patientId);


    const [appointmentId, setAppointmentId] = useState<number | undefined>();
    const [clinicalHistoryId, setClinicalHistoryId] = useState<number | undefined>();
    const [patientId, setPatientId] = useState<number>();
    const [diagnosisInformationSurgery, setDiagnosisInformationSurgery] = useState<PreoperativeDiagnosesResult>({
        cie10: "",
        cie10Id: null,
        extDiagId: null,
        is_main: null,
        name: "",
        organ: "",
        organId: null,

    });

    const [selectSearch, setSelectSearch] = useDebounce<string>("", 500);
    const [statusSelect, setStatusSelect] = useState<DIStatus[]>([]);
    const [diagnosticTimes, setDiagnosticTimes] = useState<AttentionTime[]>([]);
    const [diList, setDIList] = useState<DIListItem[]>([]);
    const [diSelectItems, setDISelectItems] = useState<DISelectItem[]>([]);
    const [eyeList, setEyeList] = useState<EyeListItem[]>([]);
    const [selectedDX, setSelectedDX] = useState<UpdateDIPayload>({
        appId: data?.appId ?? appointmentId,
        clhId: data?.clhId ?? clinicalHistoryId,
        eaccount: accountId,
        userId: userId as number,
        sheetOrigin: data?.sheet ?? currentSheet
    });
    const [diCreatePayload, setDIPayload] = useState<DIPayload>({
        appId: appointmentId,
        userId: userId as number,
        eaccount: accountId,
        type: "extendedDiagnostic",
        codeId: "",
        dboId: undefined,
        evolution: undefined,
        sheet: data?.sheet ?? currentSheet
    });
    const [diParams, setDiParams] = useState<{
        appId?: number | undefined,
        cluId?: number | undefined,
        userId: number | undefined,
        eaccount: number | undefined,
        clhId?: number | undefined;
        sheet?: string;
    }>({
        appId: undefined,
        eaccount: accountId,
        clhId: undefined,
        userId: userId as number,
        cluId: undefined,
        sheet: data?.sheet ? data.sheet : ""
    });

    useEffect(() => {
        async function fetchData() {
            const data = await getDIStatus();
            setStatusSelect(data);
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (data?.appId && data.cluId && data.clhId) {
            setAppointmentId(data.appId);
            setClinicalHistoryId(data.clhId);
            setPatientId(data.cluId);
            setDiParams({
                appId: data.appId,
                eaccount: accountId,
                clhId: data.clhId,
                userId: userId as number,
                cluId: data.cluId,
                sheet: "surgery"
            });
            setDIPayload((state) => ({ ...state, clhId: data.clhId, appId: data.appId, sheet: "surgery" }));


        } else {
            setAppointmentId(appointmentIdState);
            setClinicalHistoryId(clinicalHistoryIdState);
            setPatientId(patientIdState);
            setDIPayload((state) => ({ ...state, clhId: clinicalHistoryIdState, appId: appointmentIdState, }));
            setDiParams({
                appId: appointmentIdState,
                eaccount: accountId,
                clhId: clinicalHistoryId,
                userId: userId as number,
                cluId: patientId,
            });

        }

    }, [data?.appId, data?.clhId, data?.cluId]);

    useEffect(() => {
        async function fetchData() {

            if (data?.sheet === "surgery" && data?.appId && data?.clhId) {
                const resData = await dispatch(surgicalDiagnosis({ appId: data.appId, clhId: data.clhId }));
                if (resData) {
                    setDiagnosisInformationSurgery(resData as PreoperativeDiagnosesResult);
                }
            }
        }
        fetchData();
    }, [data?.sheet]);

    useEffect(() => {
        async function fetchData() {
            const attentionTimesData = await getAttentionTimes();
            setDiagnosticTimes(attentionTimesData as AttentionTime[]);
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const data = await dispatch(getDISelects({
                    type: diCreatePayload.type,
                    enabled: 1,
                    search: selectSearch,
                    page: 1,
                    perPage: 10
                }));
                setDISelectItems(data?.diSelectResponse as DISelectItem[]);
                setEyeList(data?.eyesResponse as EyeListItem[]);
            }
        }
        fetchData();
    }, [dispatch, diCreatePayload.type, selectSearch, historySheet]);

    useEffect(() => {
        async function fetchData() {
            if (historySheet) {
                setDIList(data?.information || []);
                const mainOption = data?.information?.find(item => item.isMain === 1);
                if (mainOption) {
                    setSelectedDX(prevValue => ({
                        ...prevValue,
                        cie10Code: mainOption.cie10Code,
                        evolution: mainOption.evolution,
                        evolutionTimeId: mainOption.evolutionTime?.id,
                        extDiagnosticId: mainOption.extDiagnosticId,
                        isMain: mainOption.isMain as 1 | 0,
                        relClhId: mainOption.id
                    }));
                    dispatch(setSelectedDI(!!mainOption.isMain));
                }
            } else {
                const res = await dispatch(getDIList({
                    appId: data?.appId ?? appointmentIdState,
                    eaccount: accountId,
                    clhId: data?.clhId ?? clinicalHistoryIdState,
                    userId: userId as number,
                    cluId: undefined,
                    sheet: data?.sheet ? data.sheet : ""
                }));
                if (res) {
                    dispatch(setDICounter(res.length));
                    const mainOption = res.find(item => item.isMain);
                    if (mainOption) {
                        setSelectedDX(prevValue => ({
                            ...prevValue,
                            cie10Code: mainOption.cie10Code,
                            evolution: mainOption.evolution,
                            evolutionTimeId: mainOption.evolutionTime?.id,
                            extDiagnosticId: mainOption.extDiagnosticId,
                            isMain: mainOption.isMain as 1 | 0,
                            relClhId: mainOption.id
                        }));
                        dispatch(setSelectedDI(!!mainOption.isMain));
                    }
                    setDIList(res);
                }
            }
        }
        fetchData();
    }, [dispatch, diParams, data?.information]);

    const onSetEvolutionTime = useDebouncedCallback(async (option: UpdateDIPayload) => {
        await dispatch(updateDI(option));
    }, 1000);

    const onChangeDISelect = (value: string | number): void => {
        setDIPayload({
            ...diCreatePayload,
            codeId: value
        });
    };

    const onChangeEyeSelect = (value: number): void => {
        setDIPayload({
            ...diCreatePayload,
            dboId: value
        });
    };

    const onCreateService = async () => {
        try {
            dispatch(startLoading());
            const response = await dispatch(createDI(diCreatePayload));
            if (response && response.success) {
                const datares = await dispatch(getDIList({
                    appId: appointmentId,
                    eaccount: accountId,
                    clhId: clinicalHistoryId,
                    userId: userId as number,
                    cluId: patientId,
                    sheet: data ? data.sheet : ""
                }));
                dispatch(setDICounter(datares.length));
                setDIList(datares as DIListItem[]);
                setDIPayload({
                    ...diCreatePayload,
                    codeId: "",
                    timId: undefined,
                    dboId: undefined,
                    evolution: undefined
                });
                const createdDi = datares.find(di => di.id === response.results);
                const alreadyCreated = datares.filter(di => di.canDelete).length > 1;
                if (createdDi && !alreadyCreated) await onChangeDX(createdDi, datares);
            }
        } finally {
            dispatch(finishLoading());
        }
    };

    const onDeleteService = async (di: DIListItem) => {
        try {
            dispatch(startLoading());
            const isSuccess = await dispatch(deleteDI({
                appId: appointmentId,
                clhId: clinicalHistoryId,
                eaccount: accountId,
                userId: userId as number,
                relClhId: di.id as number,
                cie10Code: di.cie10Code,
                extDiagnosticId: di.extDiagnosticId as number,
                sheet: data ? data.sheet : ""
            }));
            if (isSuccess) {
                if (di.isMain) setSelectedDX({
                    appId: appointmentId,
                    clhId: clinicalHistoryId,
                    eaccount: accountId,
                    userId: userId as number,
                    evolution: "",
                    evolutionTimeId: undefined,
                    sheetOrigin: data?.sheet ?? currentSheet
                });
                const dataRes = await dispatch(getDIList({
                    appId: appointmentId,
                    eaccount: accountId,
                    clhId: clinicalHistoryId,
                    userId: userId as number,
                    cluId: patientId,
                    sheet: data ? data.sheet : ""
                }));
                dispatch(setDICounter(dataRes.length));
                setDIList(dataRes as DIListItem[]);
                dispatch(setSelectedDI(false));
            }
        } finally {
            dispatch(finishLoading());
        }
    };

    const onChangeDX = async (di: DIListItem, list: DIListItem[]) => {
        const options: DIListItem[] = list.map(item => {
            if (di.id === item.id && di.clhId === item.clhId) {
                return { ...item, isMain: 1 };
            }
            return { ...item, isMain: 0 };
        });
        const selectedDI = options.find(item => item.id === di.id);

        if (selectedDI) {
            setDIList(options);

            if (selectedDI.extDiagnosticId) {
                setSelectedDX({
                    ...selectedDX,
                    relClhId: selectedDI.id,
                    extDiagnosticId: selectedDI.extDiagnosticId,
                    isMain: selectedDI.isMain,
                    evolution: "",
                    evolutionTimeId: undefined
                });
                await dispatch(updateDI({
                    appId: appointmentId,
                    clhId: clinicalHistoryId,
                    relClhId: selectedDI.id,
                    eaccount: accountId,
                    userId: userId as number,
                    extDiagnosticId: selectedDI.extDiagnosticId,
                    isMain: selectedDI.isMain,
                    sheetOrigin: data?.sheet ?? currentSheet
                }, isSurgery));
            } else {
                setSelectedDX({
                    ...selectedDX,
                    relClhId: selectedDI.id,
                    cie10Code: selectedDI.cie10Code,
                    isMain: selectedDI.isMain,
                    evolution: "",
                    evolutionTimeId: undefined
                });
                await dispatch(updateDI({
                    appId: appointmentId,
                    clhId: clinicalHistoryId,
                    relClhId: di.id,
                    cie10Code: di.cie10Code,
                    eaccount: accountId,
                    userId: userId as number,
                    isMain: selectedDI.isMain,
                    sheetOrigin: data?.sheet ?? currentSheet
                }, isSurgery));
            }
            dispatch(setSelectedDI(!!selectedDI.isMain));
        }
    };

    const onChangeStatusSelect = async (di: DIListItem, selectValue: string) => {
        const options = diList.map(item => {
            if (di.id === item.id && di.clhId === item.clhId) {
                return {
                    ...item, status: {
                        ...item.status,
                        tag: selectValue
                    }
                };
            }
            return item;
        });

        if (di.extDiagnosticId) {
            await dispatch(updateDI({
                appId: appointmentId,
                clhId: clinicalHistoryId,
                relClhId: di.id,
                eaccount: accountId,
                userId: userId as number,
                extDiagnosticId: di.extDiagnosticId,
                diagnosticStatus: selectValue,
                sheetOrigin: data?.sheet ?? currentSheet
            }, isSurgery));
        } else {
            await dispatch(updateDI({
                appId: appointmentId,
                clhId: clinicalHistoryId,
                relClhId: di.id,
                cie10Code: di.cie10Code,
                eaccount: accountId,
                userId: userId as number,
                diagnosticStatus: selectValue,
                sheetOrigin: data?.sheet ?? currentSheet
            }, isSurgery));
        }

        setDIList(options);
    };

    const renderDiagnosticHeader = () => {
        const formattedSelect = diSelectItems?.map(item => ({
            value: item.id ? item.id : item.cie10Code,
            label: item.description
        })) || [];
        const formattedEyes = eyeList?.map(item => ({
            value: item.id,
            label: item.name
        })) || [];
        const currentSelectValue = formattedSelect.find(item => item.value === diCreatePayload.codeId);

        const currentEyeValue = formattedEyes.find(item => item.value === diCreatePayload.dboId);

        return (
            <>
                <div className="w-100 d-flex align-items-center mb-3">
                    <Select
                        ref={selectRef}
                        variant="filled"
                        className="flex-fill"
                        width="100%"
                        isCleanable
                        isSearchable
                        value={currentSelectValue}
                        options={formattedSelect}
                        disabled={isDisabledForm}
                        onChange={({ option }) => onChangeDISelect(option.value)}
                        onType={(value) => setSelectSearch(value)}
                    />
                    {formattedEyes.map(eye => (
                        <label className="d-flex mx-3" key={eye.value}>
                            <span className="me-2">{eye.label}</span>
                            <input
                                type="radio"
                                name="eye"
                                className="form-check-input border-primary"
                                value={eye.value}
                                checked={eye.value === currentEyeValue?.value}
                                onChange={() => onChangeEyeSelect(eye.value)}
                                disabled={isDisabledForm}
                            />
                        </label>
                    ))}

                    <div className="flex-fill"></div>
                    {!isDisabledForm &&
                        <IconCirclePlus
                            className="text-primary pointer ms-3"
                            onClick={() => onCreateService()}
                        />
                    }
                </div>
            </>
        );
    };

    const renderTableRow = (di: DIListItem) => {
        const formattedStatus = statusSelect.map(item => ({
            label: item.description,
            value: item.value
        }));
        const statusValue = formattedStatus.find(item => item.value === di.status?.tag);
        const splittedUrl = location.pathname.split("/");
        const currentSheet = data?.sheet ?? splittedUrl[splittedUrl.length - 1];

        return (
            <ScrollableTable.Row key={`${di.id}-${di.clhId}`}>
                <ScrollableTable.Cell className="fs-6" col={2}>
                    {di.date}
                </ScrollableTable.Cell>
                <ScrollableTable.Cell className="fs-6" col={1}>
                    {di.cie10Code}
                </ScrollableTable.Cell>
                <ScrollableTable.Cell className="fs-6" col={((data?.sheet === "surgery" && Object.keys(diagnosisInformationSurgery).length !== 0)) ? 6 : 3}>
                    <div className="nowrap overflow-hidden text-ellipsis">
                        {di.extDiagnosticId ? (
                            <>
                                <span title={di.extDiagnosticDescription}>
                                    <span className="text-warning">A</span> / {di.extDiagnosticDescription}
                                </span>
                                <br />
                                <span className="nowrap" title={di.cie10Description}>
                                    <span className="text-secondary">10</span> / {di.cie10Description}
                                </span>
                            </>
                        ) : (
                            <span className="nowrap" title={di.cie10Description}>
                                {di.cie10Description}
                            </span>
                        )}
                    </div>
                </ScrollableTable.Cell>
                <ScrollableTable.Cell className="fs-6" col={1}>
                    {di.dbo?.name}
                </ScrollableTable.Cell>
                <ScrollableTable.Cell className="fs-6" col={1}
                    data-tooltip-id="doctor-name"
                    data-tooltip-content={di.doctorName}
                    style={{ cursor: "pointer" }}
                >
                    <Tooltip id="doctor-name" />
                    {di.doctorInitials.toUpperCase()}
                </ScrollableTable.Cell>
                {(data?.sheet !== "surgery" || (data?.sheet === "surgery" && Object.keys(diagnosisInformationSurgery).length === 0)) &&
                    <ScrollableTable.Cell className="fs-6" col={2}>
                        <select
                            className={`form-select form-select-sm ${di.sheet !== currentSheet && "pointer"}`}
                            value={statusValue?.value}
                            disabled={di.sheet !== currentSheet || isDisabledForm}
                            onChange={({ target }) => onChangeStatusSelect(di, target.value)}
                        >
                            <option value={undefined}>Seleccionar...</option>
                            {formattedStatus.map(item => (
                                <option key={item.value} value={item.value}>{item.label}</option>
                            ))}
                        </select>
                    </ScrollableTable.Cell>
                }
                {(data?.sheet !== "surgery" || (data?.sheet === "surgery" && Object.keys(diagnosisInformationSurgery).length === 0)) &&

                    <ScrollableTable.Cell align="center" className="fs-6" col={1}>
                        <input
                            className="form-check-input border-primary"
                            type="radio"
                            disabled={isDisabledForm}
                            checked={di.isMain === 1}
                            name="dx"
                            onChange={() => onChangeDX(di, diList)}
                            style={{ borderWidth: 2 }}
                        />
                    </ScrollableTable.Cell>
                }
                <ScrollableTable.Cell className="fs-6" col={1}>
                    {!isDisabledForm && di.canDelete ? (
                        <IconTrash
                            className="text-danger pointer"
                            size={18}
                            onClick={() => onDeleteService(di)}
                        />
                    ) : (
                        <div></div>
                    )}
                </ScrollableTable.Cell>
            </ScrollableTable.Row >
        );
    };

    const render = () => {
        const formattedTimes = diagnosticTimes.map(item => ({
            value: item.tim_id,
            label: item.tim_name
        }));
        const currentTimeValue = formattedTimes.find(item => item.value === selectedDX.evolutionTimeId);

        return (
            <SectionCard className="h-100">
                <h5 className="fw-bold text-secondary">Impresión diagnóstica ampliada</h5>
                <hr className="text-primary mt-2" />
                {data?.sheet === "surgery" && <>
                    <div className="d-flex">
                        <div className="fw-bold text-muted col-4">Pre-operatoria</div>
                        {Object.keys(diagnosisInformationSurgery).length === 0 ?
                            <div className="text-muted col-8">Diagnóstico no relacionado</div>
                            :
                            <>
                                <div className="text-muted col-6">{diagnosisInformationSurgery.name}</div>
                                <div className="text-muted col-2">{diagnosisInformationSurgery.organ}</div>
                            </>
                        }
                    </div>
                    <hr className="text-muted mt-2" />
                    <div className="d-flex mb-2">
                        <div className="fw-bold text-muted col-4">Post-operatorio</div>
                    </div>
                </>
                }
                {renderDiagnosticHeader()}
                <ScrollableTable maxHeight={150}>
                    <ScrollableTable.Head>
                        <ScrollableTable.Cell className="fs-6" col={2}>
                            Fecha
                        </ScrollableTable.Cell>
                        <ScrollableTable.Cell className="fs-6 nowrap" col={1}>
                            CIE-10
                        </ScrollableTable.Cell>
                        <ScrollableTable.Cell className="fs-6 nowrap text-ellipsis overflow-hidden" col={((data?.sheet === "surgery" && Object.keys(diagnosisInformationSurgery).length !== 0)) ? 6 : 3}>
                            Impresión diagnóstica
                        </ScrollableTable.Cell>
                        <ScrollableTable.Cell className="fs-6" col={1}>
                            Ojo
                        </ScrollableTable.Cell>
                        <ScrollableTable.Cell align="center" className="fs-6" col={1}>
                            MD
                        </ScrollableTable.Cell>
                        {(data?.sheet !== "surgery" || (data?.sheet === "surgery" && Object.keys(diagnosisInformationSurgery).length === 0)) &&
                            <ScrollableTable.Cell className="fs-6" col={2}>
                                Estado
                            </ScrollableTable.Cell>
                        }
                        {(data?.sheet !== "surgery" || (data?.sheet === "surgery" && Object.keys(diagnosisInformationSurgery).length === 0)) &&
                            <ScrollableTable.Cell className="fs-6" col={1}>
                                DX
                            </ScrollableTable.Cell>
                        }
                        <ScrollableTable.Cell className="fs-6" col={1}>
                        </ScrollableTable.Cell>
                    </ScrollableTable.Head>

                    <ScrollableTable.Body>
                        {diList.length ? diList.map((di) => renderTableRow(di)) : (
                            <ScrollableTable.Row>
                                <ScrollableTable.Cell col={12} align="center">No se encontraron registros.</ScrollableTable.Cell>
                            </ScrollableTable.Row>
                        )}
                    </ScrollableTable.Body>
                </ScrollableTable>
                {data?.sheet !== "surgery" &&

                    <div className="d-flex align-items-center mt-3">
                        <div className="d-flex align-items-center flex-wrap w-100">
                            <span className="text-primary fw-bold me-3 nowrap">Tiempo de evolución DX</span>
                            <div className="input-group flex-nowrap w-50">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Cantidad"
                                    value={selectedDX.evolution}
                                    onChange={({ target }) => {
                                        const option = { ...selectedDX, evolution: target.value, appId: appointmentId, clhId: clinicalHistoryId };
                                        setSelectedDX(option);
                                        onSetEvolutionTime(option);
                                    }}
                                    style={{ maxWidth: 80, minWidth: 50 }}
                                    disabled={(!selectedDX.isMain ? true : false) || isDisabledForm}
                                />
                                <Select
                                    options={formattedTimes}
                                    maxHeight={70}
                                    inputGroup
                                    value={currentTimeValue}
                                    onChange={({ option }) => {
                                        const options = { ...selectedDX, evolutionTimeId: option.value, appId: appointmentId, clhId: clinicalHistoryId };
                                        setSelectedDX(options);
                                        onSetEvolutionTime(options);
                                    }}
                                    style={{ width: "100%", maxWidth: 60 }}
                                    disabled={(!selectedDX.isMain ? true : false) || isDisabledForm}
                                />
                            </div>
                        </div>
                    </div>
                }
            </SectionCard>
        );
    };

    return render();
}