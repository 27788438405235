import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//
import routes from "@/constants/routes";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { fireCautionAlert, fireWarningAlert } from "@/utils";
import { getCanAttentionEnd } from "../consultation.actions";
import { finishAppointment, finishAppointmentDiagnosticAids } from "./controls.action";
//
import { Button } from "@/components";
import { PreoperativePayload } from "@/models/sheets/preoperative";
//
import { PatientHeader } from "@/models/preconsultation";
import { SectionsChanged } from "@/models/surgery/patientAttention/Anesthesiologist";
import { HeaderSurgeryResponse } from "@/models/surgery/patientAttention/headerSurgeryAttention";
import { SurgeonNotes } from "@/models/surgery/patientAttention/surgeon";
import { getHeaderSurgeryService } from "@/services/surgery/patientAttention";
import { getExternalUrl } from "@/utils/getExternalURL";
import { historyRedirect } from "@/utils/historyRedirect";
import { IconSend } from "@tabler/icons-react";
import Intervened from "../../Surgery/components/Intervened";
import ConsumptionSheet from "../../Surgery/consumptionSheet/ConsumptionSheet";
import { getPatientHeader } from "../PreConsultation/preConsultation.actions";
import "./Controls.scss";
import UnexpectedSituation from "./UnexpectedSituation/UnexpectedSituation";

interface Category {
    id: number;
    url: string;
    name: string;
    title?: string;
    description: string;
}

export default function ConsultationControls({
    realStatusSurgery,
    cluId,
    clhIdProp,
    genericFunction,
    setReloadDataNotes,
    setAuthValidation,
    preoperativePayload,
    isDisabledForm,
    tab,
    showButtonReport,
    appId,
    statusSurgery,
    stage,
    finishEvent,
}: {
    setReloadDataNotes?: (data: number) => void;
    sectionsChanged?: SectionsChanged | SurgeonNotes;
    tab?: string;
    showButtonReport?: boolean;
    preoperativePayload?: PreoperativePayload;
    isDisabledForm?: boolean;
    appId?: number;
    finishEvent?: () => Promise<SectionsChanged | SurgeonNotes | undefined>;
    statusSurgery?: string;
    realStatusSurgery?: string;
    cluId?: number;
    clhIdProp?: number | null;
    stage?: { label: string; value: string };
    setAuthValidation?: (state: { isOpen: boolean; prefix: string }) => void;
    genericFunction?: () => void;
}) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const workspace = useAppSelector((state) => state.workspace);
    const appointment = useAppSelector((state) => state.patientAttention.appointmentSelected);
    const userId = useAppSelector((state) => state.auth.user_data.id);
    const userRol = workspace.profile?.prefijo.toLowerCase();
    const eaccount = useAppSelector((state) => state.workspace.id);
    const currentAppointment = useAppSelector((state) => state.patientAttention.appointmentSelected);
    const clhId = useAppSelector((state) => state.patientAttention.patientStatus.clhId);
    const mcfId = useAppSelector((state) => state.patientAttention.patientStatus.mcfId);
    const requestStatus = useAppSelector((state) => state.surgery.requestStatus);
    const currentSheet = useAppSelector((state) => state.surgery.currentSheet);

    const [intervenedModal, setIntervenedModal] = useState({ isOpen: false });
    const [isUnexpectedModal, setIsUnexpectedModal] = useState<boolean>(false);
    const [consumptionSheetModal, setConsumptionSheetModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [patientHeader, setPatientHeader] = useState<PatientHeader>();
    const [headerSurgery, setHeaderSurgery] = useState<HeaderSurgeryResponse>();
    const authData = useAppSelector((state) => state.auth);
    const accountData = useAppSelector((state) => state.workspace);

    const { new_category, all_categories } = useAppSelector((state) => state.category.category);
    
    const isCategoryApp = new_category
        ?.find((category: Category) => category?.description === "Portal Asistencial")
        ?.data?.find((item: { title: string }) => item?.title === "Ordenamientos");


    const redirect = ()=>{
        historyRedirect({
            path: `${getExternalUrl({
                loginReducer: {
                    Authorization: authData.access_token,
                    currentAccount: {
                        id: accountData.id,
                        profile: accountData.profile?.id,
                    },
                    user_data: authData.user_data,
                    all_categories: all_categories,
                },
                category: isCategoryApp,
            })}&params=${encodeURIComponent(
                JSON.stringify({
                    infoPatient: {
                        cluId: patientHeader?.cluId,
                        clhId: clhIdProp ?? clhId,
                        document: patientHeader?.cluDocument,
                        typeDoc: patientHeader?.docTypeId,
                    },
                })
            )}&goTo=/ordenamientos/ordenes`,
            isInternal: false,
        });
    };


    const goToOrderingModule = async () => {
        const canAttentionEnd = await dispatch(getCanAttentionEnd(mcfId));

        if (!canAttentionEnd.results) {
            fireWarningAlert(canAttentionEnd.message, "Intenta de nuevo");
            return;
        } 

        redirect();
    };

    const goToOrderingModuleSurgery = async () => {
        if (appId && clhIdProp) {
            redirect();

        }
    };

    const onOpenUnexpectedModal = () => setIsUnexpectedModal(true);
    const onCloseUnexpectedModal = () => setIsUnexpectedModal(false);
    const handleOpenConsumptionSheetModal = () => setConsumptionSheetModal(true);
    const handleCloseConsumptionSheetModal = () => setConsumptionSheetModal(false);

    useEffect(() => {
        async function fetchData() {
            if (location.pathname.includes("surgery")) {
                if (appId) {
                    try {
                        const data = await getHeaderSurgeryService(appId);
                        setHeaderSurgery(data);
                    } catch (error) {
                        console.error("Error fetching header surgery data:", error);
                    }
                }
            } else {
                if ((appId ?? appointment?.app_id) && eaccount) {
                    const data = await dispatch(
                        getPatientHeader({
                            appId: appId ?? appointment?.app_id,
                            eaccount: eaccount,
                        })
                    );
                    setPatientHeader(data);
                }
            }
        }
        fetchData();
    }, [appId, appointment?.app_id, eaccount, dispatch, location.pathname]);

    const onFinishAppointment = async () => {
        try {
            setIsLoading(true);
            if (workspace.profile?.prefijo.toLowerCase() === "nurse" || currentAppointment.status === "failed") {
                location.pathname.includes("DiagnosticAids")
                    ? navigate(routes.workspaceDiagnosticAids)
                    : navigate(routes.workspaceConsultation);
            } else if (currentAppointment.status !== "attended") {
                fireCautionAlert(`Se finalizará la consulta del paciente ${currentAppointment.patientName}`, undefined, async () => {
                    let isSuccess;
                    location.pathname.includes("DiagnosticAids")
                        ? (isSuccess = await dispatch(
                            finishAppointmentDiagnosticAids({
                                appId: currentAppointment.app_id,
                                eaccount: workspace.id,
                                userId: userId as number,
                                cluId: currentAppointment.patientId,
                            })
                        ))
                        : (isSuccess = await dispatch(
                            finishAppointment({
                                appId: currentAppointment.app_id,
                                eaccount: workspace.id,
                                userId: userId as number,
                                cluId: currentAppointment.patientId,
                            })
                        ));
                    if (isSuccess) {
                        location.pathname.includes("DiagnosticAids")
                            ? navigate(routes.workspaceDiagnosticAids)
                            : navigate(routes.workspaceConsultation);
                    }
                });
            } else {
                let isSuccess;
                location.pathname.includes("DiagnosticAids")
                    ? (isSuccess = await dispatch(
                        finishAppointmentDiagnosticAids({
                            appId: currentAppointment.app_id,
                            eaccount: workspace.id,
                            userId: userId as number,
                            cluId: currentAppointment.patientId,
                        })
                    ))
                    : (isSuccess = await dispatch(
                        finishAppointment({
                            appId: currentAppointment.app_id,
                            eaccount: workspace.id,
                            userId: userId as number,
                            cluId: currentAppointment.patientId,
                        })
                    ));
                if (isSuccess) {
                    location.pathname.includes("DiagnosticAids")
                        ? navigate(routes.workspaceDiagnosticAids)
                        : navigate(routes.workspaceConsultation);
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    const dontShowSuspendButton = () => {
        let result = false;
        if (
            location.pathname.includes("surgery") &&
            (userRol?.toLowerCase() === "nurse" || userRol?.toLowerCase() === "medical" || userRol?.toLowerCase() === "anesthesiologist")
        ) {
            if (realStatusSurgery === "attended") {
                result = true;
            }
        } else {
            result = false;
        }
        return result;
    };

    const buttonsDisabled = (enabled: boolean) => {
        if (tab === "surgery") {
            if (stage?.value === "admission" && enabled) {
                return true;
            } else {
                if (currentSheet === "nurseNotes" && userRol?.toLowerCase() === "nurse") {
                    return false;
                }
                if (currentSheet === "surgeon" && userRol?.toLowerCase() === "medical") {
                    return false;
                }
                if (currentSheet === "anesthesiologist" && userRol?.toLowerCase() === "anesthesiologist") {
                    return false;
                }
            }
            return true;
        }

        return false;
    };

    const render = () => {
        return (
            <div className="consultation-controls">
                <div className="flex-grow-1">
                    {tab === "surgery" && (
                        <Button
                            variant="outline"
                            disabled={requestStatus === "pending" || buttonsDisabled(true)}
                            className="me-3"
                            onClick={handleOpenConsumptionSheetModal}
                            isLoading={isLoading}
                        >
                            Registrar consumo
                        </Button>
                    )}
                    <Button
                        variant="outline-danger"
                        disabled={buttonsDisabled(false)}
                        onClick={onOpenUnexpectedModal}
                    >
                        Situación inesperada
                    </Button>
                </div>

                {tab !== "surgery" && (
                    <Button
                        variant="outline"
                        className="me-3"
                        disabled={buttonsDisabled(true)}
                        onClick={onFinishAppointment}
                        isLoading={isLoading}
                    >
                        {tab && showButtonReport && currentAppointment.status === "attended"
                            ? tab === "adx" && showButtonReport
                                ? "Actualizar reporte"
                                : "finalizar"
                            : currentAppointment.status === "attended" || currentAppointment.status === "failed"
                                ? "Regresar"
                                : "Finalizar"}
                    </Button>
                )}
                {dontShowSuspendButton() ? (
                    <div className="mx-2">
                        <Button
                            variant="danger"
                            onClick={() => {
                                fireCautionAlert("", "¿Desea solicitar una reintervención?", () => {
                                    setIntervenedModal({ isOpen: true });
                                });
                            }}
                        >
                            Solicitar reintervención
                        </Button>
                    </div>
                ) : (
                    <div></div>
                )}
                {!location.pathname.includes("DiagnosticAids") &&
                    !location.pathname.includes("surgery") &&
                    !isDisabledForm &&
                    !(workspace.profile?.prefijo.toLowerCase() === "nurse") && (
                    <Button
                        variant="primary"
                        className="mx-2"
                        disabled={patientHeader?.canOrder === false}
                        endIcon={<IconSend />}
                        onClick={() => goToOrderingModule()}
                    >
                        Ir a ordenamiento
                    </Button>
                )}
                {location.pathname.includes("surgery") && (
                    <Button
                        variant="primary"
                        disabled={buttonsDisabled(true) || headerSurgery?.results.canOrder === false}
                        className="mx-2"
                        endIcon={<IconSend />}
                        onClick={() => goToOrderingModuleSurgery()}
                    >
                        Ir a ordenamiento
                    </Button>
                )}
                {tab === "surgery" && (userRol === "anesthesiologist" || userRol === "medical") && (
                    <Button
                        variant="outline"
                        className="me-3 mx-3"
                        disabled={buttonsDisabled(true)}
                        onClick={() => {
                            if (finishEvent) {
                                finishEvent();
                            }
                        }}
                        isLoading={isLoading}
                    >
                        Cerrar evento
                    </Button>
                )}
                {tab === "surgery" && userRol !== "anesthesiologist" && userRol !== "medical" && (
                    <Button
                        variant="outline"
                        className="me-3"
                        disabled={buttonsDisabled(false)}
                        onClick={() => {
                            if (setAuthValidation) {
                                setAuthValidation({ isOpen: true, prefix: "controls" });
                            }
                        }}
                        isLoading={isLoading}
                    >
                        {statusSurgery !== stage?.value
                            ? "Guardar"
                            : stage?.value === "admission"
                                ? "Trasladar a ingreso"
                                : stage?.value === "income"
                                    ? "Trasladar a preparación"
                                    : stage?.value === "preparation"
                                        ? "Trasladar a cirugía"
                                        : stage?.value === "surgery"
                                            ? "Trasladar a recuperación"
                                            : stage?.value === "recovery" && "Paciente egresa"}
                    </Button>
                )}
                <UnexpectedSituation
                    isOpen={isUnexpectedModal}
                    onClose={onCloseUnexpectedModal}
                    isDisabledForm={isDisabledForm}
                    appId={appId}
                    tab={tab}
                    stage={stage}
                    status={statusSurgery}
                />
                <Intervened
                    isOpen={intervenedModal.isOpen}
                    onClose={() => {
                        setIntervenedModal({ isOpen: false });
                    }}
                    appId={appId}
                    cluId={cluId}
                />

                <ConsumptionSheet
                    isOpen={consumptionSheetModal}
                    onClose={handleCloseConsumptionSheetModal}
                    appId={appId}
                    stage={stage}
                    setReloadDataNotes={setReloadDataNotes}
                />
            </div>
        );
    };

    return render();
}
